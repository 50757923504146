import {
    FilterTourFlightSearchAction,
    FilterTourFlightSearchFailureAction,
    FilterTourFlightSearchSuccessAction,
    IncrementTourFlightSearchPageAction,
    IncrementTourFlightSearchPageFailureAction,
    IncrementTourFlightSearchPageSuccessAction,
    PerformTourFlightSearchAction,
    PerformTourFlightSearchSuccessAction,
    PopulateTourFlightSearchParamsAction,
    ResetTourFlightFiltersAction,
    ResetTourFlightSearchAction,
    SortTourFlightSearchAction,
} from '@/redux/actions/flightSearch';
import { PerformTourFlightSearchFailureAction } from '@/redux/actions/flightSearch/PerformTourFlightSearchFailureAction';
import { FlightSearchResponse, SortType } from '@/endurance/flightSearch';
import { TourFlightSearchParams } from '@/core/tourFlightSearch';

import * as TYPES from './types';

export const resetTourFlightSearch = (): ResetTourFlightSearchAction => {
    return {
        type: TYPES.RESET_TOUR_FLIGHT_SEARCH,
    };
};

export const resetTourFlightFilters = (): ResetTourFlightFiltersAction => {
    return {
        type: TYPES.RESET_TOUR_FLIGHT_SEARCH_FILTERS,
    };
};

export const populateTourFlightSearchParams = (
    tourId: string,
    departureId: number,
    params: TourFlightSearchParams,
    updateQueryString = false
): PopulateTourFlightSearchParamsAction => {
    return {
        type: TYPES.POPULATE_TOUR_FLIGHT_SEARCH_PARAMS,
        updateQueryString,
        tourId,
        departureId,
        params,
    };
};

export const performTourFlightSearch = (): PerformTourFlightSearchAction => {
    return {
        type: TYPES.PERFORM_TOUR_FLIGHT_SEARCH,
    };
};

export const performTourFlightSearchSuccess = (
    response: FlightSearchResponse
): PerformTourFlightSearchSuccessAction => {
    return {
        type: TYPES.PERFORM_TOUR_FLIGHT_SEARCH_SUCCESS,
        response,
    };
};

export const performTourFlightSearchFailure = (
    error: string
): PerformTourFlightSearchFailureAction => {
    return {
        type: TYPES.PERFORM_TOUR_FLIGHT_SEARCH_FAILURE,
        error,
    };
};

export const filterTourFlightSearch = (
    params: TourFlightSearchParams
): FilterTourFlightSearchAction => {
    return {
        type: TYPES.FILTER_TOUR_FLIGHT_SEARCH,
        params,
    };
};

export const filterTourFlightSearchSuccess = (
    response: FlightSearchResponse
): FilterTourFlightSearchSuccessAction => {
    return {
        type: TYPES.FILTER_TOUR_FLIGHT_SEARCH_SUCCESS,
        response,
    };
};

export const filterTourFlightSearchFailure = (
    error: string
): FilterTourFlightSearchFailureAction => {
    return {
        type: TYPES.FILTER_TOUR_FLIGHT_SEARCH_FAILURE,
        error,
    };
};

export const sortTourFlightSearch = (
    sortType: SortType
): SortTourFlightSearchAction => {
    return {
        type: TYPES.SORT_TOUR_FLIGHT_SEARCH,
        sortType,
    };
};

export const incrementTourFlightSearchPage =
    (): IncrementTourFlightSearchPageAction => {
        return {
            type: TYPES.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE,
        };
    };

export const incrementTourFlightSearchPageSuccess = (
    response: FlightSearchResponse
): IncrementTourFlightSearchPageSuccessAction => {
    return {
        type: TYPES.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_SUCCESS,
        response,
    };
};

export const incrementTourFlightSearchPageFailure = (
    error: string
): IncrementTourFlightSearchPageFailureAction => {
    return {
        type: TYPES.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_FAILURE,
        error,
    };
};
