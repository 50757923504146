import * as TYPES from './types';

export const requestAccommodation = (countryIsoCode2, slug) => ({
    type: TYPES.ACCOM_REQUEST,
    countryIsoCode2,
    slug,
});

export const requestAccommodationSuccess = accommodation => ({
    type: TYPES.ACCOM_REQUEST_SUCCESS,
    accommodation,
});

export const requestAccommodationFailure = (countryIsoCode2, slug, errors) => ({
    type: TYPES.ACCOM_REQUEST_FAILURE,
    countryIsoCode2,
    slug,
    errors,
});

export const confirmPriceRequest = (accommodationDetails, request) => ({
    type: TYPES.ACCOM_CONFIRM_PRICE_REQUEST,
    accommodationDetails,
    request,
});

export const confirmPriceSuccess = result => ({
    type: TYPES.ACCOM_CONFIRM_PRICE_SUCCESS,
    ...result,
});

export const confirmPriceFailure = () => ({
    type: TYPES.ACCOM_CONFIRM_PRICE_FAILURE,
});
