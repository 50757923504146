import * as TYPES from '../actions/types';
import { accommodationKey } from '../../services/accommodationService';

const initialState = {
    loading: {},
    accommodations: {},
    errors: {},
};

const requestAccommodation = (state, { countryIsoCode2, slug }) => {
    const key = accommodationKey(countryIsoCode2, slug);
    return {
        ...state,
        errors: {},
        loading: { ...state.loading, [key]: true },
    };
};

const requestAccommodationSuccess = (state, { accommodation }) => {
    const key = accommodationKey(
        accommodation.location.countryIsoCode2,
        accommodation.slug
    );

    return {
        ...state,
        loading: { ...state.loading, [key]: false },
        accommodations: { ...state.accommodations, [key]: accommodation },
        errors: { ...state.errors, [key]: undefined },
    };
};

const requestAccommodationFailure = (
    state,
    { countryIsoCode2, slug, errors }
) => {
    const key = accommodationKey(countryIsoCode2, slug);

    return {
        ...state,
        loading: { ...state.loading, [key]: false },
        accommodations: { ...state.accommodations, [key]: undefined },
        errors: { ...state.errors, [key]: errors },
    };
};

const accommodation = (state = initialState, action) => {
    const handlers = {
        [TYPES.ACCOM_REQUEST]: requestAccommodation,
        [TYPES.ACCOM_REQUEST_SUCCESS]: requestAccommodationSuccess,
        [TYPES.ACCOM_REQUEST_FAILURE]: requestAccommodationFailure,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default accommodation;

// Selectors
const selectState = state => state.accommodation;

export const selectAccommodation = (state, countryIsoCode2, slug) =>
    selectState(state).accommodations[accommodationKey(countryIsoCode2, slug)];

export const selectErrors = (state, countryIsoCode2, slug) =>
    selectState(state).errors[accommodationKey(countryIsoCode2, slug)];

export const selectIsRequesting = (state, countryIsoCode2, slug) => {
    const loadingAccommodation =
        selectState(state).loading[accommodationKey(countryIsoCode2, slug)];

    return loadingAccommodation !== undefined ? loadingAccommodation : true;
};

export const selectIsNotFound = (state, countryIsoCode2, slug) => {
    const errors = selectErrors(state, countryIsoCode2, slug);

    return errors && errors.find(e => e.toLowerCase() === 'notfound');
};
