import { ApiResponse } from '@/apis';
import { AttributeLookup } from '@/endurance/lookups/AttributeLookup';

import { call, put } from 'redux-saga/effects';
import { getAttributes } from '../../apis/lookupsApi';
import { logError, logErrorWithInfo } from '../../utils/sentry';
import {
    requestActivitiesSuccess,
    requestActivitiesFailure,
} from '../actions/lookups.actions';

export function* requestActivities() {
    try {
        const result: ApiResponse<AttributeLookup[]> =
            yield call(getAttributes);
        if (result.isSuccess) {
            yield put(requestActivitiesSuccess(result.result));
        } else {
            yield put(requestActivitiesFailure());
            logErrorWithInfo(Error('Failed to fetch activities'), {
                result: result,
            });
        }
    } catch (e) {
        logError(e);
        yield put(requestActivitiesFailure());
    }
}
