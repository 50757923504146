import { LookupsState } from '@/redux/LookupsState';

import * as TYPES from '../actions/types';
import activities from '../../utils/jsonDATA/formsData/activities';
import { Action } from '@/redux/actions/Action';
import { RequestActivitiesSuccessAction } from '@/redux/actions/lookups';

const initialState = {
    loading: false,
    activities: activities,
};

const requestActivities = (state: LookupsState) => ({
    ...state,
    loading: true,
});

const requestActivitiesSuccess = (
    state: LookupsState,
    action: RequestActivitiesSuccessAction
) => ({
    ...state,
    loading: false,
    activities: action.activities,
});

const requestActivitiesFailure = (state: LookupsState) => ({
    ...state,
    loading: false,
});

const lookups = (state: LookupsState = initialState, action: Action) => {
    const handlers: any = {
        [TYPES.LOOKUPS_ACTIVITIES_REQUEST]: requestActivities,
        [TYPES.LOOKUPS_ACTIVITIES_SUCCESS]: requestActivitiesSuccess,
        [TYPES.LOOKUPS_ACTIVITIES_FAILURE]: requestActivitiesFailure,
    };

    const handler: (site: LookupsState, action?: Action) => LookupsState =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default lookups;
