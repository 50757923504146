import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Icon from '../../common/Icon/Icon';
import { toggleMobileMenu } from '../../../redux/actions/site.actions';
import styles from './MobileMenuButton.module.scss';

function MobileMenuButton({
    isMobileMenuActive,
    toggleMobileMenu,
    className,
    ...props
}) {
    return (
        <button
            className={classNames({
                [styles.mobileMenuButton]: true,
                [styles.isActive]: isMobileMenuActive,
                [className]: !!className,
            })}
            onClick={toggleMobileMenu}
            type="button"
            aria-label="Toggle menu"
            {...props}
        >
            {isMobileMenuActive ? (
                <Icon
                    className={styles.closeIcon}
                    icon="times"
                    height={null}
                    width={null}
                />
            ) : (
                <Icon
                    className={styles.menuIcon}
                    icon="menu"
                    height={null}
                    width={null}
                />
            )}
        </button>
    );
}

MobileMenuButton.propTypes = {
    isMobileMenuActive: PropTypes.bool.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
};

const mapStateToProps = ({ site }) => ({
    isMobileMenuActive: site.isMobileMenuActive,
});

function mapDispatchToProps(dispatch) {
    return {
        toggleMobileMenu: params => dispatch(toggleMobileMenu(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuButton);
