import * as moment from 'moment';
import {
    selectTourRoomData,
    selectTourDepartureProduct,
} from './tourDepartures.selectors';
import * as fromSite from './site.selectors';

const selectState = state => state.checkout;
export const selectProduct = state => selectState(state).product;
export const selectCustomers = state => selectState(state).customers;
export const selectAccommodation = state => selectState(state).accommodation;
export const selectPaymentType = state => selectState(state).paymentType;
export const selectBooking = state => selectState(state).booking;
export const selectMonthlyPaymentPlan = state => {
    const instalmentPaymentPlan =
        state.checkout.paymentPlans && !state.checkout.paymentPlans.loading
            ? state.checkout.paymentPlans.plans.find(
                  pl => pl.planType === 'Monthly'
              )
            : null;

    return {
        isAvailable: instalmentPaymentPlan
            ? instalmentPaymentPlan.payments.length > 2
            : false,
        instalmentCount: instalmentPaymentPlan
            ? instalmentPaymentPlan.payments.length - 1
            : null,
    };
};

export const selectTourAccommodationType = state =>
    selectState(state).tourAccommodationType;

export const selectIsTourPackage = checkout =>
    checkout.product.type === 'Package';

export const selectTourSelectedRooms = state =>
    selectState(state).tourSelectedRooms;

export const selectFlights = checkout => checkout.flights;

export const selectPromoDiscount = (checkout, currencyCode) =>
    checkout.promoCode &&
    checkout.promoCode.code && {
        code: checkout.promoCode.code,
        discount:
            checkout.promoCode.discount.find(
                amount => amount.currencyCode === currencyCode
            ) || 0,
    };
export const selectTourPackageProduct = (
    packagesState,
    checkoutState,
    currencyCode
) => {
    const { tour, prices } = packagesState;

    // Find the appropriate price object based on the currency code
    const priceInfo = prices.find(price => price.currencyCode === currencyCode);

    // Create line items based on the package data
    const lineItems = [];

    // Add tour pricing
    if (tour) {
        lineItems.push({
            id: 'Tour',
            type: 'Product',
            label: `Tour: ${tour.name}`,
            price: priceInfo.tourPricePerPerson,
            quantity: checkoutState.customerCount,
        });
    }

    // Add package pricing if available
    if (
        priceInfo.packagePricePerPerson &&
        priceInfo.packagePricePerPerson !== priceInfo.tourPricePerPerson
    ) {
        lineItems.push({
            id: 'Package',
            type: 'Product',
            label: 'Package Price',
            price:
                priceInfo.packagePricePerPerson - priceInfo.tourPricePerPerson,
            quantity: checkoutState.customerCount,
        });
    }

    const isFullBalanceDue = moment().utc().diff(tour.endDate, 'days') >= 0;
    return {
        totalDeposit: priceInfo.deposit,
        isFullBalanceDue,
        balanceDueDate: tour.endDate,
        lineItems,
    };
};

export const selectFlightsProduct = (checkout, currencyCode) => {
    const flightsPrice = checkout.flights.totalPrice.find(
        price => price.currencyCode === currencyCode
    );

    const lineItems = [
        {
            id: 'TourWithFlights',
            type: 'Product',
            label: 'Tour plus flights',
            price: flightsPrice.totalPrice / checkout.customerCount,
            quantity: checkout.customerCount,
        },
        {
            id: 'AtolFee',
            type: 'Extras',
            label: 'ATOL Protection',
            price: flightsPrice.atolFee / checkout.customerCount,
            quantity: checkout.customerCount,
        },
    ];

    const isFullBalanceDue =
        moment().utc().diff(flightsPrice.balanceDueDate, 'days') >= 0;

    return {
        totalDeposit: flightsPrice.deposit,
        isFullBalanceDue: isFullBalanceDue,
        balanceDueDate: new Date(flightsPrice.balanceDueDate).toISOString(),
        lineItems,
    };
};
const getPriceDetails = (checkoutState, currencyCode, key, priceKey) => {
    return checkoutState[key] && checkoutState[key][priceKey]
        ? checkoutState[key][priceKey].find(
              price => price.currencyCode === currencyCode
          )
        : null;
};

export const selectDepartureFlightsProduct = (
    tourState,
    checkoutState,
    departureId,
    currencyCode
) => {
    const calculatePerPersonPrice = (priceObj, total, divisorKey) => {
        return priceObj ? priceObj[total] / checkoutState[divisorKey] : 0;
    };

    const flightsPrice = getPriceDetails(
        checkoutState,
        currencyCode,
        'flights',
        'totalPrice'
    );

    const flightPricePerPerson = calculatePerPersonPrice(
        flightsPrice,
        'baseFlightPrice',
        'customerCount'
    );

    const roomData = selectTourRoomData(tourState, departureId, currencyCode);
    const selectedRooms = checkoutState.tourSelectedRooms;
    const lineItems = [];

    if (roomData?.options) {
        for (const [key, quantity] of Object.entries(selectedRooms)) {
            const departureRoomId = parseInt(key);
            const room = roomData.options.find(
                r => r.departureRoomId === departureRoomId
            );

            if (room) {
                lineItems.push({
                    id: 'TourWithFlights',
                    type: 'Product',
                    label: 'Tour plus flights',
                    price: (room.price || 0) + flightPricePerPerson,
                    quantity: quantity,
                });
            }
        }
    }

    const atolFeePerPerson = calculatePerPersonPrice(
        flightsPrice,
        'atolFee',
        'customerCount'
    );

    lineItems.push({
        id: 'AtolFee',
        type: 'Extras',
        label: 'ATOL Protection',
        price: atolFeePerPerson,
        quantity: checkoutState.customerCount,
    });

    const isFullBalanceDue = [flightsPrice].some(
        price => price && moment().utc().diff(price.balanceDueDate, 'days') >= 0
    );

    const parseDate = date => (date ? new Date(date).toISOString() : null);
    const balanceDueDate = parseDate(flightsPrice?.balanceDueDate);

    return {
        totalDeposit: flightsPrice?.deposit ?? 0,
        isFullBalanceDue,
        balanceDueDate,
        lineItems,
    };
};

export const selectAccommodationProduct = (state, currencyCode) => {
    const accommodation = selectAccommodation(state);
    const roomPrice = accommodation.roomPrice;

    if (roomPrice.currencyCode !== currencyCode) {
        throw Error(`Currency code mismatch`);
    }

    const totalDeposit = roomPrice.deposit;
    const isFullBalanceDue =
        totalDeposit == null ||
        moment().utc().diff(roomPrice.balanceDueDate, 'days') >= 0;

    const balanceDueDate = roomPrice.balanceDueDate;

    return {
        totalDeposit: totalDeposit,
        isFullBalanceDue,
        balanceDueDate: balanceDueDate,
        lineItems: [
            {
                id: 'Accommodation',
                type: 'Product',
                label: 'Room rate',
                price:
                    roomPrice.discount > 0
                        ? roomPrice.originalPrice
                        : roomPrice.price,
                quantity: 1,
            },
            ...(roomPrice.discount > 0
                ? [
                      {
                          id: 'AccommodationDiscount',
                          type: 'Product',
                          label: 'Discount',
                          price: -roomPrice.discount,
                          quantity: 1,
                      },
                  ]
                : []),
        ],
    };
};

export const selectCancellationPolicy = state => {
    return state.checkout.accommodation || undefined;
};

export const selectCheckoutSummary = state => {
    const { checkout, tour, tourPackages, departures } = state;
    const product = selectProduct(state);
    const currencyCode = fromSite.selectSelectedCurrencyCode(state);

    let productSummary;
    if (product.type === 'Tour') {
        productSummary = selectTourDepartureProduct(
            tour,
            departures,
            product.departureId,
            checkout.tourSelectedRooms,
            currencyCode
        );
    }

    if (product.type === 'Package') {
        productSummary = selectDepartureFlightsProduct(
            tour,
            checkout,
            product.departureId,
            currencyCode
        );
    }

    const promoDiscount = selectPromoDiscount(checkout, currencyCode);

    if (promoDiscount) {
        productSummary.lineItems.push({
            id: 'PromoDiscount',
            type: 'Discount',
            label: 'Promo discount',
            price: -promoDiscount.discount.value,
            quantity: 1,
            note: promoDiscount.code,
        });
    }

    const total = productSummary.lineItems.reduce(
        (total, lineItem) => total + lineItem.price * lineItem.quantity,
        0
    );

    const balanceDueNow =
        !productSummary.isFullBalanceDue &&
        !!checkout.selectedPaymentPlan &&
        (checkout.selectedPaymentPlan.planType === 'deposit' ||
            checkout.selectedPaymentPlan.planType === 'monthly')
            ? productSummary.totalDeposit
            : total;

    return {
        ...productSummary,
        productType: product.type,
        tourPackages,
        total,
        currencyCode,
        balanceDueNow,
        isPromoCodeApplied: promoDiscount != null,
        monthlyPaymentPlan: selectMonthlyPaymentPlan(state),
    };
};

export const selectPaymentOptions = state => {
    if (!state.checkout.paymentPlans || state.checkout.paymentPlans.loading) {
        return null;
    }

    return {
        ...state.checkout.paymentPlans,
        plans: state.checkout.paymentPlans.plans.map(plan => {
            var momentisedPayments = plan.payments.map(payment => {
                return {
                    ...payment,
                    paymentType: payment.paymentType.toLowerCase(),
                    dateDue: moment(payment.dateDue),
                };
            });

            return {
                ...plan,
                planType: plan.planType.toLowerCase(),
                payments: momentisedPayments,
            };
        }),
    };
};

export const selectAccommodationCheckoutSummary = state => {
    const currencyCode = fromSite.selectSelectedCurrencyCode(state);
    const product = selectProduct(state);
    const paymentType = selectPaymentType(state);
    let monthlyPaymentPlan = selectMonthlyPaymentPlan(state);

    if (product.type !== 'AccommodationOnly') {
        return null;
    }

    const productSummary = selectAccommodationProduct(state, currencyCode);

    if (productSummary.isFullBalanceDue) {
        monthlyPaymentPlan = { isAvailable: false };
    }

    const total = productSummary.lineItems.reduce(
        (total, lineItem) => total + lineItem.price * lineItem.quantity,
        0
    );

    const balanceDueNow =
        !productSummary.isFullBalanceDue &&
        (paymentType === 'deposit' || paymentType === 'monthly')
            ? productSummary.totalDeposit
            : total;

    return {
        ...productSummary,
        productType: product.type,
        total,
        currencyCode,
        balanceDueNow,
        isPromoCodeApplied: false,
        monthlyPaymentPlan,
    };
};

export const selectAccommodationPaymentOptions = state => {
    const checkoutSummary = selectAccommodationCheckoutSummary(state);
    const monthlyPaymentPlan = selectMonthlyPaymentPlan(state);
    const { total, totalDeposit, balanceDueDate } = checkoutSummary;

    return [
        ...(!checkoutSummary.isFullBalanceDue &&
        monthlyPaymentPlan &&
        monthlyPaymentPlan.isAvailable
            ? [
                  {
                      type: 'monthly',
                      label: `${
                          monthlyPaymentPlan.payments.length - 1
                      } monthly payments`,
                      payments: [
                          ...monthlyPaymentPlan.payments.map((payment, i) => ({
                              paymentType: i === 0 ? 'deposit' : 'instalment',
                              amount: payment.amount,
                              dateDue: moment.utc(payment.dateDue),
                          })),
                      ],
                  },
              ]
            : []),
        {
            type: 'full',
            label: 'Full payment',
            payments: [
                {
                    paymentType: 'full',
                    amount: total,
                    dateDue: moment(),
                },
            ],
        },
        ...(!checkoutSummary.isFullBalanceDue
            ? [
                  {
                      type: 'deposit',
                      label: 'Low deposit',
                      payments: [
                          {
                              paymentType: 'deposit',
                              amount: totalDeposit,
                              dateDue: moment(),
                          },
                          {
                              dateDue: moment(balanceDueDate),
                              paymentType: 'balance',
                              amount: total - totalDeposit,
                          },
                      ],
                  },
              ]
            : []),
    ];
};
