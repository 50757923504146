import { ChangeEvent, FocusEvent } from 'react';
import * as classNames from 'classnames';
import TextInput from '../TextInput/TextInput';
import styles from './TextInputField.module.scss';

export interface TextInputFieldProps {
    value: string;
    name: string;
    label: string;
    subLabel?: string;
    error?: string;
    isTouched?: boolean;
    isRequired?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    type?: 'text' | 'email' | 'password' | 'search';
    icon?: 'search';
    placeholder?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    className?: string;
}

const TextInputField = ({
    value,
    label,
    name,
    error = '',
    isRequired,
    isTouched,
    onChange,
    onBlur,
    onFocus,
    onKeyUp,
    type,
    placeholder,
    subLabel,
    className,
    inputRef,
    ...props
}: TextInputFieldProps) => {
    return (
        <div className={classNames(styles.wrapper, className)} {...props}>
            {label && (
                <label className={styles.label} htmlFor={name}>
                    {label}
                    {isRequired && <span>*</span>}
                    {subLabel && (
                        <span className={styles.subLabel}>{subLabel}</span>
                    )}
                </label>
            )}

            <TextInput
                type={type}
                name={name}
                isRequired={isRequired}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                inputRef={inputRef}
                onKeyUp={onKeyUp}
            />

            {(isTouched === true || isTouched === undefined) &&
                error &&
                error.length > 0 && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export default TextInputField;
