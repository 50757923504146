import React from 'react';
import { logErrorWithCustomMessage } from '../../utils/sentry';
import { PrimaryButton } from '../common/Button';
import PageLoading from '../PageLoading/PageLoading';
import { isPreRender } from '../../utils/config';
import styles from './AsyncLoader.module.scss';

const AsyncLoader = props => {
    if (props.error) {
        if (!isPreRender()) {
            logErrorWithCustomMessage(
                new Error('Error Loading async component'),
                'Async component Error',
                props.error
            );
        }
        return (
            <section className={styles.asyncLoader}>
                <h2>Oh no! We've encountered a problem loading this page!</h2>
                <PrimaryButton
                    as="button"
                    onClick={() => window.location.reload(true)}
                >
                    Click here to refresh
                </PrimaryButton>
            </section>
        );
    } else if (props.timedOut) {
        logErrorWithCustomMessage(
            new Error('Async component request timed out'),
            'Async component Error'
        );
        return (
            <section className={styles.asyncLoader}>
                <h2>
                    Oh no! It looks like your request timed out! Please check
                    your internet connection and click below to try again.
                </h2>
                <PrimaryButton
                    as="button"
                    onClick={() => window.location.reload(true)}
                >
                    Click here to refresh
                </PrimaryButton>
            </section>
        );
    } else {
        return <PageLoading />;
    }
};

export default AsyncLoader;
