/* eslint-disable */
String.prototype.replaceAll = function (str1, str2, ignore) {
    return this.replace(
        new RegExp(
            str1.replace(
                /([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,
                '\\$&'
            ),
            ignore ? 'gi' : 'g'
        ),
        typeof str2 == 'string' ? str2.replace(/\$/g, '$$$$') : str2
    );
};

export const decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    let element = document.createElement('div');

    function decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();

export const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
export const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isCheckoutPage = pageUrl => {
    if (pageUrl === null || pageUrl === undefined) {
        return false;
    }

    let isCheckoutPage = false;
    if (
        pageUrl.includes('/checkout/payment/') ||
        pageUrl.includes('/checkout/accom-only/payment') ||
        pageUrl.includes('/checkout/flights') ||
        ((pageUrl.includes('/holdspace/') ||
            pageUrl.includes('holdspace/convert/')) &&
            !pageUrl.includes('/holdspace/convert/confirmation') &&
            !pageUrl.includes('/holdspace/confirmation'))
    ) {
        isCheckoutPage = true;
    }

    return isCheckoutPage;
};
