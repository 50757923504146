import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AtolLogo } from '../../../../assets/images/atol-logo-with-label.svg';

const AtolBadgeWithLabel = props => (
    <Link to="/atol" target="_blank" {...props}>
        <AtolLogo alt="ATOL member No. 11740" />
    </Link>
);

export default AtolBadgeWithLabel;
