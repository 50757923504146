import { logErrorWithInfo } from '../../sentry';
import ConversionCategory from '../enums/ConversionCategory';
import ConversionType from '../enums/ConversionType';
import IAnalyticsProvider from '../interfaces/IAnalyticsProvider';

export default class microsoftAnalytics implements IAnalyticsProvider {
    public sendProviderAdsEvent() {
        //Not implemented: Google/Mixpanel is used to view event data
    }
    public sendEventV2(): void {
        //Not implemented: Google/Mixpanel is used to view event data
    }

    public sendProviderPageView(label: string) {
        try {
            window.uetq = window.uetq || [];

            window.uetq.push('event', 'PageLoad', {
                event_category: ConversionCategory.microsoftPageView,
                label: label,
            });
        } catch (error) {
            logErrorWithInfo('Failed to send Microsoft Ads page view.', {
                error,
                category: ConversionCategory.microsoftPageView,
                action: 'PageLoad',
                label,
            });
        }
    }

    public sendProviderAdsConversion(
        type: ConversionType,
        id: string,
        value: number | null = null,
        currency: string | null = null
    ) {
        try {
            window.uetq = window.uetq || [];

            window.uetq.push('event', 'Conversion', {
                event_category: type,
                label: id,
                value: value,
                currency: currency,
            });
        } catch (error) {
            logErrorWithInfo('Failed to send Microsoft Ads conversion.', {
                error,
                type,
                value,
                currency,
            });
        }
    }
}
