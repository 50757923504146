import React from 'react';
import Loadable from 'react-loadable';
import { getLatestVersion } from '../apis/versionApi';
import { appVersion } from './config';
import { compareVersions } from '../services/versionService';
import logger from './sentry';
import AsyncLoader from '../Components/AsyncLoader/AsyncLoader';

const asyncTimout = 10000; // 10 seconds
const updatingVersionCookieMaxAge = 600; // 10 minutes
const cookieName = 'tap_version_checked';

const asyncRouteLoader = (importFunction, asyncProps) => {
    return Loadable.Map({
        loader: {
            component: importFunction,
            version: () =>
                hasRecentlyVersionChecked()
                    ? Promise.resolve(appVersion)
                    : getLatestVersion().then(version => {
                          setRecentlyVersionChecked();
                          return version;
                      }),
        },
        render(loaded, props) {
            let Component = loaded.component.default;
            if (isAppOutOfDate(loaded.version)) {
                forceRefresh(loaded.version);
            }

            return <Component {...props} {...asyncProps} />;
        },
        loading: AsyncLoader,
        timeout: asyncTimout,
    });
};

const isAppOutOfDate = newVersion => {
    return compareVersions(appVersion, newVersion) < 0;
};

const forceRefresh = newVersion => {
    logger.logErrorMessage(
        `Users app out of date current: ${appVersion}, latest: ${newVersion}`
    );
    window.location.reload(true);
};

const hasRecentlyVersionChecked = () =>
    document.cookie
        .split(';')
        .map(c => c.split('=')[0])
        .filter(k => k.trim() === cookieName).length > 0;

const setRecentlyVersionChecked = () => {
    document.cookie = `${cookieName}=true;max-age=${updatingVersionCookieMaxAge};path=/;secure=true`;
};

export default asyncRouteLoader;
