import moment from 'moment';
import queryString from 'qs';
import searchHelper from '../helpers/searchHelper';
import isEqual from 'lodash.isequal';

export const queryDateFormat = 'YYYYMMDD';
export const apiDateFormat = 'YYYY-MM-DD';

// Converts the query string into elements that will populate the search tool
export const getSearchParamsFromQuery = queryStringParams => {
    const parsedParams = queryString.parse(queryStringParams, {
        ignoreQueryPrefix: true,
    });

    const checkInDate = moment(parsedParams.checkInDate, queryDateFormat);
    const checkOutDate = moment(parsedParams.checkOutDate, queryDateFormat);

    const params = {
        checkInDate: checkInDate.isValid()
            ? checkInDate.format(apiDateFormat)
            : null,
        checkOutDate: checkOutDate.isValid()
            ? checkOutDate.format(apiDateFormat)
            : null,
        adultCount: parsedParams.adultCount
            ? parseInt(parsedParams.adultCount)
            : null,
        childCount: parsedParams.childAges ? parsedParams.childAges.length : 0,
        childAges: parsedParams.childAges
            ? parsedParams.childAges.split(',')
            : [],
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return filteredParams;
};

// Generates a query string from the current search state
export const getQueryFromSearchParams = searchParams => {
    const params = {
        checkInDate: moment(searchParams.checkInDate).format(queryDateFormat),
        checkOutDate: moment(searchParams.checkOutDate).format(queryDateFormat),
        adultCount: searchParams.adultCount,
        childCount: searchParams.childAges ? searchParams.childAges.length : 0,
        childAges: searchParams.childAges
            ? searchParams.childAges.join(',')
            : null,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, {
        encode: false,
        arrayFormat: 'comma',
    });
};

// Converts the given search state into the query params expected by the API
export const getApiQueryFromSearchParams = searchParams => {
    const params = {
        accommodationId: searchParams.accommodationId,
        countryIsoCode2: searchParams.countryIsoCode2,
        slug: searchParams.slug,
        currencyCode: searchParams.currencyCode,
        checkInDate: moment(searchParams.checkInDate).format(apiDateFormat),
        checkOutDate: moment(searchParams.checkOutDate).format(apiDateFormat),
        adultCount: parseInt(searchParams.adultCount),
        childCount: searchParams.childAges ? searchParams.childAges.length : 0,
        childAges: searchParams.childAges
            ? searchParams.childAges.join(',')
            : null,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, {
        encode: false,
        arrayFormat: 'comma',
    });
};

export const hasSearchChanged = (queryParams, searchParams) => {
    const checkInDate = moment(searchParams.checkInDate);
    if (
        checkInDate.isValid() &&
        queryParams.checkInDate !== checkInDate.format(apiDateFormat)
    ) {
        return true;
    }

    const checkOutDate = moment(searchParams.checkOutDate);
    if (
        checkOutDate.isValid() &&
        queryParams.checkOutDate !== checkOutDate.format(apiDateFormat)
    ) {
        return true;
    }

    if (queryParams.adultCount !== searchParams.adultCount) {
        return true;
    }

    if (queryParams.childCount !== searchParams.childCount) {
        return true;
    }

    if (
        queryParams.childAges.length !== searchParams.childAges.length &&
        !isEqual(queryParams.childAges.sort(), searchParams.childAges.sort())
    ) {
        return true;
    }

    return false;
};
