import { SortType, TourSearchParams } from '@/core/tourSearch';
import { TourSearchResponse } from '@/endurance/tourSearch';
import {
    FilterSearchAction,
    IncrementPageAction,
    IncrementPageFailureAction,
    IncrementPageSuccessAction,
    PopulateSearchParamsAction,
    ResetFiltersAction,
    ResetSearchAction,
    SearchTourAction,
    SearchTourFailureAction,
    SearchTourSuccessAction,
    SortSearchAction,
} from '@/redux/actions/tourSearch';

import * as TYPES from './types';

export const resetSearch = (sessionId: string): ResetSearchAction => {
    return {
        type: TYPES.RESET_SEARCH,
        sessionId,
    };
};

export const resetFilters = (sessionId: string): ResetFiltersAction => {
    return {
        type: TYPES.RESET_FILTERS,
        sessionId,
    };
};

export const populateSearchParams = (
    params: TourSearchParams,
    updateQueryString = false
): PopulateSearchParamsAction => {
    return {
        type: TYPES.POPULATE_SEARCH_PARAMS,
        updateQueryString,
        params,
    };
};

export const performSearch = (sessionId: string): SearchTourAction => {
    return {
        type: TYPES.PERFORM_SEARCH,
        sessionId,
    };
};

export const performSearchSuccess = (
    response: TourSearchResponse
): SearchTourSuccessAction => {
    return {
        type: TYPES.PERFORM_SEARCH_SUCCESS,
        response,
    };
};

export const performSearchFailure = (
    error: string
): SearchTourFailureAction => {
    return {
        type: TYPES.PERFORM_SEARCH_FAILURE,
        error,
    };
};

export const filterTourSearch = (
    params: TourSearchParams,
    sessionId: string
): FilterSearchAction => {
    return {
        type: TYPES.FILTER_SEARCH,
        params,
        sessionId,
    };
};

export const sortSearch = (
    sortType: SortType,
    sessionId: string
): SortSearchAction => {
    return {
        type: TYPES.SORT_SEARCH,
        sortType,
        sessionId,
    };
};

export const incrementPage = (
    sessionId: string,
    pageNumber: number
): IncrementPageAction => {
    return {
        type: TYPES.INCREMENT_PAGE,
        sessionId,
        pageNumber: pageNumber,
    };
};

export const incrementPageSuccess = (
    response: TourSearchResponse
): IncrementPageSuccessAction => {
    return {
        type: TYPES.INCREMENT_PAGE_SUCCESS,
        response,
    };
};

export const incrementPageFailure = (
    error: string
): IncrementPageFailureAction => {
    return {
        type: TYPES.INCREMENT_PAGE_FAILURE,
        error,
    };
};
