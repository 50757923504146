import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const Activities = ({ activities }) => {
    if (!activities) {
        return null;
    }

    return (
        <ul>
            {activities.map(activity => {
                const url = `/activities/${activity.slug}`;

                return (
                    <li key={url}>
                        <Link to={url}>{activity.title}</Link>
                    </li>
                );
            })}
        </ul>
    );
};

const mapStateToProps = state => ({
    activities: state.site.activities,
});

export default connect(mapStateToProps)(Activities);
