import { put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { isBot, isPreRender } from './../../utils/config';
import { LocalisationApi } from '../../apis/localisationApi';

import {
    requestSiteDataSuccess,
    requestSiteDataFailure,
    setCurrency,
} from '../actions/site.actions';
import contentApi from '../../apis/contentApi';
import { logErrorWithInfo } from '../../utils/sentry';

export function* requestSiteDataSaga() {
    try {
        if (
            !Cookies.get('TAP-SelectedCurrency') &&
            (isPreRender() || isBot())
        ) {
            yield put(setCurrency({ code: 'GBP' }));
        }

        const getSiteDataResponse = yield Promise.resolve(
            contentApi.getSiteData()
        );

        yield put(requestSiteDataSuccess(getSiteDataResponse));
    } catch (error) {
        yield put(requestSiteDataFailure(error, getBasicSiteData()));
    }
}

export function* requestLocalisationDataSaga() {
    if (!Cookies.get('TAP-SelectedCurrency')) {
        const localisationApi = new LocalisationApi();

        const localisationData = yield Promise.resolve(
            localisationApi.getLocalisation()
        );

        if (localisationData.isSuccess) {
            yield put(
                setCurrency({ code: localisationData.result.currencyCode })
            );
        } else {
            logErrorWithInfo(localisationData.errors);
        }
    }
}

function getBasicSiteData() {
    return {
        currencies: [
            {
                code: 'GBP',
                displayTelephoneNumber: '+44 (0)208 004 8886',
                displayTelephoneNumberTailormade: '0333 006 2683',
                flag: {
                    url: 'https://cdn.cosmicjs.com/e7817ba0-ab1f-11ea-acab-c986a56b1c02-united-kingdom.svg',
                    imgix_url:
                        'https://imgix.cosmicjs.com/e7817ba0-ab1f-11ea-acab-c986a56b1c02-united-kingdom.svg',
                },
                locale: 'en-GB',
                name: 'Pound Sterling',
                position: 'Left',
                space: false,
                symbol: '£',
                telephoneNumber: '+44-208-004-8886',
                telephoneNumberTailormade: '0333-006-2683',
            },
        ],
        header: {
            dealsMenuTitle: 'Deals',
            dealsMenuUrl: '/deals',
            dealsLinks: [],
        },
        reviews: [],
        regions: [],
        activities: [],
        saleBanner: null,
    };
}
