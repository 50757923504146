import * as TYPES from '../actions/types';
import RequestStatus from '../../helpers/enums/requestStatus';
import { apiDateFormat } from '../../services/accommodationSearchService';
import moment from 'moment';

const initialSearchParams = {
    searchText: '',
    checkInDate: null,
    checkOutDate: null,
    adultCount: 2,
    childCount: 0,
    childAges: [],
    minimumPricePerNight: null,
    maximumPricePerNight: null,
    starRatings: [],
    boardBases: [],
    facilities: [],
    accommodationTypes: [],
    source: null,
};

const initialSearchAggregations = {};

export const initialState = {
    searchParams: initialSearchParams,
    originalSearchParams: null,
    aggregations: initialSearchAggregations,
    originalAggregations: null,
    sessionId: null,
    status: RequestStatus.LOADING,
    errors: [],
    results: [],
    totalResults: 0,
    page: 1,
    pageSize: 20,
    sortType: 'Recommended',
    pagingStatus: RequestStatus.IDLE,
};

function getCurrentSearchParams(action) {
    return {
        ...initialSearchParams,
        searchText: action.params.searchText,
        checkInDate: action.params.checkInDate,
        checkOutDate: action.params.checkOutDate,
        adultCount: action.params.adultCount,
        childCount: action.params.childCount,
        childAges: action.params.childAges,
        minimumPricePerNight: action.params.minimumPricePerNight,
        maximumPricePerNight: action.params.maximumPricePerNight,
        facilities: action.params.facilities,
        starRatings: action.params.starRatings,
        boardBases: action.params.boardBases,
        accommodationTypes: action.params.accommodationTypes,
        source: action.params.source,
    };
}

const resetAccommodationSearch = (state, action) => {
    return {
        ...initialState,
    };
};

const searchAccommodation = state => {
    return {
        ...state,
        originalSearchParams: initialState.originalSearchParams,
        originalAggregations: initialState.originalAggregations,
        status: RequestStatus.LOADING,
    };
};

const searchAccommodationSuccess = (state, action) => {
    if (state.status !== RequestStatus.LOADING) {
        return state;
    }

    // Filter out rooms as they are handled by accommodationPrices.reducer
    const results = action.results.map(r => {
        // TODO: TAP-1818 This will likely need some tweaking to accommodate room info
        const { rooms, ...searchResult } = r;
        return searchResult;
    });

    return {
        ...state,
        results: results,
        aggregations: action.aggregations,
        sessionId: action.sessionId,
        totalResults: action.totalResults,
        totalFilteredResults: action.totalFilteredResults,
        page: action.pageNumber,
        request: {
            ...action.request,
            checkInDate: moment(action.request.checkInDate).format(
                apiDateFormat
            ),
            checkOutDate: moment(action.request.checkOutDate).format(
                apiDateFormat
            ),
            childAges: action.request.childAges
                ? action.request.childAges.split(',').map(x => parseInt(x))
                : [],
        },
        originalSearchParams:
            state.originalSearchParams === null
                ? state.searchParams
                : state.originalSearchParams,
        originalAggregations:
            state.originalAggregations === null && action.totalResults > 0
                ? action.aggregations
                : state.originalAggregations,
        status: RequestStatus.SUCCESS,
    };
};

const searchAccommodationFailure = (state, action) => {
    if (state.status !== RequestStatus.LOADING) {
        return state;
    }

    return {
        ...state,
        status: RequestStatus.FAILED,
        originalSearchParams: state.searchParams,
        totalResults: 0,
        totalFilteredResults: 0,
        errors: action.errors,
    };
};

const incrementAccommodationPage = (state, action) => {
    return {
        ...state,
        pagingStatus: RequestStatus.LOADING,
        page: state.page + 1,
    };
};

const incrementAccommodationPageSuccess = (state, action) => {
    return {
        ...state,
        originalAggregations:
            state.originalAggregations === null && action.totalResults > 0
                ? action.aggregations
                : state.originalAggregations,
        pagingStatus: RequestStatus.SUCCESS,
        results: [...state.results, ...action.results],
    };
};

const incrementAccommodationPageFailure = (state, action) => {
    return {
        ...state,
        pagingStatus: RequestStatus.FAILED,
        page: state.page - 1,
        errors: action.errors,
    };
};

const resetAccommodationFilters = (state, action) => {
    return {
        ...state,
        searchParams: state.originalSearchParams,
        aggregations: state.originalAggregations,
        page: initialState.page,
        sortType: initialState.sortType,
        status: RequestStatus.LOADING,
    };
};

const filterAccommodationSearch = (state, action) => {
    const params = getCurrentSearchParams(action);

    return {
        ...state,
        searchParams: params,
        originalSearchParams: state.originalSearchParams
            ? state.originalSearchParams
            : params,
        page: initialState.page,
        status: RequestStatus.LOADING,
    };
};

const sortAccommodationSearch = (state, action) => {
    return {
        ...state,
        page: initialState.page,
        sortType: action.sortType,
        status: RequestStatus.LOADING,
    };
};

const populateAccommodationSearchParams = (state, action) => {
    const params = getCurrentSearchParams(action);
    return {
        ...state,
        searchParams: params,
    };
};

const results = (state = initialState, action) => {
    const handlers = {
        [TYPES.ACCOM_SEARCH_RESET]: resetAccommodationSearch,
        [TYPES.ACCOM_SEARCH_FILTER_RESET]: resetAccommodationFilters,
        [TYPES.ACCOM_POPULATE_SEARCH_PARAMS]: populateAccommodationSearchParams,
        [TYPES.ACCOM_SEARCH_REQUEST]: searchAccommodation,
        [TYPES.ACCOM_SEARCH_SUCCESS]: searchAccommodationSuccess,
        [TYPES.ACCOM_SEARCH_FAILURE]: searchAccommodationFailure,
        [TYPES.ACCOM_SEARCH_INCREMENT_REQUEST]: incrementAccommodationPage,
        [TYPES.ACCOM_SEARCH_INCREMENT_SUCCESS]:
            incrementAccommodationPageSuccess,
        [TYPES.ACCOM_SEARCH_INCREMENT_FAILURE]:
            incrementAccommodationPageFailure,
        [TYPES.ACCOM_SEARCH_FILTER]: filterAccommodationSearch,
        [TYPES.ACCOM_SEARCH_SORT]: sortAccommodationSearch,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default results;

// Selectors
const selectState = state => state.accommodationSearch;

export const selectRequest = state => selectState(state).request;
export const selectSessionId = state => selectState(state).sessionId;
export const selectResults = state => selectState(state).results;
export const selectResultAggregations = state =>
    selectState(state).aggregations;
export const selectSearchErrors = state => selectState(state).errors;
export const selectSearchParams = state => selectState(state).searchParams;

export const selectPage = state => selectState(state).page;
export const selectPageSize = state => selectState(state).pageSize;
export const selectIsLoading = state =>
    selectState(state).status === RequestStatus.LOADING;
export const selectIsError = state =>
    selectState(state).status === RequestStatus.FAILED;
export const selectIsPageLoading = state =>
    selectState(state).pagingStatus === RequestStatus.LOADING;
export const selectSortType = state => selectState(state).sortType;

export const sortOptions = [
    {
        text: 'Recommended',
        value: 'Recommended',
    },
    {
        text: 'Price (lowest)',
        value: 'PriceAsc',
    },
    {
        text: 'Price (highest)',
        value: 'PriceDesc',
    },
];
