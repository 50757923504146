import React from 'react';
import classNames from 'classnames';
import tapLogo from '../../../assets/images/the-adventure-people-logo.svg';
import styles from './LoadingLogo.module.scss';

const LoadingLogo = ({ className, ...props }) => (
    <div className={classNames(styles.loadingLogo, className)} {...props}>
        <img src={tapLogo} alt="loading" />
    </div>
);

export default LoadingLogo;
