import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './PrimaryLoadingButton.module.scss';

const PrimaryLoadingButton = ({ className, ...props }) => {
    return (
        <PrimaryButton
            className={classNames(styles.primaryLoadingButton, className)}
            onClick={e => {
                e.preventDefault();
            }}
            {...props}
        >
            <div className={classNames(styles.loader)} />
        </PrimaryButton>
    );
};

PrimaryLoadingButton.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default PrimaryLoadingButton;
