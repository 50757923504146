import {
    GetTourPackageResponse,
    SearchParams,
    SearchTourDepartureResponse,
} from '@/apis/tourPackagesApi';
import {
    TourPackageDeparture,
    TourPackageGetRequest,
    TourPackageGetResponse,
} from '@/endurance/tourPackages';

import * as queryString from 'qs';
import { get } from './enduranceApi';

const tourPackageSearchUrl = '/tours/packages/search';
const tourPackagesUrl = '/tours/packages';

export function searchTourDepartures(
    params: SearchParams
): Promise<SearchTourDepartureResponse> {
    const query = queryString.stringify(params, {
        encode: true,
        arrayFormat: 'comma',
    });

    return get<TourPackageDeparture[]>(`${tourPackageSearchUrl}?${query}`).then(
        ({ result, isSuccess, errors }) => {
            if (!result) {
                throw new Error('Package not found');
            }

            return {
                success: isSuccess,
                errors: errors,
                departures: result,
            };
        }
    );
}

export function getTourPackage(
    params: TourPackageGetRequest
): Promise<GetTourPackageResponse> {
    const query = queryString.stringify(params, {
        encode: true,
        arrayFormat: 'comma',
    });

    return get<TourPackageGetResponse>(`${tourPackagesUrl}?${query}`).then(
        ({ result, isSuccess, errors }) => {
            if (!result) {
                throw new Error('Package not found');
            }

            return {
                success: isSuccess,
                errors: errors,
                tour: result.tour,
                flight: result.flight,
                prices: result.prices,
            };
        }
    );
}
