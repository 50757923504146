import { tapApiBaseUrl, tapApiAuthKey } from '../utils/config';
import { getApiQueryFromSearchParams as buildSearchQuery } from '../services/accommodationSearchService';
import { getApiQueryFromSearchParams as buildRoomSearchQuery } from '../services/accommodationRoomSearchService';

export const searchUrl = tapApiBaseUrl + '/accommodations/search';
export const searchRoomsUrl = searchUrl + '/rooms';

export function searchAccommodation(params) {
    const query = buildSearchQuery(params);
    return fetch(`${searchUrl}?${query}`, {
        headers: {
            'X-AuthKey': tapApiAuthKey,
        },
    }).then(response => response.json());
}

export function searchAccommodationRooms(params) {
    const query = buildRoomSearchQuery(params);
    return fetch(`${searchRoomsUrl}?${query}`, {
        headers: {
            'X-AuthKey': tapApiAuthKey,
        },
    }).then(response => response.json());
}

const accommodationSearchApi = {
    searchAccommodation,
    searchAccommodationRooms,
};

export default accommodationSearchApi;
