import { isDevelopment, appVersion } from '../utils/config';
import { logErrorWithCustomMessage } from '../utils/sentry';

export const getLatestVersion = () => {
    if (isDevelopment) {
        return Promise.resolve(appVersion);
    } else {
        return fetch('/version.json', {
            cache: 'no-store',
        })
            .then(response => response.json())
            .then(response => response.version)
            .catch(error => {
                logErrorWithCustomMessage(
                    error,
                    'Failed version check download'
                );
                return appVersion;
            });
    }
};

const versionApi = {
    getLatestVersion,
};

export default versionApi;
