import {
    RequestAirportDataAction,
    RequestAirportDataFailureAction,
    RequestAirportDataSuccessAction,
} from '@/redux/actions/flight';
import * as TYPES from './types';
import { Airport, AirportGroup } from '@/endurance/flights';

export const requestAirportData = (): RequestAirportDataAction => ({
    type: TYPES.FLIGHTS_AIRPORT_DATA_REQUEST,
});

export const requestAirportDataSuccess = (
    airports: Airport[],
    airportGroups: AirportGroup[]
): RequestAirportDataSuccessAction => ({
    type: TYPES.FLIGHTS_AIRPORT_DATA_SUCCESS,
    airports: airports,
    airportGroups: airportGroups,
});

export const requestAirportDataFailure = (
    errors: string[]
): RequestAirportDataFailureAction => ({
    type: TYPES.FLIGHTS_AIRPORT_DATA_FAILURE,
    errors,
});
