import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './LoadingText.module.scss';

const LoadingText = ({ text = 'Loading', className, ...props }) => {
    const [dots, setDots] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            if (dots.length < 3) {
                setDots(dots + '.');
            } else {
                setDots('');
            }
        }, 600);
        return () => clearInterval(interval);
    }, [dots]);

    return (
        <div className={classNames(styles.loadingText, className)} {...props}>
            <span>{text}</span>
            <span className={styles.dots}>{dots}</span>
        </div>
    );
};

LoadingText.propTypes = {
    text: PropTypes.string,
};

export default LoadingText;
