import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import MenuItem from '../MenuItem/MenuItem';
import SubMenu from '../SubMenu/SubMenu';
import Icon from '../../common/Icon/Icon';
import { toggleMobileMenu } from '../../../redux/actions/site.actions';
import styles from './MenuLink.module.scss';
import { sendEventV2 } from '../../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../../utils/analytics/enums/EventInteractionEnums';

const mobileMenuMaxSize = 767;

class MenuLink extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handlePointerEnter = this.handlePointerEnter.bind(this);
        this.handlePointerLeave = this.handlePointerLeave.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleOnSubMenuMouseLeave =
            this.handleOnSubMenuMouseLeave.bind(this);
    }

    state = {
        isOpen: false,
    };

    componentDidUpdate(prevProps) {
        const routeHasChanged = this.props.location !== prevProps.location;
        const mobileMenuHasClosed =
            prevProps.isMobileMenuActive === true &&
            this.props.isMobileMenuActive === false;

        if (routeHasChanged || mobileMenuHasClosed) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const {
            to,
            children,
            links,
            toggleMobileMenu,
            isMobileMenuActive,
            history,
            className,
            location,
            match,
            staticContext,
            ...props
        } = this.props;

        const isCurrentPage = location.pathname === to;
        const hasChildren = links && links.length > 0;

        return (
            <MenuItem
                className={classNames({
                    [styles.menuLink]: true,
                    [styles.isCurrentPage]: isCurrentPage,
                    [styles.hasChildren]: hasChildren,
                    [className]: !!className,
                })}
                onPointerEnter={this.handlePointerEnter}
                onPointerLeave={this.handlePointerLeave}
                {...props}
            >
                <Link to={to} onClick={this.handleClick}>
                    {children}
                    <Icon
                        className={styles.moreIcon}
                        icon="chevron"
                        height={null}
                        width={null}
                    />
                </Link>
                {hasChildren && (
                    <SubMenu
                        to={to}
                        isOpen={this.state.isOpen}
                        links={links}
                        onBack={this.handleBackClick}
                        onMouseLeave={this.handleOnSubMenuMouseLeave}
                    >
                        {children}
                    </SubMenu>
                )}
            </MenuItem>
        );
    }

    handlePointerEnter(event) {
        event.preventDefault();
        event.stopPropagation();
        const { links } = this.props;
        const hasChildren = links !== undefined && links.length > 0;

        if (
            !this.state.isOpen &&
            hasChildren &&
            event.pointerType === 'mouse' &&
            !isMobileMenu()
        ) {
            this.setState({ isOpen: true });
        }
    }

    handlePointerLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        {
            this.setState({ isOpen: false });
        }

        const { links } = this.props;
        const hasChildren = links !== undefined && links.length > 0;

        const shouldCloseMenu =
            this.state.isOpen &&
            event.pointerType === 'mouse' &&
            event.relatedTarget &&
            event.relatedTarget === window &&
            event.currentTarget &&
            !isMobileMenu() &&
            hasChildren;
        if (
            (shouldCloseMenu &&
                !event.relatedTarget.contains(event.currentTarget)) ||
            (shouldCloseMenu &&
                event.relatedTarget.className.includes('MenuItem_menuItem'))
        ) {
            this.setState({ isOpen: false });
        }
    }

    handleBackClick() {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        }
    }

    handleClick(event) {
        event.preventDefault();
        const { links, to, toggleMobileMenu, history } = this.props;
        const hasChildren = links !== undefined && links.length > 0;

        if (hasChildren) {
            this.setState({ isOpen: !this.state.isOpen });
        } else {
            sendEventV2(
                Page.header,
                Area.siteLinks,
                InteractionItem.siteLinks,
                Action.click,
                {
                    link: to,
                }
            );

            history.push(to);
            toggleMobileMenu();
        }
    }
    handleOnSubMenuMouseLeave() {
        this.setState({ isOpen: false });
    }
}

function isMobileMenu() {
    return window.innerWidth <= mobileMenuMaxSize;
}

MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    links: PropTypes.array,
    isMobileMenuActive: PropTypes.bool.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
};

function mapStateToProps({ site }) {
    return {
        isMobileMenuActive: site.isMobileMenuActive,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleMobileMenu: params => dispatch(toggleMobileMenu(params)),
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(MenuLink);
