import { CUSTOMERS_DETAILS_UPDATED } from '../actions/types';

export const initialState = {
    customers: null,
};

const updateCustomerDetails = (state, action) => {
    return {
        ...state,
        ...initialState,
        customers: action.customers,
    };
};

const customers = (state = initialState, action) => {
    const handlers = {
        [CUSTOMERS_DETAILS_UPDATED]: updateCustomerDetails,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default customers;

const selectState = state => state.customers;
export const selectCustomers = state => {
    return selectState(state).customers;
};
