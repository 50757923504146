import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MobileCurrencySelector from '../MobileCurrencySelector/MobileCurrencySelector';
import CurrencySelector from '../CurrencySelector/CurrencySelector';
import MenuLink from '../MenuLink/MenuLink';
import Icon from '../../common/Icon/Icon';
import { isLoginEnabled } from '../../../utils/config';
import EnquiryNumbers from '../../common/EnquiryNumbers/EnquiryNumbers';
import styles from './HeaderMenu.module.scss';
import { sendEventV2 } from '../../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../../utils/analytics/enums/EventInteractionEnums';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { getActivityLinks } from '../../../helpers/activitiesHelper';

function HeaderMenu({
    isLoading,
    isMobileMenuActive,
    dealsTitle,
    dealsUrl,
    deals,
    regions,
    activities,
    className,
    dispatch,
    telephoneNumber,
    displayTelephoneNumber,
    ...props
}) {
    const dealsLinks = deals.map(({ title, slug }) => ({
        to: `/deals/${slug}`,
        children: title,
    }));

    const { features } = useFeatureFlag();
    const tailorMadeMenuText = `${
        features?.siteWideTailorMadeRename ?? false
            ? 'Private Tour'
            : 'Tailor Made'
    } Holidays`;

    const destinationsLinks = [
        {
            to: '/destinations/popular',
            children: 'Popular',
            links: getPopularDestinationLinks(regions),
        },
        ...regions
            .map(({ title, slug, destinations }) => ({
                title,
                to: `/destinations/${slug}`,
                children: title,
                links: [
                    ...destinations
                        .map(({ title, slug }) => ({
                            title,
                            to: `/destinations/${slug}`,
                            children: title,
                        }))
                        .sort(sortByTitle),
                    {
                        to: `/destinations/${slug}`,
                        children: `View all ${title}`,
                    },
                ],
            }))
            .sort(sortByTitle),
        { to: '/around-the-world', children: 'Around the world' },
        { to: '/tailor-made', children: tailorMadeMenuText },
        { to: '/destinations', children: 'View all destinations' },
    ];

    const activityLinks = getActivityLinks(activities, tailorMadeMenuText);

    return (
        <nav
            className={classNames({
                [styles.headerMenu]: true,
                [styles.isActive]: isMobileMenuActive,
                [className]: !!className,
            })}
            {...props}
        >
            <div className={styles.wrapper}>
                <MobileCurrencySelector
                    className={styles.mobileCurrencySelector}
                />
                <ul className={styles.menuItems}>
                    <MenuLink
                        className={classNames(
                            styles.menuItem,
                            styles.link,
                            styles.home
                        )}
                        to="/"
                    >
                        <span>Home</span>
                    </MenuLink>
                    {isLoginEnabled && (
                        <MenuLink
                            className={classNames(
                                styles.menuItem,
                                styles.link,
                                styles.favourites
                            )}
                            to="/favourites"
                            onClick={() => {
                                sendEventV2(
                                    Page.header,
                                    Area.favourites,
                                    InteractionItem.favourites,
                                    Action.click,
                                    null
                                );
                            }}
                        >
                            <span>Favourites</span>
                            <Icon className={styles.icon} icon="emptyHeart" />
                        </MenuLink>
                    )}
                    <MenuLink
                        className={styles.menuItem}
                        to={dealsUrl}
                        links={dealsLinks}
                    >
                        {dealsTitle}
                    </MenuLink>
                    <MenuLink
                        className={styles.menuItem}
                        to="/destinations"
                        links={destinationsLinks}
                    >
                        Destinations
                    </MenuLink>
                    <MenuLink
                        className={styles.menuItem}
                        to="/activities"
                        links={activityLinks}
                    >
                        Adventures
                    </MenuLink>
                    {isLoginEnabled && (
                        <MenuLink
                            to="/myaccount"
                            className={classNames(
                                styles.menuItem,
                                styles.link,
                                styles.myAccount
                            )}
                        >
                            <span>My Account</span>
                            <Icon icon="userSolid" className={styles.icon} />
                        </MenuLink>
                    )}
                    <li className={styles.enquiryNumbers}>
                        <EnquiryNumbers />
                    </li>
                </ul>
                <CurrencySelector className={styles.currencySelector} />
            </div>
        </nav>
    );
}

function getPopularDestinationLinks(regions) {
    const popularLinks = [];

    if (regions) {
        regions.forEach(region => {
            region.destinations.forEach(destination => {
                if (destination.isPopular) {
                    popularLinks.push({
                        to: `/destinations/${destination.slug}`,
                        children: destination.title,
                        links: [],
                    });
                }
            });
        });
    }

    return popularLinks.sort(sortByChildren);
}

function sortByTitle(a, b) {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
}

function sortByChildren(a, b) {
    const titleA = a.children.toLowerCase();
    const titleB = b.children.toLowerCase();

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
}

HeaderMenu.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isMobileMenuActive: PropTypes.bool.isRequired,
    dealsTitle: PropTypes.string.isRequired,
    deals: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ).isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            destinations: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                    isPopular: PropTypes.bool.isRequired,
                })
            ).isRequired,
        })
    ),
    activities: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            isPopular: PropTypes.bool.isRequired,
            isIconic: PropTypes.bool.isRequired,
        })
    ).isRequired,
};

function mapStateToProps({ site }) {
    return {
        isLoading: site.isLoading,
        isMobileMenuActive: site.isMobileMenuActive,
        dealsTitle: !site.isLoading ? site.header.dealsMenuTitle : '',
        dealsUrl: !site.isLoading ? site.header.dealsMenuUrl : '/deals',
        deals: !site.isLoading ? site.header.dealsLinks : [],
        regions: !site.isLoading ? site.regions : [],
        activities: !site.isLoading ? site.activities : [],
    };
}

export default connect(mapStateToProps)(HeaderMenu);
