import React from 'react';
import ExpandableSection from '../../common/ExpandableSection/ExpandableSection';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import styles from './PopularDestinations.module.scss';

export const PopularDestinations = ({ regions }) => {
    if (!regions) {
        return null;
    }

    return (
        <ul className={styles.popularDestinations}>
            {regions
                .filter(region => region.destinations.length > 0)
                .map((region, index) => (
                    <ExpandableSection
                        as="li"
                        key={index}
                        title={region.title}
                        arrowSize={15}
                        className={styles.expandable}
                    >
                        <ul>
                            {region.destinations
                                .filter(
                                    destination =>
                                        destination.hideOnDestinationPage ===
                                            false &&
                                        destination.slug !==
                                            window.location.pathname.substring(
                                                window.location.pathname.lastIndexOf(
                                                    '/'
                                                ) + 1
                                            )
                                )
                                .map(destination => {
                                    const url = `/destinations/${destination.slug}`;

                                    return (
                                        <li key={url}>
                                            <Link to={url}>
                                                {destination.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                        </ul>
                    </ExpandableSection>
                ))}
        </ul>
    );
};

const mapStateToProps = state => ({
    regions: state.site.regions,
});

export default connect(mapStateToProps)(PopularDestinations);
