import * as TYPES from '../actions/types';
import RequestStatus from '../../helpers/enums/requestStatus';

const initialSearchParams = {
    searchText: '',
    checkInDate: null,
    checkOutDate: null,
    adultCount: 2,
    childCount: 0,
    childAges: [],
    minimumPricePerNight: null,
    maximumPricePerNight: null,
    starRatings: [],
    boardBases: [],
    facilities: [],
    packagesTypes: [],
    source: null,
};

const initialSearchAggregations = {};

export const initialState = {
    searchParams: initialSearchParams,
    originalSearchParams: null,
    aggregations: initialSearchAggregations,
    originalAggregations: null,
    sessionId: null,
    status: RequestStatus.LOADING,
    errors: [],
    results: [],
    totalResults: 0,
    page: 1,
    pageSize: 20,
    sortType: 'Recommended',
    pagingStatus: RequestStatus.IDLE,
};

function getCurrentSearchParams(action) {
    return {
        ...initialSearchParams,
        searchText: action.params.searchText,
        checkInDate: action.params.checkInDate,
        checkOutDate: action.params.checkOutDate,
        adultCount: action.params.adultCount,
        childCount: action.params.childCount,
        childAges: action.params.childAges,
        minimumPricePerNight: action.params.minimumPricePerNight,
        maximumPricePerNight: action.params.maximumPricePerNight,
        facilities: action.params.facilities,
        starRatings: action.params.starRatings,
        boardBases: action.params.boardBases,
        packagesTypes: action.params.packagesTypes,
        source: action.params.source,
    };
}

const resetPackagesSearch = (state, action) => {
    return {
        ...initialState,
    };
};

const searchPackages = state => {
    return {
        ...state,
        originalSearchParams: initialState.originalSearchParams,
        originalAggregations: initialState.originalAggregations,
        status: RequestStatus.LOADING,
    };
};

const searchPackagesSuccess = (state, action) => {
    if (state.status !== RequestStatus.LOADING) {
        return state;
    }

    const results = action.results;
    return {
        ...state,
        results: results,
        aggregations: action.aggregations,
        sessionId: action.sessionId,
        totalResults: action.totalResults,
        totalFilteredResults: action.totalFilteredResults,
        page: action.pageNumber,
        request: {
            ...action.request,
            // checkInDate: moment(action.request.checkInDate).format(
            //     apiDateFormat
            // ),
            // checkOutDate: moment(action.request.checkOutDate).format(
            //     apiDateFormat
            // ),
            // childAges: action.request.childAges
            //     ? action.request.childAges.split(',').map(x => parseInt(x))
            //     : [],
        },
        originalSearchParams:
            state.originalSearchParams === null
                ? state.searchParams
                : state.originalSearchParams,
        originalAggregations:
            state.originalAggregations === null && action.totalResults > 0
                ? action.aggregations
                : state.originalAggregations,
        status: RequestStatus.SUCCESS,
    };
};

const searchPackagesFailure = (state, action) => {
    if (state.status !== RequestStatus.LOADING) {
        return state;
    }

    return {
        ...state,
        status: RequestStatus.FAILED,
        originalSearchParams: state.searchParams,
        totalResults: 0,
        totalFilteredResults: 0,
        errors: action.errors,
    };
};

const incrementPackagesPage = (state, action) => {
    return {
        ...state,
        pagingStatus: RequestStatus.LOADING,
        page: state.page + 1,
    };
};

const incrementPackagesPageSuccess = (state, action) => {
    return {
        ...state,
        originalAggregations:
            state.originalAggregations === null && action.totalResults > 0
                ? action.aggregations
                : state.originalAggregations,
        pagingStatus: RequestStatus.SUCCESS,
        results: [...state.results, ...action.results],
    };
};

const incrementPackagesPageFailure = (state, action) => {
    return {
        ...state,
        pagingStatus: RequestStatus.FAILED,
        page: state.page - 1,
        errors: action.errors,
    };
};

const resetPackagesFilters = (state, action) => {
    return {
        ...state,
        searchParams: state.originalSearchParams,
        aggregations: state.originalAggregations,
        page: initialState.page,
        sortType: initialState.sortType,
        status: RequestStatus.LOADING,
    };
};

const filterPackagesSearch = (state, action) => {
    const params = getCurrentSearchParams(action);

    return {
        ...state,
        searchParams: params,
        originalSearchParams: state.originalSearchParams
            ? state.originalSearchParams
            : params,
        page: initialState.page,
        status: RequestStatus.LOADING,
    };
};

const sortPackagesSearch = (state, action) => {
    return {
        ...state,
        page: initialState.page,
        sortType: action.sortType,
        status: RequestStatus.LOADING,
    };
};

const populatePackagesSearchParams = (state, action) => {
    const params = getCurrentSearchParams(action);
    return {
        ...state,
        searchParams: params,
    };
};

const results = (state = initialState, action) => {
    const handlers = {
        [TYPES.PACKAGES_SEARCH_RESET]: resetPackagesSearch,
        [TYPES.PACKAGES_SEARCH_FILTER_RESET]: resetPackagesFilters,
        [TYPES.PACKAGES_POPULATE_SEARCH_PARAMS]: populatePackagesSearchParams,
        [TYPES.PACKAGES_SEARCH_REQUEST]: searchPackages,
        [TYPES.PACKAGES_SEARCH_SUCCESS]: searchPackagesSuccess,
        [TYPES.PACKAGES_SEARCH_FAILURE]: searchPackagesFailure,
        [TYPES.PACKAGES_SEARCH_INCREMENT_REQUEST]: incrementPackagesPage,
        [TYPES.PACKAGES_SEARCH_INCREMENT_SUCCESS]: incrementPackagesPageSuccess,
        [TYPES.PACKAGES_SEARCH_INCREMENT_FAILURE]: incrementPackagesPageFailure,
        [TYPES.PACKAGES_SEARCH_FILTER]: filterPackagesSearch,
        [TYPES.PACKAGES_SEARCH_SORT]: sortPackagesSearch,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default results;

// Selectors
const selectState = state => state.packagesSearch;

export const selectRequest = state => selectState(state).request;
export const selectSessionId = state => selectState(state).sessionId;
export const selectResults = state => selectState(state).results;
export const selectResultAggregations = state =>
    selectState(state).aggregations;
export const selectSearchErrors = state => selectState(state).errors;
export const selectSearchParams = state => selectState(state).searchParams;

export const selectPage = state => selectState(state).page;
export const selectPageSize = state => selectState(state).pageSize;
export const selectIsLoading = state =>
    selectState(state).status === RequestStatus.LOADING;
export const selectIsError = state =>
    selectState(state).status === RequestStatus.FAILED;
export const selectIsPageLoading = state =>
    selectState(state).pagingStatus === RequestStatus.LOADING;
export const selectSortType = state => selectState(state).sortType;

export const sortOptions = [
    {
        text: 'Recommended',
        value: 'Recommended',
    },
    {
        text: 'Price (lowest)',
        value: 'PriceAsc',
    },
    {
        text: 'Price (highest)',
        value: 'PriceDesc',
    },
];
