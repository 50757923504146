import * as TYPES from '../actions/types';
import * as Cookies from 'js-cookie';
import * as config from './../../utils/config';
import { SiteState } from '@/redux/SiteState';
import {
    SetCurrencyAction,
    RequestSiteDataSuccessAction,
    RequestSiteDataFailureAction,
} from '@/redux/actions/site';
import { Action } from '@/redux/actions/Action';

const tapSelectedCurrency = Cookies.get('TAP-SelectedCurrency') || null;
const tapSelectedPhoneNumber =
    Cookies.get('TAP-tapSelectedPhoneNumber') || null;

const initialState: SiteState = {
    isLoading: true,
    isMobileMenuActive: false,
    isMobileSearchMenuActive: false,
    saleBanner: null,
    selectedCurrency: tapSelectedCurrency,
    selectedPhoneNumber: tapSelectedPhoneNumber,
    selectedCurrencyData: null,
    prevCurrency: null,
    currencies: [],
    error: null,
    agentHeroImageUrl: null,
    agentImageUrls: [],
    tailorMadeImageUrl: null,
    reviews: [],
    activities: [],
};

const requestSiteData = (state: SiteState): SiteState => {
    return {
        ...state,
    };
};

const requestLocalisationData = (state: SiteState) => {
    return {
        ...state,
    };
};

const requestSiteDataSuccess = (
    state: SiteState,
    action: RequestSiteDataSuccessAction
): SiteState => {
    let currencyIsAvailable = false;
    if (action.data.currencies && state.selectedCurrency) {
        currencyIsAvailable = action.data.currencies.some(
            c => c.code === state.selectedCurrency
        );
    }

    if (config.isPreRender()) {
        return {
            ...state,
            ...action.data,
            selectedCurrency: 'GBP',
            selectedPhoneNumber: state.selectedPhoneNumber,
            prevCurrency: 'GBP',
            isLoading: false,
        };
    }

    return {
        ...state,
        ...action.data,
        selectedCurrency: currencyIsAvailable ? state.selectedCurrency : null,
        prevCurrency: currencyIsAvailable ? state.selectedCurrency : null,
        isLoading: false,
    };
};

const requestSiteDataFailure = (
    state: SiteState,
    action: RequestSiteDataFailureAction
): SiteState => {
    return {
        ...state,
        ...action.data,
        isLoading: false,
        error: action.error,
    };
};

const setCurrency = (
    state: SiteState,
    action: SetCurrencyAction
): SiteState => {
    return {
        ...state,
        selectedCurrencyData: action.currency,
        selectedCurrency: action.currency.code,
        selectedPhoneNumber: action.currency.telephoneNumber,
        prevCurrency: action.currency.code,
    };
};

const resetCurrency = (state: SiteState): SiteState => {
    return {
        ...state,
        selectedCurrency: null,
    };
};

export const toggleMobileMenu = (state: SiteState): SiteState => {
    return {
        ...state,
        isMobileMenuActive: !state.isMobileMenuActive,
    };
};

export const closeMobileMenu = (state: SiteState): SiteState => {
    return {
        ...state,
        isMobileMenuActive: false,
    };
};

export const toggleMobileSearchMenu = (state: SiteState): SiteState => {
    return {
        ...state,
        isMobileSearchMenuActive: !state.isMobileSearchMenuActive,
    };
};

export const closeMobileSearchMenu = (state: SiteState): SiteState => {
    return {
        ...state,
        isMobileSearchMenuActive: false,
    };
};

const site = (state: SiteState = initialState, action: Action): SiteState => {
    const handlers: any = {
        [TYPES.REQUEST_SITE_DATA]: requestSiteData,
        [TYPES.REQUEST_SITE_DATA_SUCCESS]: requestSiteDataSuccess,
        [TYPES.REQUEST_SITE_DATA_FAILURE]: requestSiteDataFailure,
        [TYPES.SITE_SET_CURRENCY]: setCurrency,
        [TYPES.RESET_CURRENCY]: resetCurrency,
        [TYPES.SITE_TOGGLE_MOBILE_MENU]: toggleMobileMenu,
        [TYPES.SITE_CLOSE_MOBILE_MENU]: closeMobileMenu,
        [TYPES.SITE_TOGGLE_MOBILE_SEARCH_MENU]: toggleMobileSearchMenu,
        [TYPES.SITE_CLOSE_MOBILE_SEARCH_MENU]: closeMobileSearchMenu,
        [TYPES.REQUEST_LOCALISATION_DATA]: requestLocalisationData,
    };

    const handler: (site: SiteState, action?: Action) => SiteState =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default site;
