import { useState, useContext, ReactNode, createContext } from 'react';
import cookiesApi from '../apis/cookiesApi';
import { cookieBannerEnabled } from '../utils/config';
import { cookiePreferences } from '../utils/cookiesPreferences';
import { useSession } from './SessionContext';

export interface CookieAcceptanceContextValue {
    hasSetCookiePreferences: boolean;
    onAcceptAllCookies: () => void;
    onAcceptSpecificCookies: (acceptFunctionalCookies: boolean) => void;
}

export interface CookieAcceptanceProviderProps {
    children: ReactNode;
}

const CookieAcceptanceContext =
    createContext<CookieAcceptanceContextValue | null>(null);

export function useCookieAcceptance() {
    const context = useContext(CookieAcceptanceContext);
    if (!context) {
        throw new Error('No Cookie Acceptance Context initialised.');
    }
    return context;
}

export function CookieAcceptanceProvider({
    children,
}: CookieAcceptanceProviderProps) {
    const { sessionId } = useSession();
    const [hasSetCookiePreferences, setHasSetCookiePreferences] = useState(
        !cookieBannerEnabled
            ? true
            : cookiePreferences.getCookiePreferencesSet()
    );

    return (
        <CookieAcceptanceContext.Provider
            value={{
                hasSetCookiePreferences,
                onAcceptAllCookies,
                onAcceptSpecificCookies,
            }}
        >
            {children}
        </CookieAcceptanceContext.Provider>
    );

    function onAcceptAllCookies() {
        cookiePreferences.setAllCookiesAccepted();
        cookiePreferences.setCookiesPreferencesSet(true);
        setHasSetCookiePreferences(true);
        cookiesApi.storeSessionPreference(sessionId ?? 'UNSET', true);
    }

    function onAcceptSpecificCookies(acceptFunctionalCookies: boolean) {
        cookiePreferences.setFunctionalCookiesAccepted(acceptFunctionalCookies);
        cookiePreferences.setCookiesPreferencesSet(true);
        setHasSetCookiePreferences(true);
        cookiesApi.storeSessionPreference(
            sessionId ?? 'UNSET',
            acceptFunctionalCookies
        );
    }
}
