import * as Cookies from 'js-cookie';

const functionalCookiesAcceptedName = 'Functional-Cookies-Accepted';
const acceptAllCookiesName = 'Cookies-AcceptAll';
const cookiePreferencesSet = 'Cookies-Preferences-Set';
const expiryDays = 365;

export function getAcceptAllCookies() {
    const value = Cookies.get(acceptAllCookiesName);
    return (
        (value !== undefined && value === 'true') ||
        getFunctionalCookiesAccepted()
    );
}

export function getFunctionalCookiesAccepted() {
    const value = Cookies.get(functionalCookiesAcceptedName);
    return value !== undefined && value === 'true';
}

export function getCookiePreferencesSet() {
    const value = Cookies.get(cookiePreferencesSet);
    return value !== undefined && value === 'true';
}

export function setAllCookiesAccepted() {
    setFunctionalCookiesAccepted(true);
    Cookies.set(acceptAllCookiesName, true.toString(), {
        expires: expiryDays,
        secure: true,
    });
}

export function setFunctionalCookiesAccepted(value: boolean) {
    Cookies.set(functionalCookiesAcceptedName, value.toString(), {
        expires: expiryDays,
        secure: true,
    });
}

export function setCookiesPreferencesSet(value: boolean) {
    Cookies.set(cookiePreferencesSet, value.toString(), {
        expires: expiryDays,
        secure: true,
    });
}

export const cookiePreferences = {
    getAcceptAllCookies,
    getFunctionalCookiesAccepted,
    getCookiePreferencesSet,
    setAllCookiesAccepted,
    setFunctionalCookiesAccepted,
    setCookiesPreferencesSet,
};
