import { ApiResponse } from '@/apis';
import { LocalisationInfo } from '@/endurance/localisation/LocalisationInfo';
import { get } from './enduranceApi';
import { Localisation } from '@/core/Localisation';

export class LocalisationApi {
    public async getLocalisation(): Promise<ApiResponse<Localisation>> {
        return get<LocalisationInfo>('/localisation').then(
            ({ result, isSuccess, errors }) => {
                const response: ApiResponse<LocalisationInfo> = {
                    isSuccess,
                    errors,
                    result: null,
                };

                if (result) {
                    try {
                        const localisation: Localisation = {
                            ip: result.ip,
                            currencyCode: result.currencyCode,
                            countryCode: result.countryCode,
                        };

                        response.result = localisation;
                    } catch (error) {
                        response.isSuccess = false;
                        response.errors = [
                            'Unexpected error mapping Localisation.',
                        ];
                    }
                }

                return response;
            }
        );
    }
}
