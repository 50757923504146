import Helmet from 'react-helmet';

function WebsiteStructuredData() {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "name": "The Adventure People",
                    "url": "https://theadventurepeople.com/",
                    "potentialAction" : {
                        "@type": "SearchAction",
                        "name": "Tour Search",
                        "target": "https://theadventurepeople.com/search?text={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }}`}
            </script>
        </Helmet>
    );
}

export default WebsiteStructuredData;
