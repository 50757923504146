import React from 'react';
import classNames from 'classnames';
import { SocialIcon } from 'react-social-icons';
import feefoLogo from '../../../assets/images/feefogoldtrustedbadge.svg';
import styles from './SocialBar.module.scss';

const SocialBar = ({ className }) => {
    return (
        <div className={classNames(styles.socialBar, className)}>
            <nav>
                <SocialIcon
                    network="facebook"
                    label="Facebook"
                    url="https://www.facebook.com/TheAdventurePeoples/"
                    className={styles.socialIcon}
                />
                <SocialIcon
                    network="instagram"
                    label="Instagram"
                    url="https://www.instagram.com/the_adventure_people/"
                    className={styles.socialIcon}
                />
                <SocialIcon
                    network="email"
                    label="Email"
                    url="mailto:hello@theadventurepeople.com"
                    className={styles.socialIcon}
                />
                <SocialIcon
                    network="youtube"
                    label="Youtube"
                    url="https://www.youtube.com/c/TheAdventurePeople"
                    className={styles.socialIcon}
                />
            </nav>
            <a
                className={styles.feefo}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.feefo.com/en-US/reviews/the-adventure-people?displayFeedbackType=SERVICE&timeFrame=ALL"
            >
                <img
                    alt="The Adventure People Feefo Rating"
                    src={feefoLogo}
                    loading="lazy"
                />
            </a>
        </div>
    );
};

export default SocialBar;
