import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BlueButton from '../BlueButton/BlueButton';
import styles from './BlueLoadingButton.module.scss';

const BlueLoadingButton = ({ className, ...props }) => {
    return (
        <BlueButton
            className={classNames(styles.blueLoadingButton, className)}
            onClick={e => {
                e.preventDefault();
            }}
            {...props}
        >
            <div className={classNames(styles.loader)} />
        </BlueButton>
    );
};

BlueLoadingButton.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default BlueLoadingButton;
