import { call, put, select } from 'redux-saga/effects';
import flightSearchApi from '../../apis/flightSearchApi';
import {
    performTourFlightSearchFailure,
    performTourFlightSearchSuccess,
    filterTourFlightSearchSuccess,
    filterTourFlightSearchFailure,
    incrementTourFlightSearchPageSuccess,
    incrementTourFlightSearchPageFailure,
} from '../actions/tourFlightSearch.actions';
import { getQueryFromSearchParams } from '../../services/tourFlightSearchService';

export const getState = state => state.tourFlightSearch;
export const getSelectedCurrency = state => {
    return state.site.selectedCurrency ? state.site.selectedCurrency : 'GBP';
};

export function populateTourFlightSearchParamsSaga(action) {
    if (action.updateQueryString) {
        updateSearchQueryString(
            action.tourId,
            action.departureId,
            action.params
        );
    }
}

export function* resetTourFlightSearchFiltersSaga(action) {
    const searchState = yield select(getState);
    updateSearchQueryString(
        searchState.tourId,
        searchState.tourDepartureId,
        searchState.searchParams
    );
    yield performTourFlightFilterSaga();
}

export function* filterTourFlightSearchSaga(action) {
    const searchState = yield select(getState);
    updateSearchQueryString(
        searchState.tourId,
        searchState.tourDepartureId,
        action.params
    );
    yield performTourFlightFilterSaga();
}

export function* performTourFlightFilterSaga(action) {
    const response = yield searchFlights();

    yield put(
        response.success
            ? filterTourFlightSearchSuccess(response)
            : filterTourFlightSearchFailure(
                  'Error retrieving filtered search results'
              )
    );
}

export function* sortTourFlightSearchSaga(action) {
    yield performTourFlightFilterSaga();
}

export function* performTourFlightSearchSaga(action) {
    const response = yield searchFlights();

    yield put(
        response.success
            ? performTourFlightSearchSuccess(response)
            : performTourFlightSearchFailure('Error retrieving search results')
    );
}

export function* incrementTourFlightSearchPageSaga(action) {
    const response = yield searchFlights();

    yield put(
        response.success
            ? incrementTourFlightSearchPageSuccess(response)
            : incrementTourFlightSearchPageFailure(
                  'Error retrieving search results'
              )
    );
}

function* searchFlights() {
    const searchState = yield select(getState);
    const selectedCurrency = yield select(getSelectedCurrency);

    return yield call(flightSearchApi.searchFlights, {
        ...searchState.searchParams,
        sessionId: searchState.sessionId,
        tourAvailabilityId: searchState.TourAvailabilityId,
        tourDepartureId: searchState.tourDepartureId,
        currencyCode: selectedCurrency,
        page: searchState.page,
        pageSize: searchState.pageSize,
        sortType: searchState.sortType,
    });
}

function updateSearchQueryString(tourId, departureId, searchParams) {
    const newQuery = getQueryFromSearchParams(searchParams);
    window.history.pushState(
        null,
        '',
        `/checkout/flights/${tourId}/${departureId}?${newQuery}`
    );
}
