import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import atolLogo from '../../../assets/images/atol-logo.svg';
import abtaLogo from '../../../assets/images/abta-logo-p8142.svg';
import attaLogo from '../../../assets/images/ATTA_logo.jpg';
import atasLogo from '../../../assets/images/atas-logo.png';
import travelWeeklyLogo from '../../../assets/images/travel-weekly-finalist.jpg';
import styles from './Memberships.module.scss';

const Memberships = ({ ...props }) => (
    <ul className={styles.memberships} {...props}>
        <li className={classNames(styles.membership, styles.atol)}>
            <Link to="/atol">
                <img
                    src={atolLogo}
                    alt="ATOL member No. 11740"
                    loading="lazy"
                    width="150px"
                    height="150px"
                />
            </Link>
        </li>
        <li className={styles.membership}>
            <Link to="/abta-member">
                <img
                    src={abtaLogo}
                    alt="ABTA member No. P8142"
                    loading="lazy"
                    width="135px"
                    height="75px"
                />
            </Link>
        </li>
        <li className={styles.membership}>
            <a
                href="https://www.adventuretravel.biz/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={attaLogo}
                    alt="ATTA member"
                    loading="lazy"
                    width="150px"
                    height="72px"
                />
            </a>
        </li>
        <li className={styles.membership}>
            <a
                href="https://www.touringandadventure.com/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={atasLogo}
                    alt="ATAS member"
                    loading="lazy"
                    width="113px"
                    height="75px"
                />
            </a>
        </li>
        <li className={styles.membership}>
            <img
                src={travelWeeklyLogo}
                alt="Travel Weekly 2019 awards - One to Watch finalist"
                loading="lazy"
                width="150px"
                height="41px"
            />
        </li>
    </ul>
);

export default Memberships;
