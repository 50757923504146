import { format, parseISO } from 'date-fns';
import queryString from 'qs';
import searchHelper from '../helpers/searchHelper';

const apiDateFormat = 'yyyy-MM-dd';

export const buildConfirmPriceQuery = requestParams => {
    const params = {
        accommodationId: requestParams.accommodationId,
        supplierId: requestParams.supplierId,
        token: requestParams.token,
        sessionId: requestParams.sessionId,
        countryIsoCode2: requestParams.countryIsoCode2,
        slug: requestParams.slug,
        currencyCode: requestParams.currencyCode,
        checkInDate: format(parseISO(requestParams.checkInDate), apiDateFormat),
        checkOutDate: format(
            parseISO(requestParams.checkOutDate),
            apiDateFormat
        ),
        adultCount: parseInt(requestParams.adultCount),
        childCount: requestParams.childAges
            ? requestParams.childAges.length
            : 0,
        childAges: requestParams.childAges
            ? requestParams.childAges.join(',')
            : null,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, {
        encode: false,
        arrayFormat: 'comma',
    });
};
