import * as TYPES from './types';

export const searchPackages = () => ({
    type: TYPES.PACKAGES_SEARCH_REQUEST,
});

export const searchPackagesSuccess = data => ({
    type: TYPES.PACKAGES_SEARCH_SUCCESS,
    ...data,
});

export const searchPackagesFailure = data => ({
    type: TYPES.PACKAGES_SEARCH_FAILURE,
    ...data,
});

export const incrementPackagesSearchPage = () => ({
    type: TYPES.PACKAGES_SEARCH_INCREMENT_REQUEST,
});

export const incrementPackagesSearchPageSuccess = data => ({
    type: TYPES.PACKAGES_SEARCH_INCREMENT_SUCCESS,
    ...data,
});

export const incrementPackagesSearchPageFailure = data => ({
    type: TYPES.PACKAGES_SEARCH_INCREMENT_FAILURE,
    ...data,
});

export const populatePackagesSearchParams = (
    params,
    updateQueryString = false
) => ({
    type: TYPES.PACKAGES_POPULATE_SEARCH_PARAMS,
    updateQueryString,
    params,
});

export const filterPackagesSearch = params => {
    return {
        type: TYPES.PACKAGES_SEARCH_FILTER,
        params,
    };
};

export const sortPackagesSearch = sortType => ({
    type: TYPES.PACKAGES_SEARCH_SORT,
    sortType,
});

export const resetPackagesSearch = () => ({
    type: TYPES.PACKAGES_SEARCH_RESET,
});

export const resetPackagesFilters = () => ({
    type: TYPES.PACKAGES_SEARCH_FILTER_RESET,
});
