import { FlightSearchRequest } from '@/endurance/flightSearch';
import { TourFlightSearchParams } from '@/core/tourFlightSearch';

import * as queryString from 'qs';
import { format, parseISO } from 'date-fns';
import searchHelper from '../helpers/searchHelper';

const minutesInDay = 1440;
const dateFormat = 'yyyy-MM-dd';

const mergeParams = (defaultParams: any, additionalParams: any) => {
    return { ...defaultParams, ...additionalParams };
};

export const getSearchParamsFromQuery = (
    queryStringParams: string
): TourFlightSearchParams => {
    const parsedParams: any = queryString.parse(queryStringParams, {
        ignoreQueryPrefix: true,
    });

    const params: TourFlightSearchParams = {
        sessionId: parsedParams.sessionId || null,
        departureAirportCode: parsedParams.departureAirportCode || null,
        departureAirportGroupCode:
            parsedParams.departureAirportGroupCode || null,
        adults: parsedParams.adults ? parseInt(parsedParams.adults) : 1,
        minPrice: parsedParams.minPrice
            ? parseInt(parsedParams.minPrice)
            : null,
        maxPrice: parsedParams.maxPrice
            ? parseInt(parsedParams.maxPrice)
            : null,
        departureDate: parsedParams.departureDate || null,
        arrivalDate: parsedParams.arrivalDate || null,
        returnDate: parsedParams.returnDate || null,
        departureAirports: parsedParams.departureAirports
            ? parsedParams.departureAirports.split(',')
            : null,
        arrivalAirports: parsedParams.arrivalAirports
            ? parsedParams.arrivalAirports.split(',')
            : null,
        returnAirports: parsedParams.returnAirports
            ? parsedParams.returnAirports.split(',')
            : null,
        carriers: parsedParams.carriers
            ? parsedParams.carriers.split(',')
            : null,
        stops: parsedParams.stops
            ? parsedParams.stops.split(',').map((s: string) => parseInt(s))
            : null,
        outboundDepartureTime:
            parsedParams.minOutboundDepartureTime ||
            parsedParams.maxOutboundDepartureTime
                ? {
                      min: parsedParams.minOutboundDepartureTime ?? 0,
                      max:
                          parsedParams.maxOutboundDepartureTime ?? minutesInDay,
                  }
                : null,
        returnDepartureTime:
            parsedParams.minReturnDepartureTime ||
            parsedParams.maxReturnDepartureTime
                ? {
                      min: parsedParams.minReturnDepartureTime ?? 0,
                      max: parsedParams.maxReturnDepartureTime ?? minutesInDay,
                  }
                : null,
        flexibility: parsedParams.flexibility ?? 0,
        airports: parsedParams.airports ? parsedParams.airports.split(',') : [],
    };

    return params;
};

export const getQueryFromSearchParams = (searchParams: any) => {
    const params = {
        adults: searchParams.adults,
        arrivalDate: searchParams.arrivalDate || null,
        departureDate: searchParams.departureDate || null,
        returnDate: searchParams.returnDate || null,
        departureAirportCode: searchParams.departureAirportCode,
        departureAirportGroupCode: searchParams.departureAirportGroupCode,
        departureAirports: searchParams.departureAirports
            ? searchParams.departureAirports.join(',')
            : null,
        arrivalAirports: searchParams.arrivalAirports
            ? searchParams.arrivalAirports.join(',')
            : null,
        returnAirports: searchParams.returnAirports
            ? searchParams.returnAirports.join(',')
            : null,
        carriers: searchParams.carriers
            ? searchParams.carriers.join(',')
            : null,
        stops: searchParams.stops ? searchParams.stops.join(',') : null,
        minOutboundDepartureTime: searchParams.outboundDepartureTime
            ? searchParams.outboundDepartureTime.min
            : null,
        maxOutboundDepartureTime: searchParams.outboundDepartureTime
            ? searchParams.outboundDepartureTime.max
            : null,
        minReturnDepartureTime: searchParams.returnDepartureTime
            ? searchParams.returnDepartureTime.min
            : null,
        maxReturnDepartureTime: searchParams.returnDepartureTime
            ? searchParams.returnDepartureTime.max
            : null,
        sortType: searchParams.sortType || null,
        flexibility:
            searchParams.flexibility && searchParams.flexibility > 0
                ? searchParams.flexibility
                : null,
        isCachedFlight: searchParams.isCachedFlight,
        flightToken: searchParams.flightToken || null,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, { encode: true });
};

export function getSearchParamsFromApiRequest(
    searchParams: TourFlightSearchParams,
    {
        filterArrivalAirports,
        filterReturnAirports,
        departureDate,
        arrivalDate,
        returnDate,
    }: FlightSearchRequest
) {
    return {
        ...searchParams,
        arrivalAirports: filterArrivalAirports?.split(',') ?? [],
        returnAirports: filterReturnAirports?.split(',') ?? [],
        departureDate: departureDate
            ? format(parseISO(departureDate), dateFormat)
            : null,
        arrivalDate: arrivalDate
            ? format(parseISO(arrivalDate), dateFormat)
            : null,
        returnDate: returnDate
            ? format(parseISO(returnDate), dateFormat)
            : null,
    };
}

export function getApiQueryFromSearchParams(searchParams: any): string {
    const defaultParams = {
        tourDepartureId: searchParams.tourDepartureId,
        sessionId: searchParams.sessionId,
        adults: searchParams.adults,
        pageSize: searchParams.pageSize,
        page: searchParams.page,
        sortType: searchParams.sortType,
        flexibility: searchParams.flexibility,
        departureAirportCode: searchParams.departureAirportCode,
        departureAirportGroupCode: searchParams.departureAirportGroupCode,
        filterDepartureAirports:
            searchParams.departureAirports &&
            searchParams.departureAirports.length > 0
                ? searchParams.departureAirports.join(',')
                : null,
        filterArrivalAirports:
            searchParams.arrivalAirports &&
            searchParams.arrivalAirports.length > 0
                ? searchParams.arrivalAirports.join(',')
                : null,
        filterReturnAirports:
            searchParams.returnAirports &&
            searchParams.returnAirports.length > 0
                ? searchParams.returnAirports.join(',')
                : null,
        stops:
            searchParams.stops && searchParams.stops.length > 0
                ? searchParams.stops.join(',')
                : null,
        carriers:
            searchParams.carriers && searchParams.carriers.length > 0
                ? searchParams.carriers.join(',')
                : null,
        minOutboundDepartureTime: searchParams.outboundDepartureTime?.min,
        maxOutboundDepartureTime: searchParams.outboundDepartureTime?.max,
        minReturnDepartureTime: searchParams.returnDepartureTime?.min,
        maxReturnDepartureTime: searchParams.returnDepartureTime?.max,
    };
    const additionalParams = {
        adultCount: searchParams.adultCount,
        currencyCode: searchParams.currencyCode,
        departureId: searchParams.departureId,
        tourId: searchParams.tourId,

        departureRoomId: searchParams.departureRoomId
            ? parseInt(searchParams.departureRoomId)
            : null,

        flightToken: searchParams.flightToken,

        flightSupplierId: searchParams.flightSupplierId
            ? parseInt(searchParams.flightSupplierId)
            : null,
        isCachedFlight: searchParams.isCachedFlight || null,
        showAlternativeFlights: true,
    };

    const params = mergeParams(defaultParams, additionalParams);
    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, { encode: true });
}
