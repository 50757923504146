import * as TYPES from '../actions/types';
import Cookies from 'js-cookie';
import { addFavourites, unFavourite } from '../../apis/accountApi';
import { logErrorWithInfo } from '../../utils/sentry';

const initialState = {
    favourites: getTapFavouriteTours(),
};

function getTapFavouriteTours() {
    let favourites = [];
    const cookieFavouriteTours = Cookies.get('TAP-FavouriteTours') || '';

    if (cookieFavouriteTours) {
        favourites = cookieFavouriteTours.split(',');
    }

    return favourites;
}

const favouriteTour = (state, action) => {
    const cookieValue = Cookies.get('TAP-FavouriteTours') || '';
    const existingFavourites = cookieValue ? cookieValue.split(',') : [];
    const newFavourites = action.tourId
        ? [...existingFavourites, action.tourId]
        : existingFavourites;
    Cookies.set('TAP-FavouriteTours', newFavourites.join(','), {
        expires: 365,
        secure: true,
    });

    const accessToken = Cookies.get('access-token');
    if (accessToken !== 'null' && accessToken !== undefined) {
        addFavourites(accessToken, action.tourId).then(response => {
            if (!response.success) {
                logErrorWithInfo(
                    Error('Failed to add to favourites'),
                    response
                );
            }
        });
    }
    return {
        ...state,
        favourites: newFavourites,
    };
};

const unfavouriteTour = (state, action) => {
    const cookieValue = Cookies.get('TAP-FavouriteTours') || '';
    const existingFavourites = cookieValue ? cookieValue.split(',') : [];
    const newFavourites = existingFavourites.filter(
        tourId => tourId !== action.tourId
    );
    Cookies.set('TAP-FavouriteTours', newFavourites.join(','), {
        expires: 365,
        secure: true,
    });

    const accessToken = Cookies.get('access-token');
    if (accessToken !== 'null' && accessToken !== undefined) {
        unFavourite(accessToken, action.tourId).then(response => {
            if (!response.success) {
                logErrorWithInfo(
                    Error('Failed to remove from favourites'),
                    response
                );
            }
        });
    }

    return {
        ...state,
        favourites: newFavourites,
    };
};

const favourites = (state = initialState, action) => {
    const handlers = {
        [TYPES.FAVOURITES_FAVOURITE_TOUR]: favouriteTour,
        [TYPES.FAVOURITES_UNFAVOURITE_TOUR]: unfavouriteTour,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default favourites;
