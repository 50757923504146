import Footer from './Footer';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext';
import { useLocation } from 'react-router-dom';
import { isCheckoutPage } from '../../helpers/globalHelpers';

export default function FooterHoc() {
    const { features } = useFeatureFlag();
    const location = useLocation();

    const shouldHideFooter =
        (features?.siteWideRemoveLinksBookingPaths ?? false) &&
        isCheckoutPage(location?.pathname);

    return !shouldHideFooter && <Footer />;
}
