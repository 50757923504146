import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { Provider } from 'react-redux';

import App from './App';
import { unregister } from './registerServiceWorker';
import { store } from './redux/store';
import logger from './utils/sentry';
import config from './utils/config';
import tracking from './utils/tracking/trackingService';
import './helpers/globalHelpers';
import {
    requestLocalisationData,
    requestSiteData,
} from './redux/actions/site.actions';
import { requestActivities } from './redux/actions/lookups.actions';
import { SessionProvider } from './contexts/SessionContext';
import { createRoot } from 'react-dom/client';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

logger.init();
console.log(`App version: ${config.appVersion}`); // eslint-disable-line no-console
console.log(`Environment: ${config.environment}`); // eslint-disable-line no-console
unregister();

window.onerror = function (message, source, lineno, colno, error) {
    let errorinfo = {
        message: message,
        source: source,
        lineno: lineno,
        colno: colno,
        stack: error ? error.stack : null,
    };
    logger.logErrorWithInfo(error, errorinfo);
    return false;
};

tracking.setupTrackingCodes();

root.render(
    <Provider store={store}>
        <SessionProvider>
            <App />
        </SessionProvider>
    </Provider>
);

store.dispatch(requestSiteData());
store.dispatch(requestActivities());
store.dispatch(requestLocalisationData());
