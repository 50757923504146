import googleAnalytics from './providers/googleAnalytics';
import microsoftAnalytics from './providers/microsoftAnalytics';
import metaAnalytics from './providers/metaAnalytics';
import ConversionType from './enums/ConversionType';
import { conversionsEnabled } from '../config';
import { logErrorWithInfo } from '../sentry';
import { isBot, isPreRender } from '../config';
import ConversionCategory from './enums/ConversionCategory';
import mixpanelAnalytics from './providers/mixpanelAnalytics';
import {
    Page,
    Area,
    InteractionItem,
    Action,
} from './enums/EventInteractionEnums';
import EventData from './dataModels/EventData';

const analyticsProviders = [
    new googleAnalytics(),
    new microsoftAnalytics(),
    new metaAnalytics(),
    new mixpanelAnalytics(),
];

export function fireTracking(
    param: string | null = null,
    pageName?: Page,
    pageData?: any | null
) {
    let url: string = '';

    const bot = isBot() || isPreRender();

    if (!bot) {
        if (param === '404') {
            url = `/404?page=${window.location.pathname.substr(1)}`;
        } else {
            url = window.location.pathname + window.location.search;
        }

        analyticsProviders.forEach(provider =>
            provider.sendProviderPageView(url, pageName, pageData)
        );
    }
}

export function sendEvent(
    category: ConversionCategory,
    action: string,
    label: string | null = null,
    value: number | null = null
) {
    if (!(isPreRender() || isBot())) {
        analyticsProviders.forEach(provider =>
            provider.sendProviderAdsEvent(category, action, label, value)
        );
    } else {
        console.log('Bot detected, no analytics sent');
    }
}

export function sendEventV2(
    page: Page,
    area: Area,
    interactionItem: InteractionItem | string,
    action: Action,
    data: EventData | null
) {
    if (!(isPreRender() || isBot())) {
        analyticsProviders.forEach(provider =>
            provider.sendEventV2(page, area, interactionItem, action, data)
        );
    } else {
        console.log('Bot detected, no analytics sent');
    }
}

export function sendConversion(
    type: ConversionType,
    id: string,
    value: number | null = null,
    currency: string | null = null
) {
    if (conversionsEnabled) {
        if (!type || !id) {
            logErrorWithInfo('Failed to send conversion - Invalid request', {
                type,
                id,
                value,
                currency,
            });
        } else {
            analyticsProviders.forEach(provider =>
                provider.sendProviderAdsConversion(type, id, value, currency)
            );
        }
    }
}
