import * as TYPES from './types';

export const requestAccommodationRooms = roomsRequest => ({
    type: TYPES.ACCOM_ROOMS_REQUEST,
    ...roomsRequest,
});

export const requestAccommodationRoomsSuccess = (request, data) => ({
    type: TYPES.ACCOM_ROOMS_SUCCESS,
    ...data,
    request,
});

export const requestAccommodationRoomsFailure = data => ({
    type: TYPES.ACCOM_ROOMS_FAILURE,
    ...data,
});
