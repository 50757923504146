import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';
import Icon from '../../Icon/Icon';
import styles from './SecondaryInverseButton.module.scss';

const SecondaryInverseButton = ({
    icon,
    width,
    height,
    color,
    children,
    className,
    ...props
}) => {
    return (
        <Button
            className={classNames(styles.secondaryInverseButton, className)}
            {...props}
        >
            <span className={styles.children}>{children}</span>
            {!!icon && (
                <Icon
                    className={styles.icon}
                    icon={icon}
                    width={width}
                    height={height}
                    color={color}
                />
            )}
        </Button>
    );
};

SecondaryInverseButton.propTypes = {
    icon: propTypes.string,
    ...Button.propTypes,
};

export default SecondaryInverseButton;
