import {
    authServer,
    clientId,
    loginRedirectUri,
    logoutRedirectUri,
} from '../utils/config';
import { getResponse } from './enduranceApi';
import { BasicApiResponse } from '@/endurance';
import { LoginResponse } from '@/endurance/login/loginResponse';

interface LoginResult {
    success: boolean;
    error: any;
    result: LoginResponse | null;
}

export function login(accessCode: string): Promise<LoginResult> {
    return getResponse<BasicApiResponse<LoginResponse>>(
        `/Login?accessCode=${accessCode}&redirectUri=${encodeURIComponent(
            loginRedirectUri
        )}`
    ).then(response => {
        return {
            success: response.success,
            error: response.errors,
            result: response.result,
        };
    });
}

export function getLoginUrl(): string {
    return `${authServer}login?response_type=code&client_id=${clientId}&scope=openid&redirect_uri=${encodeURIComponent(
        loginRedirectUri
    )}`;
}

export function getLogoutUrl(): string {
    return `${authServer}logout?&client_id=${clientId}&logout_uri=${encodeURIComponent(
        logoutRedirectUri
    )}`;
}

const loginApi = {
    login,
    getLoginUrl,
};

export default loginApi;
