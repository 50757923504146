import { call, put } from 'redux-saga/effects';
import { fetchAgents } from '../../apis/agentApi';
import { logError, logErrorWithInfo } from '../../utils/sentry';
import {
    fetchAgentsSuccess,
    fetchAgentsFailure,
} from '../actions/agents.actions';

export function* requestAgents() {
    try {
        const response = yield call(fetchAgents);
        if (response.success) {
            yield put(fetchAgentsSuccess(response.result.agents));
        } else {
            yield put(fetchAgentsFailure('API failed to fetch.'));
            logErrorWithInfo(Error('Failed to fetch agents.'), {
                response,
            });
        }
    } catch (e) {
        logError(e);
        yield put(
            fetchAgentsFailure(`Unexpected error getting agents - ${e.message}`)
        );
    }
}
