import { buildConfirmPriceQuery } from '../services/packagesPricingService';
import { tapApiBaseUrl, tapApiAuthKey } from '../utils/config';
import { getApiQueryFromSearchParams as buildSearchQuery } from '../services/packagesSearchService';

const packagesUrl = `${tapApiBaseUrl}/packages/Search/destination`;
const confirmPriceUrl = `${packagesUrl}/pricing/confirmPrice`;

export function searchPackages(params) {
    const query = buildSearchQuery(params);
    return fetch(`${packagesUrl}?${query}`, {
        headers: {
            'X-AuthKey': tapApiAuthKey,
        },
    }).then(response => response.json());
}

const packagesApi = {
    searchPackages,
};

export default packagesApi;
