import { AttributeLookup } from '@/endurance/lookups/AttributeLookup';

const activities: AttributeLookup[] = [
    {
        name: 'Anything',
        group: 'Activity',
    },
    {
        name: 'Cycling',
        group: 'Activity',
    },
    {
        name: 'Everest Base Camp',
        group: 'Activity',
    },
    {
        name: 'Exploring',
        group: 'Activity',
    },
    {
        name: 'Family',
        group: 'Activity',
    },
    {
        name: 'Food',
        group: 'Activity',
    },
    {
        name: 'Kayaking',
        group: 'Activity',
    },
    {
        name: 'Multi Activity',
        group: 'Activity',
    },
    {
        name: 'National Parks',
        group: 'Activity',
    },
    {
        name: 'Polar',
        group: 'Activity',
    },
    {
        name: 'Rail',
        group: 'Activity',
    },
    {
        name: 'Road Trip',
        group: 'Activity',
    },
    {
        name: 'Safari',
        group: 'Activity',
    },
    {
        name: 'Sailing',
        group: 'Activity',
    },
    {
        name: 'Self Guided',
        group: 'Activity',
    },
    {
        name: 'Skiing',
        group: 'Activity',
    },
    {
        name: 'Snorkelling',
        group: 'Activity',
    },
    {
        name: 'Snowshoeing',
        group: 'Activity',
    },
    {
        name: 'Surfing',
        group: 'Activity',
    },
    {
        name: 'Swimming',
        group: 'Activity',
    },
    {
        name: 'Touring',
        group: 'Activity',
    },
    {
        name: 'Trekking',
        group: 'Activity',
    },
    {
        name: 'Wildlife',
        group: 'Activity',
    },
    {
        name: 'Working',
        group: 'Activity',
    },
];

export default activities;
