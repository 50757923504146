import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectTapTelephoneNumber } from '../../../../redux/selectors/site.selectors';

const TapPhoneNumber = ({
    displayTelephoneNumber,
    telephoneNumber,
    onClick,
    dispatch,
    ...props
}) => {
    return (
        <a href={`tel:${telephoneNumber}`} onClick={onClick} {...props}>
            {displayTelephoneNumber}
        </a>
    );
};

TapPhoneNumber.propTypes = {
    displayTelephoneNumber: PropTypes.string,
    telephoneNumber: PropTypes.string,
    onClick: PropTypes.func,
};

const mapStateToProps = ({ site }) => {
    const tapTelephoneNumber = selectTapTelephoneNumber(site);

    return {
        displayTelephoneNumber:
            site?.selectedCurrencyData?.displayTelephoneNumber ||
            tapTelephoneNumber.displayTelephoneNumber,
        telephoneNumber:
            site?.selectedCurrencyData?.telephoneNumber ||
            tapTelephoneNumber.telephoneNumber,
    };
};

export default connect(mapStateToProps)(TapPhoneNumber);
