import styles from './SalesHeader.module.scss';

export interface SalesHeaderProps {
    text: string;
    discountPercentage: number;
}

const SalesHeader = ({ text }: SalesHeaderProps) => {
    return (
        <div className={styles.SalesHeader}>
            <p>{text}</p>
        </div>
    );
};

export default SalesHeader;
