enum ConversionCategory {
    accommodationSearchResults = 'Accommodation Search Results',
    brochure = 'Brochure',
    contactUs = 'Contact Us',
    favourites = 'Favourites',
    flightContactUs = 'Flight Contact Us',
    flightsPageEngagement = 'Flights Page Engagement',
    flightEnquiryWidget = 'Flight Enquiry Widget',
    holdSpace = 'Hold Space',
    homePage = 'Home Page',
    localisation = 'Localisation',
    promoCodes = 'Promo Codes',
    searchTool = 'Search Tool',
    tailorMade = 'Tailor Made',
    tailorMadeSearch = 'Tailor Made Search Form',
    tailorMadeWidget = 'Tailor Made Widget',
    tourContactUs = 'Tour Contact Us',
    whoIsGoing = 'Who is going',
    tourPage = 'Tour Page',
    tourPackagePage = 'Tour Package Page',
    searchPageEditSearchEngagement = 'Search Page Edit Search Engagement',
    searchPageSortEngagement = 'Search Page Sort Engagement',
    factSheetDownload = 'Fact Sheet Download',
    flightSearchPopupEngagement = 'Flights Search Popup Engagement',
    accommodationPage = 'Accommodation Page',
    flightsEditSearchEngagement = 'Flights Edit Search Engagement',
    searchPageViewDetailsCta = 'Search Page View Details CTA',
    searchPageFilterEngagement = 'Search Page Filter Engagement',
    holdSpaceRequest = 'hold-space-request',
    microsoftPageView = 'PageView',
    booking = 'booking completion',
    mapInteraction = 'map-interaction',
}

export default ConversionCategory;
