import React from 'react';
import { withRouter } from 'react-router-dom';
import { logErrorWithCustomMessage } from '../../utils/sentry';

class ErrorBoundary extends React.PureComponent {
    state = {
        hasError: false,
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        logErrorWithCustomMessage(
            error,
            'CATASTROPHIC ERROR! REACT COLLAPSE',
            errorInfo
        );
    }

    componentDidUpdate() {
        if (this.state.hasError) {
            this.setState({ hasError: false });
            this.props.history.push('/error');
        }
    }

    render() {
        if (this.state.hasError) {
            return null;
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
