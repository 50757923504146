import { logErrorWithInfo } from '../../sentry';
import ConversionType from '../enums/ConversionType';
import IAnalyticsProvider from '../interfaces/IAnalyticsProvider';
import ConversionCategory from '../enums/ConversionCategory';
import experimentTrackingService from '../../../services/experimentTrackingService';
import { Action } from '../enums/Action';
import { Area, AreaMinifiedMap } from '../enums/Area';
import {
    InteractionItem,
    InteractionItemMinifiedMap,
} from '../enums/InteractionItem';
import { Page, PageMinifiedMap } from '../enums/Page';
import EventData from '../dataModels/EventData';

export default class googleAnalytics implements IAnalyticsProvider {
    public sendProviderAdsEvent(
        category: ConversionCategory,
        action: string,
        label: string | null = null,
        value: number | null = null
    ) {
        var experimentTrackingUUID =
            experimentTrackingService.getExperimentUUID();

        if (window.dataLayer) {
            try {
                window.dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: category,
                    eventAction: action,
                    eventLabel: label ?? undefined,
                    eventValue: value ?? undefined,
                    user_id: experimentTrackingUUID ?? undefined,
                });
            } catch (error) {
                logErrorWithInfo('Failed to send Google Analytics event.', {
                    error,
                    category,
                    action,
                    label,
                    value,
                });
            }
        }
    }

    sendEventV2(
        page: Page,
        area: Area,
        interactionItem: InteractionItem | string,
        action: Action,
        data: EventData | null
    ): void {
        var experimentTrackingUUID =
            experimentTrackingService.getExperimentUUID();

        if (window.dataLayer) {
            try {
                window.dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: this.minifyEventName(
                        page,
                        area,
                        interactionItem
                    ),
                    eventAction: action,
                    eventLabel: `${page}_${area}_${interactionItem}_${action}`,
                    eventValue: data ?? undefined,
                    user_id: experimentTrackingUUID ?? undefined,
                });
            } catch (error) {
                logErrorWithInfo('Failed to send Google Analytics event.', {
                    error,
                    page,
                    area,
                    interactionItem,
                    action,
                    data,
                });
            }
        }
    }

    minifyEventName(
        page: Page,
        area: Area,
        interactionItem: InteractionItem | string
    ) {
        var minifiedPageName = PageMinifiedMap[page];
        var minifiedAreaName = AreaMinifiedMap[area];
        let minifiedInteractionItem = '';
        if (typeof interactionItem === 'string') {
            minifiedInteractionItem = interactionItem;
        } else {
            minifiedInteractionItem =
                InteractionItemMinifiedMap[interactionItem];
        }

        return `${minifiedPageName}_${minifiedAreaName}_${minifiedInteractionItem}`;
    }

    public sendProviderPageView(_label: string, pageName?: string, data?: any) {
        var experimentTrackingUUID =
            experimentTrackingService.getExperimentUUID();

        if (window.dataLayer) {
            try {
                window.dataLayer.push({
                    user_id: experimentTrackingUUID ?? undefined,
                });
            } catch (error) {
                logErrorWithInfo(
                    'Failed to register custom user ID in google analytics.',
                    {
                        error,
                    }
                );
            }
        }

        //Send Specific Page View event for critical page paths
        if (window.dataLayer && pageName) {
            try {
                window.dataLayer.push({
                    event: 'GAEvent',
                    eventCategory: `${pageName}_View`,
                    eventAction: 'CriticalPageView',
                    eventLabel: `${pageName}_View`,
                    eventValue: data ?? undefined,
                    user_id: experimentTrackingUUID ?? undefined,
                });
            } catch (error) {
                logErrorWithInfo('Failed to send Google Analytics event.', {
                    error,
                    pageName,
                    data,
                });
            }
        }
    }

    public sendProviderAdsConversion(
        type: ConversionType,
        id: string,
        value: number | null = null,
        currency: string | null = null
    ) {
        if (window.dataLayer) {
            try {
                var experimentTrackingUUID =
                    experimentTrackingService.getExperimentUUID();

                window.dataLayer.push({
                    event: 'Conversion',
                    conversionType: type,
                    conversionTransactionId: id,
                    conversionCurrency: currency ?? undefined,
                    conversionValue: value ?? undefined,
                    user_id: experimentTrackingUUID ?? undefined,
                });
            } catch (error) {
                logErrorWithInfo('Failed to send Google Ads conversion.', {
                    error,
                    type,
                    value,
                    currency,
                });
            }
        }
    }
}
