import * as Sentry from '@sentry/browser';
import {
    appVersion,
    environment,
    isPreRender,
    isProduction,
} from '../utils/config';

const init = () => {
    Sentry.init({
        dsn: 'https://7882ba3649464d07984ded83a5c4abb6@sentry.io/1383045',
        release: isProduction ? `v${appVersion}` : null,
        environment: environment,
        integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
        beforeSend: event => {
            if (isPreRender()) {
                event.environment = 'Prerender';
                event.user = { ...(event.user || {}), username: 'Prerender' };
            }
            return event;
        },
    });
};

export const logError = error => {
    Sentry.withScope(scope => {
        scope.setExtra('User App Version', appVersion);
        Sentry.captureException(error);
    });
};

export const logErrorWithInfo = (error, errorInfo) => {
    if (Object.prototype.toString.call(errorInfo) !== '[object Object]') {
        errorInfo = {
            info: errorInfo,
        };
    }
    Sentry.withScope(scope => {
        errorInfo.pageUrl =
            window.location && window.location.href
                ? window.location.href
                : null;
        Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key]);
        });
        scope.setExtra('User App Version', appVersion);
        Sentry.captureException(error);
    });
};

export const logErrorWithCustomMessage = (
    error,
    message,
    errorInfo = undefined
) => {
    if (error instanceof Error && message !== undefined) {
        try {
            error.message = `${message}: ${error.message}`;
        } catch (error) {
            // In some older browsers Error.message is readonly so catch it so we still log
            console.warn('Error setting Error.message:', error); // eslint-disable-line no-console
        }
    } else if (message !== undefined) {
        error = new Error(message);
    }

    if (errorInfo !== undefined) {
        logErrorWithInfo(error, errorInfo);
    } else {
        logError(error);
    }
};

export const logMessage = message => {
    Sentry.captureMessage(message);
};

export const logErrorMessage = message => {
    Sentry.captureMessage(message, Sentry.Severity.Error);
};

const logger = {
    init,
    logError,
    logErrorWithInfo,
    logMessage,
    logErrorMessage,
};

export default logger;
