import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './BlueButton.module.scss';

const BlueButton = ({ className, ...props }) => {
    return (
        <Button
            className={classNames(styles.blueButton, className)}
            {...props}
        />
    );
};

BlueButton.propTypes = {
    ...Button.propTypes,
};

export default BlueButton;
