import {
    FETCH_AGENTS_REQUEST,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_FAILURE,
} from '../actions/types';

const initialState = {
    loading: false,
    agents: null,
    error: null,
};

const fetchAgentsRequest = state => ({ ...state, loading: true });
const fetchAgentsSuccess = (state, action) => ({
    ...state,
    loading: false,
    agents: action.agents,
});

const fetchAgentsFailure = (state, action) => ({
    ...state,
    loading: false,
    agents: [],
    error: action.error,
});

const agentReducer = (state = initialState, action) => {
    const handlers = {
        [FETCH_AGENTS_REQUEST]: fetchAgentsRequest,
        [FETCH_AGENTS_SUCCESS]: fetchAgentsSuccess,
        [FETCH_AGENTS_FAILURE]: fetchAgentsFailure,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default agentReducer;
