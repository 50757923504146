import { TourFlightSearchState } from '@/redux/TourFlightSearchState';
import { Action } from '@/redux/actions/Action';
import {
    FilterTourFlightSearchAction,
    FilterTourFlightSearchFailureAction,
    FilterTourFlightSearchSuccessAction,
    IncrementTourFlightSearchPageFailureAction,
    IncrementTourFlightSearchPageSuccessAction,
    PerformTourFlightSearchSuccessAction,
    PopulateTourFlightSearchParamsAction,
    SortTourFlightSearchAction,
} from '@/redux/actions/flightSearch';
import { PerformTourFlightSearchFailureAction } from '@/redux/actions/flightSearch/PerformTourFlightSearchFailureAction';
import {
    TourFlightSearchParams,
    TourFlightSearchAggregations,
} from '@/core/tourFlightSearch';

import {
    getQueryFromSearchParams,
    getSearchParamsFromApiRequest,
} from '../../services/tourFlightSearchService';
import * as TYPES from '../actions/types';

const initialSearchParams: TourFlightSearchParams = {
    sessionId: null,
    departureAirportCode: null,
    departureAirportGroupCode: null,
    adults: 1,
    arrivalDate: null,
    departureDate: null,
    returnDate: null,
    flexibility: 0,
    minPrice: null,
    maxPrice: null,
    outboundDepartureTime: null,
    returnDepartureTime: null,
    departureAirports: [],
    arrivalAirports: [],
    returnAirports: [],
    airports: [],
    carriers: [],
    stops: [],
};

const initialSearchAggregations: TourFlightSearchAggregations = {
    stops: [],
    departureAirports: [],
    arrivalAirports: [],
    returnAirports: [],
    carriers: [],
};

const initialState: TourFlightSearchState = {
    searchParams: initialSearchParams,
    request: null,
    originalSearchParams: null,
    aggregations: initialSearchAggregations,
    originalAggregations: null,
    isSearching: false,
    isFilteringOrSorting: false,
    isPaging: false,
    page: 1,
    pageSize: 20,
    sortType: 'PriceAsc',
    results: [],
    totalResults: 0,
    totalFilteredResults: 0,
    tourId: null,
    tourDepartureId: null,
    sessionId: null,
    errors: [],
};

function getCurrentSearchParams(
    action: PopulateTourFlightSearchParamsAction | FilterTourFlightSearchAction
) {
    return {
        ...initialSearchParams,
        departureAirportCode: action.params.departureAirportCode,
        departureAirportGroupCode: action.params.departureAirportGroupCode,
        departureDate: action.params.departureDate,
        arrivalDate: action.params.arrivalDate,
        returnDate: action.params.returnDate,
        adults: action.params.adults,
        adultCount: action.params.adults,
        minPrice: action.params.minPrice,
        maxPrice: action.params.maxPrice,
        departureAirports: action.params.departureAirports,
        arrivalAirports: action.params.arrivalAirports,
        returnAirports: action.params.returnAirports,
        carriers: action.params.carriers,
        stops: action.params.stops,
        outboundDepartureTime: action.params.outboundDepartureTime,
        returnDepartureTime: action.params.returnDepartureTime,
        flexibility: action.params.flexibility,
    };
}

const resetTourFlightSearch = (state: TourFlightSearchState) => {
    return {
        ...initialState,
        sessionId: state.sessionId,
    };
};

const resetTourFlightSearchFilters = (state: TourFlightSearchState) => {
    return {
        ...state,
        searchParams: {
            ...initialSearchParams,
            departureAirportCode: state.searchParams.departureAirportCode,
            departureAirportGroupCode:
                state.searchParams.departureAirportGroupCode,
            adults: state.searchParams.adults,
        },
        page: initialState.page,
    };
};

const populateTourFlightSearchParams = (
    state: TourFlightSearchState,
    action: PopulateTourFlightSearchParamsAction
) => {
    const params = getCurrentSearchParams(action);
    return {
        ...state,
        tourId: action.tourId,
        tourDepartureId: action.departureId,
        searchParams: params,
    };
};

const performTourFlightSearch = (state: TourFlightSearchState) => {
    return {
        ...state,
        originalSearchParams: initialState.originalSearchParams,
        originalAggregations: initialState.originalAggregations,
        isSearching: true,
    };
};

const performTourFlightSearchSuccess = (
    state: TourFlightSearchState,
    action: PerformTourFlightSearchSuccessAction
) => {
    const newSearchParams = getSearchParamsFromApiRequest(
        state.searchParams,
        action.response.request
    );

    if (
        state.originalSearchParams === null &&
        state.tourId &&
        state.tourDepartureId
    ) {
        replaceSearchQueryString(
            state.tourId,
            state.tourDepartureId,
            newSearchParams
        );
    }

    return {
        ...state,
        results: action.response.flights,
        totalResults: action.response.totalResults,
        totalFilteredResults: action.response.totalFilteredResults,
        aggregations: action.response.aggregations,
        request: action.response.request,
        originalSearchParams:
            state.originalSearchParams === null
                ? newSearchParams
                : state.originalSearchParams,
        searchParams:
            state.originalSearchParams === null
                ? newSearchParams
                : state.searchParams,
        originalAggregations:
            state.originalAggregations === null &&
            action.response.totalResults > 0
                ? action.response.aggregations
                : state.originalAggregations,
        isSearching: false,
        isFilteringOrSorting: false,
        sessionId: action.response.sessionId,
    };
};

const performTourFlightSearchFailure = (
    state: TourFlightSearchState,
    action: PerformTourFlightSearchFailureAction
) => {
    return {
        ...state,
        originalSearchParams: state.searchParams,
        isSearching: false,
        isFilteringOrSorting: false,
        error: action.error,
    };
};

const filterTourFlightSearch = (
    state: TourFlightSearchState,
    action: FilterTourFlightSearchAction
) => {
    const params = getCurrentSearchParams(action);
    return {
        ...state,
        searchParams: params,
        originalSearchParams: state.originalSearchParams
            ? state.originalSearchParams
            : params,
        page: initialState.page,
        isFilteringOrSorting: true,
    };
};

const filterTourFlightSearchSuccess = (
    state: TourFlightSearchState,
    action: FilterTourFlightSearchSuccessAction
) => {
    const newSearchParams = getSearchParamsFromApiRequest(
        state.searchParams,
        action.response.request
    );

    if (
        state.originalSearchParams === null &&
        state.tourId &&
        state.tourDepartureId
    ) {
        replaceSearchQueryString(
            state.tourId,
            state.tourDepartureId,
            newSearchParams
        );
    }

    return {
        ...state,
        results: action.response.flights,
        totalResults: action.response.totalResults,
        totalFilteredResults: action.response.totalFilteredResults,
        aggregations: action.response.aggregations,
        request: action.response.request,
        searchParams:
            state.originalSearchParams === null
                ? newSearchParams
                : state.searchParams,
        isFilteringOrSorting: false,
    };
};

const filterTourFlightSearchFailure = (
    state: TourFlightSearchState,
    action: FilterTourFlightSearchFailureAction
) => {
    return {
        ...state,
        originalSearchParams: state.searchParams,
        isFilteringOrSorting: false,
        error: action.error,
    };
};

const sortTourFlightSearch = (
    state: TourFlightSearchState,
    action: SortTourFlightSearchAction
) => {
    return {
        ...state,
        page: initialState.page,
        sortType: action.sortType,
        isFilteringOrSorting: true,
    };
};

const incrementTourFlightSearchPage = (state: TourFlightSearchState) => {
    return {
        ...state,
        isPaging: true,
        page: state.page + 1,
    };
};

const incrementTourFlightSearchPageSuccess = (
    state: TourFlightSearchState,
    action: IncrementTourFlightSearchPageSuccessAction
) => {
    return {
        ...state,
        results: [...state.results, ...action.response.flights],
        isPaging: false,
    };
};

const incrementTourFlightSearchPageFailure = (
    state: TourFlightSearchState,
    action: IncrementTourFlightSearchPageFailureAction
) => {
    return {
        ...state,
        isPaging: false,
        error: action.error,
    };
};

const tourFlightSearch = (
    state: TourFlightSearchState = initialState,
    action: Action
) => {
    const handlers: any = {
        [TYPES.RESET_TOUR_FLIGHT_SEARCH]: resetTourFlightSearch,
        [TYPES.RESET_TOUR_FLIGHT_SEARCH_FILTERS]: resetTourFlightSearchFilters,
        [TYPES.POPULATE_TOUR_FLIGHT_SEARCH_PARAMS]:
            populateTourFlightSearchParams,
        [TYPES.PERFORM_TOUR_FLIGHT_SEARCH]: performTourFlightSearch,
        [TYPES.PERFORM_TOUR_FLIGHT_SEARCH_SUCCESS]:
            performTourFlightSearchSuccess,
        [TYPES.PERFORM_TOUR_FLIGHT_SEARCH_FAILURE]:
            performTourFlightSearchFailure,
        [TYPES.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE]:
            incrementTourFlightSearchPage,
        [TYPES.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_SUCCESS]:
            incrementTourFlightSearchPageSuccess,
        [TYPES.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_FAILURE]:
            incrementTourFlightSearchPageFailure,
        [TYPES.FILTER_TOUR_FLIGHT_SEARCH]: filterTourFlightSearch,
        [TYPES.FILTER_TOUR_FLIGHT_SEARCH_SUCCESS]:
            filterTourFlightSearchSuccess,
        [TYPES.FILTER_TOUR_FLIGHT_SEARCH_FAILURE]:
            filterTourFlightSearchFailure,
        [TYPES.SORT_TOUR_FLIGHT_SEARCH]: sortTourFlightSearch,
    };

    const handler: (
        site: TourFlightSearchState,
        action?: Action
    ) => TourFlightSearchState = handlers[action.type];

    return handler ? handler(state, action) : state;
};

function replaceSearchQueryString(
    tourId: string,
    departureId: number,
    searchParams: TourFlightSearchParams
) {
    const newQuery = getQueryFromSearchParams(searchParams);
    const currentPath = window.location.pathname;

    // Check current path and adjust accordingly
    if (
        currentPath.toLowerCase().startsWith('/checkout/flights') &&
        tourId &&
        departureId
    ) {
        window.history.replaceState(
            null,
            '',
            `/checkout/flights/${tourId}/${departureId}?${newQuery}`
        );
    } else if (currentPath.toLowerCase().startsWith('/tour/packages')) {
        window.history.replaceState(null, '', `/tour/packages?${newQuery}`);
    }
}

export default tourFlightSearch;
