import { post } from './enduranceApi';

async function storeSessionPreference(
    sessionId: string,
    acceptFunctionalCookies: boolean
): Promise<void> {
    const url = `/Users/Cookies/SessionPreference`;
    post(url, {
        sessionId: sessionId,
        acceptFunctionalCookies: acceptFunctionalCookies,
    });
}

const cookiesApi = {
    storeSessionPreference,
};

export default cookiesApi;
