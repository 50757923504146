export const RESET_SEARCH = 'RESET_SEARCH';
export const RESET_FILTERS = 'RESET_FILTERS';
export const POPULATE_SEARCH_PARAMS = 'POPULATE_SEARCH_PARAMS';
export const PERFORM_SEARCH = 'PERFORM_SEARCH';
export const PERFORM_SEARCH_SUCCESS = 'PERFORM_SEARCH_SUCCESS';
export const PERFORM_SEARCH_FAILURE = 'PERFORM_SEARCH_FAILURE';
export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const INCREMENT_PAGE_SUCCESS = 'INCREMENT_PAGE_SUCCESS';
export const INCREMENT_PAGE_FAILURE = 'INCREMENT_PAGE_FAILURE';
export const FILTER_SEARCH = 'FILTER_SEARCH';
export const SORT_SEARCH = 'SORT_SEARCH';

export const REQUEST_TOUR = 'REQUEST_TOUR';
export const REQUEST_TOUR_SUCCESS = 'REQUEST_TOUR_SUCCESS';
export const REQUEST_TOUR_FAILURE = 'REQUEST_TOUR_FAILURE';

export const REQUEST_TOUR_DEPARTURES = 'REQUEST_TOUR_DEPARTURES';
export const REQUEST_TOUR_DEPARTURES_SUCCESS =
    'REQUEST_TOUR_DEPARTURES_SUCCESS';
export const REQUEST_TOUR_DEPARTURES_FAILURE =
    'REQUEST_TOUR_DEPARTURES_FAILURE';

export const TOUR_REQUEST_TOUR_ROOM_DATA = 'TOUR_REQUEST_TOUR_ROOM_DATA';
export const TOUR_REQUEST_TOUR_ROOM_DATA_SUCCESS =
    'TOUR_REQUEST_TOUR_ROOM_DATA_SUCCESS';
export const TOUR_REQUEST_TOUR_ROOM_DATA_FAILURE =
    'TOUR_REQUEST_TOUR_ROOM_DATA_FAILURE';

export const CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE';

export const CUSTOMER_DETAILS_CHANGED = 'CUSTOMER_DETAILS_CHANGED';

export const CHECKOUT_SET_CUSTOMER_COUNT = 'CHECKOUT_SET_CUSTOMER_COUNT';
export const CHECKOUT_SET_PAYMENT_TYPE = 'CHECKOUT_SET_PAYMENT_TYPE';
export const CHECKOUT_SET_TOUR_ACCOMMODATION_TYPE =
    'CHECKOUT_SET_TOUR_ACCOMMODATION_TYPE';

export const CHECKOUT_PRICE_CHANGE = 'CHECKOUT_PRICE_CHANGE';
export const CHECKOUT_PRICE_CHANGE_ACCEPT = 'CHECKOUT_PRICE_CHANGE_ACCEPT';

export const REQUEST_SITE_DATA = 'REQUEST_SITE_DATA';
export const REQUEST_SITE_DATA_SUCCESS = 'REQUEST_SITE_DATA_SUCCESS';
export const REQUEST_SITE_DATA_FAILURE = 'REQUEST_SITE_DATA_FAILURE';
export const SITE_SET_CURRENCY = 'SITE_SET_CURRENCY';
export const SITE_TOGGLE_MOBILE_MENU = 'SITE_TOGGLE_MOBILE_MENU';
export const SITE_CLOSE_MOBILE_MENU = 'SITE_CLOSE_MOBILE_MENU';
export const SITE_TOGGLE_MOBILE_SEARCH_MENU = 'SITE_TOGGLE_MOBILE_SEARCH_MENU';
export const SITE_CLOSE_MOBILE_SEARCH_MENU = 'SITE_CLOSE_MOBILE_SEARCH_MENU';

export const FAVOURITES_FAVOURITE_TOUR = 'FAVOURITES_FAVOURITE_TOUR';
export const FAVOURITES_UNFAVOURITE_TOUR = 'FAVOURITES_UNFAVOURITE_TOUR';

export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';

export const CHECKOUT_ADD_FLIGHTS = 'CHECKOUT_ADD_FLIGHTS';
export const CHECKOUT_REMOVE_FLIGHTS = 'CHECKOUT_REMOVE_FLIGHTS';

export const CHECKOUT_ADD_ACCOMMODATION = 'CHECKOUT_ADD_ACCOMMODATION';

export const REQUEST_PAYMENT_PLAN = 'REQUEST_PAYMENT_PLAN';
export const REQUEST_PAYMENT_PLAN_SUCCESS = 'REQUEST_PAYMENT_PLAN_SUCCESS';
export const REQUEST_PAYMENT_PLAN_FAILURE = 'REQUEST_PAYMENT_PLAN_FAILURE';

export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE';
export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS';
export const APPLY_PROMO_CODE_FAILURE = 'APPLY_PROMO_CODE_FAILURE';

export const FLIGHTS_SEARCH_REQUEST = 'FLIGHTS_SEARCH_REQUEST';
export const FLIGHTS_SEARCH_BY_DEPARTURE_REQUEST =
    'FLIGHTS_SEARCH_BY_DEPARTURE_REQUEST';
export const FLIGHTS_SEARCH_SUCCESS = 'FLIGHTS_SEARCH_SUCCESS';
export const FLIGHTS_SEARCH_FAILURE = 'FLIGHTS_SEARCH_FAILURE';

export const FLIGHTS_FILTER_ADDED = 'FLIGHTS_FILTER_ADDED';
export const FLIGHTS_FILTER_REMOVED = 'FIGHTS_FILTER_REMOVED';
export const FLIGHTS_FILTER_RANGE_UPDATED = 'FLIGHTS_FILTER_RANGE_UPDATED';
export const FLIGHTS_FILTERS_RESET = 'FLIGHTS_FILTERS_RESET';

export const FLIGHTS_SET_SORT_TYPE = 'FLIGHTS_SET_SORT_TYPE';

export const FLIGHTS_AIRPORT_DATA_REQUEST = 'FLIGHTS_AIRPORT_DATA_REQUEST';
export const FLIGHTS_AIRPORT_DATA_SUCCESS = 'FLIGHTS_AIRPORT_DATA_SUCCESS';
export const FLIGHTS_AIRPORT_DATA_FAILURE = 'FLIGHTS_AIRPORT_DATA_FAILURE';

export const ACCOM_REQUEST = 'ACCOM_REQUEST';
export const ACCOM_REQUEST_SUCCESS = 'ACCOM_REQUEST_SUCCESS';
export const ACCOM_REQUEST_FAILURE = 'ACCOM_REQUEST_FAILURE';

export const ACCOM_ROOMS_REQUEST = 'ACCOM_ROOMS_REQUEST';
export const ACCOM_ROOMS_SUCCESS = 'ACCOM_ROOMS_SUCCESS';
export const ACCOM_ROOMS_FAILURE = 'ACCOM_ROOMS_FAILURE';
export const ACCOM_ROOMS_SET_PARAMS = 'ACCOM_ROOMS_SET_PARAMS';

export const ACCOM_CONFIRM_PRICE_REQUEST = 'ACCOM_CONFIRM_PRICE_REQUEST';
export const ACCOM_CONFIRM_PRICE_SUCCESS = 'ACCOM_CONFIRM_PRICE_SUCCESS';
export const ACCOM_CONFIRM_PRICE_FAILURE = 'ACCOM_CONFIRM_PRICE_FAILURE';

export const ACCOM_SEARCH_REQUEST = 'ACCOM_SEARCH_REQUEST';
export const ACCOM_SEARCH_SUCCESS = 'ACCOM_SEARCH_SUCCESS';
export const ACCOM_SEARCH_FAILURE = 'ACCOM_SEARCH_FAILURE';

export const ACCOM_SEARCH_INCREMENT_REQUEST = 'ACCOM_SEARCH_INCREMENT_REQUEST';
export const ACCOM_SEARCH_INCREMENT_SUCCESS = 'ACCOM_SEARCH_INCREMENT_SUCCESS';
export const ACCOM_SEARCH_INCREMENT_FAILURE = 'ACCOM_SEARCH_INCREMENT_FAILURE';

export const ACCOM_SEARCH_RESET = 'ACCOM_SEARCH_RESET';
export const ACCOM_SEARCH_FILTER = 'ACCOM_SEARCH_FILTER';
export const ACCOM_SEARCH_FILTER_RESET = 'ACCOM_SEARCH_FILTER_RESET';
export const ACCOM_SEARCH_SORT = 'ACCOM_SEARCH_SORT';
export const ACCOM_POPULATE_SEARCH_PARAMS = 'ACCOM_POPULATE_SEARCH_PARAMS';

export const PACKAGES_REQUEST = 'PACKAGES_REQUEST';
export const PACKAGES_REQUEST_SUCCESS = 'PACKAGES_REQUEST_SUCCESS';
export const PACKAGES_REQUEST_FAILURE = 'PACKAGES_REQUEST_FAILURE';

export const PACKAGES_DESTINATIONS_REQUEST = 'PACKAGES_DESTINATIONS_REQUEST';
export const PACKAGES_DESTINATIONS_SUCCESS = 'PACKAGES_DESTINATIONS_SUCCESS';
export const PACKAGES_DESTINATIONS_FAILURE = 'PACKAGES_DESTINATIONS_FAILURE';
export const PACKAGES_DESTINATIONS_SET_PARAMS =
    'PACKAGES_DESTINATIONS_SET_PARAMS';

export const PACKAGES_CONFIRM_PRICE_REQUEST = 'PACKAGES_CONFIRM_PRICE_REQUEST';
export const PACKAGES_CONFIRM_PRICE_SUCCESS = 'PACKAGES_CONFIRM_PRICE_SUCCESS';
export const PACKAGES_CONFIRM_PRICE_FAILURE = 'PACKAGES_CONFIRM_PRICE_FAILURE';

export const PACKAGES_SEARCH_REQUEST = 'PACKAGES_SEARCH_REQUEST';
export const PACKAGES_SEARCH_SUCCESS = 'PACKAGES_SEARCH_SUCCESS';
export const PACKAGES_SEARCH_FAILURE = 'PACKAGES_SEARCH_FAILURE';

export const PACKAGES_SEARCH_INCREMENT_REQUEST =
    'PACKAGES_SEARCH_INCREMENT_REQUEST';
export const PACKAGES_SEARCH_INCREMENT_SUCCESS =
    'PACKAGES_SEARCH_INCREMENT_SUCCESS';
export const PACKAGES_SEARCH_INCREMENT_FAILURE =
    'PACKAGES_SEARCH_INCREMENT_FAILURE';

export const PACKAGES_SEARCH_RESET = 'PACKAGES_SEARCH_RESET';
export const PACKAGES_SEARCH_FILTER = 'PACKAGES_SEARCH_FILTER';
export const PACKAGES_SEARCH_FILTER_RESET = 'PACKAGES_SEARCH_FILTER_RESET';
export const PACKAGES_SEARCH_SORT = 'PACKAGES_SEARCH_SORT';
export const PACKAGES_POPULATE_SEARCH_PARAMS =
    'PACKAGES_POPULATE_SEARCH_PARAMS';

export const RESET_CURRENCY = 'RESET_CURRENCY';

export const RESET_TOUR_FLIGHT_SEARCH = 'RESET_TOUR_FLIGHT_SEARCH';
export const RESET_TOUR_FLIGHT_SEARCH_FILTERS =
    'RESET_TOUR_FLIGHT_SEARCH_FILTERS';
export const POPULATE_TOUR_FLIGHT_SEARCH_PARAMS =
    'POPULATE_TOUR_FLIGHT_SEARCH_PARAMS';
export const PERFORM_TOUR_FLIGHT_SEARCH = 'PERFORM_TOUR_FLIGHT_SEARCH';
export const PERFORM_TOUR_FLIGHT_SEARCH_SUCCESS =
    'PERFORM_TOUR_FLIGHT_SEARCH_SUCCESS';
export const PERFORM_TOUR_FLIGHT_SEARCH_FAILURE =
    'PERFORM_TOUR_FLIGHT_SEARCH_FAILURE';
export const INCREMENT_TOUR_FLIGHT_SEARCH_PAGE =
    'INCREMENT_TOUR_FLIGHT_SEARCH_PAGE';
export const INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_SUCCESS =
    'INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_SUCCESS';
export const INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_FAILURE =
    'INCREMENT_TOUR_FLIGHT_SEARCH_PAGE_FAILURE';
export const FILTER_TOUR_FLIGHT_SEARCH = 'FILTER_TOUR_FLIGHT_SEARCH';
export const FILTER_TOUR_FLIGHT_SEARCH_SUCCESS =
    'FILTER_TOUR_FLIGHT_SEARCH_SUCCESS';
export const FILTER_TOUR_FLIGHT_SEARCH_FAILURE =
    'FILTER_TOUR_FLIGHT_SEARCH_FAILURE';
export const SORT_TOUR_FLIGHT_SEARCH = 'SORT_TOUR_FLIGHT_SEARCH';

export const FETCH_AGENTS_REQUEST = 'FETCH_AGENTS_REQUEST';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILURE = 'FETCH_AGENTS_FAILURE';

export const CHECKOUT_TOUR_SET_ROOMS_SELECTED =
    'CHECKOUT_TOUR_SET_ROOMS_SELECTED';

export const LOOKUPS_ACTIVITIES_REQUEST = 'LOOKUPS_ACTIVITIES_REQUEST';
export const LOOKUPS_ACTIVITIES_SUCCESS = 'LOOKUPS_ACTIVITIES_SUCCESS';
export const LOOKUPS_ACTIVITIES_FAILURE = 'LOOKUPS_ACTIVITIES_FAILURE';

export const TOGGLE_TOUR_FLIGHT_INCLUSIVE_SWITCH =
    'TOGGLE_TOUR_FLIGHT_INCLUSIVE_SWITCH';

export const REQUEST_TOUR_FLIGHTS = 'REQUEST_TOUR_FLIGHTS';
export const REQUEST_TOUR_FLIGHTS_SUCCESS = 'REQUEST_TOUR_FLIGHTS_SUCCESS';
export const REQUEST_TOUR_FLIGHTS_FAILURE = 'REQUEST_TOUR_FLIGHTS_FAILURE';

export const SET_SEARCH_DEPARTURE = 'SET_SEARCH_DEPARTURE';
export const SET_SEARCH_DEPARTURE_TRAVELLERS =
    'SET_SEARCH_DEPARTURE_TRAVELLERS';

export const TOUR_DEPARTURES_SEARCH_REQUEST = 'TOUR_DEPARTURES_SEARCH_REQUEST';
export const TOUR_DEPARTURES_SEARCH_SUCCESS = 'TOUR_DEPARTURES_SEARCH_SUCCESS';
export const TOUR_DEPARTURES_SEARCH_FAILURE = 'TOUR_DEPARTURES_SEARCH_FAILURE';

export const TOUR_PACKAGES_GET_REQUEST = 'TOUR_PACKAGES_GET_REQUEST';
export const TOUR_PACKAGES_GET_SUCCESS = 'TOUR_PACKAGES_GET_SUCCESS';
export const TOUR_PACKAGES_GET_FAILURE = 'TOUR_PACKAGES_GET_FAILURE';
export const CUSTOMERS_DETAILS_UPDATED = 'CUSTOMERS_DETAILS_UPDATED';
export const SET_PACKAGE_SELECTED = 'SET_PACKAGE_SELECTED';

export const REQUEST_LOCALISATION_DATA = 'REQUEST_LOCALISATION_DATA';
