import { TourPackagesState } from '@/redux/TourPackagesState';

export const selectIsPackageNotFound = (state: TourPackagesState) => {
    const error = state.error;

    if (error && error.statusCode === 'NotFound') {
        return true;
    }

    return false;
};
export const selectIsNotFound = (state: TourPackagesState) => {
    const error = state.error;

    if (error && error.statusCode === 'BadRequest') {
        return true;
    }

    return false;
};

export const selectTourDepartures = (state: TourPackagesState) =>
    state.departures;
export const selectTourDeparturesLoading = (state: TourPackagesState) =>
    state.loading;
export const selectTourDeparturesError = (state: TourPackagesState) =>
    state.error;

export const selectTourPackage = (state: TourPackagesState) => state.package;
export const getTourLoading = (state: TourPackagesState) => state.loading;
export const getTourError = (state: TourPackagesState) => state.error;
export const getTourFlightInclusiveStatus = (state: TourPackagesState) =>
    state.flightInclusive;
