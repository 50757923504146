export function getActivityLinks(activities, tailorMadeText) {
    const activityLinks = [
        {
            type: 'popular',
            to: '/activities/popular',
            children: 'Popular',
            links: [],
        },
        { type: 'iconic', to: '/iconic-tours', children: 'Iconic', links: [] },
        {
            type: 'walking',
            to: '/activities/trekking',
            children: 'Walking & Trekking',
            links: [],
        },
        {
            type: 'cycling',
            to: '/activities/cycling',
            children: 'Cycling',
            links: [],
        },
        {
            type: 'exploring',
            to: '/activities/exploring',
            children: 'Exploring',
            links: [],
        },
        {
            type: 'wildlife',
            to: '/activities/wildlife',
            children: 'Wildlife',
            links: [],
        },
        {
            type: 'solo',
            to: '/activities/solo-travellers',
            children: 'Solo Travellers',
            links: [],
        },

        {
            type: 'other',
            to: '/activities',
            children: 'Other',
            links: [],
        },
        { to: '/tailor-made', children: tailorMadeText },
    ];

    const links = activities
        .map(({ slug, title, isPopular, isIconic, type, imageUrl }) => ({
            link: {
                to: `/activities/${slug}`,
                children: title,
                imageUrl,
            },
            title,
            isPopular,
            isIconic,
            type,
        }))
        .sort(sortByTitle);

    links.forEach(({ link, isPopular, isIconic, type, imageUrl }) => {
        if (isPopular) {
            activityLinks
                .find(({ type }) => type === 'popular')
                .links.push(link);
        }

        if (isIconic) {
            activityLinks
                .find(({ type }) => type === 'iconic')
                .links.push(link);
        }

        switch (type) {
            case 'walking':
            case 'cycling':
            case 'exploring':
            case 'wildlife':
            case 'solo':
                activityLinks
                    .find(activityLinks => activityLinks.type === type)
                    .links.push(link);
                break;
            default:
                activityLinks
                    .find(({ type }) => type === 'other')
                    .links.push(link);
                break;
        }
    });

    return activityLinks;
}

function sortByTitle(a, b) {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
}
