enum Area {
    searchWidget = 'SearchWidget',
    feefoButton = 'FeefoButton',
    recentlyViewedAdventures = 'RecentlyViewedAdventures',
    landingPages = 'LandingPages',
    meetOurPeople = 'MeetOurPeople',
    buildYourAdventure = 'BuildYourAdventure',
    FAQs = 'FAQs',
    stillHaveQuestions = 'StillHaveQuestions',
    blogPosts = 'BlogPosts',
    dealSignup = 'DealSignup',
    filters = 'Filters',
    sort = 'Sort',
    resultCard = 'ResultCard',
    searchPageCTA = 'SearchPageCTA',
    resultContainer = 'ResultContainer',
    tourHeader = 'TourHeader',
    stickyHeader = 'StickyHeader',
    photoGallery = 'PhotoGallery',
    whoIsThisFor = 'WhoIsThisFor',
    brochureLine = 'BrochureLine',
    contactCTA = 'ContactCTA',
    mapPopup = 'MapPopup',
    USPs = 'USPS',
    itinerary = 'Itinerary',
    map = 'Map',
    brochureCTA = 'BrochureCTA',
    PandA = 'PandA',
    reviews = 'Reviews',
    essentialInformation = 'EssentialInformation',
    questionCTA = 'QuestionCTA',
    similarAdventures = 'SimilarAdventures',
    navigation = 'Navigation',
    homeLink = 'HomeLink',
    searchBox = 'SearchBox',
    siteLinks = 'SiteLinks',
    favourites = 'Favourites',
    myAccount = 'MyAccount',
    currencySelector = 'CurrencySelector',
    phoneNumber = 'PhoneNumber',
    numberOfTravellers = 'NumberOfTravellers',
    roomSelect = 'RoomSelect',
    travellerDetails = 'TravellerDetails',
    checkoutButtons = 'CheckoutButtons',
    sidebar = 'Sidebar',
    whatHappensNext = 'WhatHappensNext',
    deals = 'Deals',
    banner = 'Banner',
    results = 'Results',
    region = 'Region',
    destination = 'Destination',
    contentInfo = 'ContentInfo',
    enquiryCTA = 'EnquiryCTA',
    activities = 'Activities',
    links = 'Links',
    paymentPlanOptions = 'PaymentPlanOptions',
    passenger = 'Passenger',
    duration = 'Duration',
    date = 'Date',
    contactDetails = 'ContactDetails',
    activity = 'Activity',
    budget = 'Budget',
    departureDate = 'DepartureDate',
    adults = 'Adults',
    children = 'Children',
    comments = 'Comments',
    addFlights = 'AddFlights',
    paxDetails = 'PaxDetails',
    callBackTime = 'CallBackTime',
    videoAppointment = 'VideoAppointment',
    sendEnquiry = 'SendEnquiry',
    flightInclusive = 'FlightInclusive',
    alternativeFlights = 'AlternativeFlights',
    highlightedTours = 'HighlightedTours',
    checkoutSideBar = 'CheckoutSideBar',
    siteWide = 'SiteWide',
}

const AreaMinifiedMap: { [key in Area]: string } = {
    [Area.searchWidget]: 'SW',
    [Area.feefoButton]: 'FEEFOB',
    [Area.recentlyViewedAdventures]: 'RVA',
    [Area.landingPages]: 'LP',
    [Area.meetOurPeople]: 'MOP',
    [Area.buildYourAdventure]: 'BYA',
    [Area.FAQs]: 'FAQ',
    [Area.stillHaveQuestions]: 'SHQ',
    [Area.blogPosts]: 'BP',
    [Area.dealSignup]: 'DS',
    [Area.filters]: 'F',
    [Area.sort]: 'S',
    [Area.resultCard]: 'RC',
    [Area.searchPageCTA]: 'SPC',
    [Area.resultContainer]: 'RC',
    [Area.tourHeader]: 'TH',
    [Area.stickyHeader]: 'SH',
    [Area.photoGallery]: 'PG',
    [Area.whoIsThisFor]: 'WITF',
    [Area.brochureLine]: 'BL',
    [Area.contactCTA]: 'CC',
    [Area.mapPopup]: 'MP',
    [Area.USPs]: 'USPS',
    [Area.itinerary]: 'I',
    [Area.map]: 'M',
    [Area.brochureCTA]: 'BC',
    [Area.PandA]: 'PA',
    [Area.reviews]: 'R',
    [Area.essentialInformation]: 'EI',
    [Area.questionCTA]: 'QC',
    [Area.similarAdventures]: 'SA',
    [Area.navigation]: 'NAV',
    [Area.homeLink]: 'HL',
    [Area.searchBox]: 'SB',
    [Area.siteLinks]: 'SL',
    [Area.favourites]: 'FV',
    [Area.myAccount]: 'MA',
    [Area.currencySelector]: 'CS',
    [Area.phoneNumber]: 'PN',
    [Area.numberOfTravellers]: 'NT',
    [Area.roomSelect]: 'RS',
    [Area.travellerDetails]: 'TD',
    [Area.checkoutButtons]: 'CB',
    [Area.sidebar]: 'SB',
    [Area.whatHappensNext]: 'WHN',
    [Area.deals]: 'D',
    [Area.banner]: 'B',
    [Area.results]: 'R',
    [Area.region]: 'Reg',
    [Area.destination]: 'D',
    [Area.contentInfo]: 'CI',
    [Area.enquiryCTA]: 'EC',
    [Area.activities]: 'A',
    [Area.links]: 'L',
    [Area.paymentPlanOptions]: 'PO',
    [Area.passenger]: 'PAX',
    [Area.duration]: 'D',
    [Area.date]: 'D',
    [Area.contactDetails]: 'CD',
    [Area.activity]: 'ACT',
    [Area.budget]: 'BUD',
    [Area.departureDate]: 'DD',
    [Area.adults]: 'AD',
    [Area.children]: 'CHI',
    [Area.comments]: 'COM',
    [Area.addFlights]: 'AF',
    [Area.paxDetails]: 'PD',
    [Area.callBackTime]: 'CBT',
    [Area.videoAppointment]: 'VA',
    [Area.sendEnquiry]: 'SE',
    [Area.flightInclusive]: 'FI',
    [Area.alternativeFlights]: 'AF',
    [Area.highlightedTours]: 'HT',
    [Area.siteWide]: 'SW',
    [Area.checkoutSideBar]: 'CSB',
};

export { Area, AreaMinifiedMap };
