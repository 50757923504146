export function isValidEmail(email: string | null | undefined) {
    if (email === null || email === undefined) {
        return false;
    }
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export function isValidPhoneNumber(phoneNumber: string | null | undefined) {
    if (phoneNumber === null || phoneNumber === undefined) {
        return false;
    }
    return (
        /^[0-9.+\-\s()]{6,}$/i.test(phoneNumber) &&
        !/^([0-9.+\-\s()])\1*$/.test(phoneNumber)
    );
}

const validation = {
    isValidEmail,
    isValidPhoneNumber,
};

export default validation;
