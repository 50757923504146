import { get } from './enduranceApi';
import { Feature, FeaturesResult } from '@/endurance/featureFlags';
import { FeatureFlag } from '../contexts/FeatureFlagContext';

const featureFlagUrl = `/featureFlags/featureFlag`;

export function getFeatureFlags(
    sessionId: string,
    accessToken: string | undefined = '',
    isBot: boolean
) {
    const url = `${featureFlagUrl}/all?&sessionId=${sessionId}&cognitoToken=${accessToken}&isBot=${isBot}`;

    return get<FeaturesResult>(url);
}

export function getSpecificFeatures(
    features: FeatureFlag[],
    sessionId: string,
    accessToken: string | undefined = '',
    isBot: boolean
) {
    const url = `${featureFlagUrl}/specific?&sessionId=${sessionId}&cognitoToken=${accessToken}&isBot=${isBot}&features=${features.join(
        ','
    )}`;

    return get<Feature[]>(url);
}

export const getFeatureFlagEnabled = (
    sessionId: string,
    featureFlag: string,
    project: string,
    accessToken: string | undefined = ''
) => {
    const url = `${featureFlagUrl}?featureFlag=${featureFlag}&project=${project}&sessionId=${sessionId}&cognitoToken=${accessToken}`;

    return get<boolean>(url).then(result => ({
        success: result.isSuccess,
        errors: result.errors,
        enabled: result.result,
    }));
};

const featureFlagApi = {
    getFeatureFlags,
    getFeatureFlagEnabled,
};

export default featureFlagApi;
