import { Action } from '@/redux/actions/Action';
import {
    RequestTourAction,
    RequestTourFailureAction,
    RequestTourSuccessAction,
    RequestTourRoomDataAction,
    RequestTourRoomDataFailureAction,
    RequestTourRoomDataSuccessAction,
} from '@/redux/actions/tour';
import { TourState } from '@/redux/TourState';
import * as TYPES from '../actions/types';

const initialState: TourState = {
    loading: {},
    tours: {},
    roomData: {},
    errors: {},
};

const requestTour = (
    state: TourState,
    action: RequestTourAction
): TourState => ({
    ...state,
    errors: {},
    loading: { ...state.loading, [action.tourId]: true },
});

const requestTourSuccess = (
    state: TourState,
    action: RequestTourSuccessAction
): TourState => ({
    ...state,
    loading: { ...state.loading, [action.tour.tourId]: false },
    tours: { ...state.tours, [action.tour.tourId]: action.tour },
    errors: { ...state.errors, [action.tour.tourId]: undefined },
});

const requestTourFailure = (
    state: TourState,
    action: RequestTourFailureAction
): TourState => ({
    ...state,
    loading: { ...state.loading, [action.tourId]: false },
    tours: { ...state.tours, [action.tourId]: undefined },
    errors: { ...state.errors, [action.tourId]: action.errors },
});

const requestTourRoomData = (
    state: TourState,
    action: RequestTourRoomDataAction
): TourState => {
    return {
        ...state,
        roomData: {
            ...state.roomData,
            [`${action.departureId}-${action.currencyCode}`]: {
                options: null,
                loading: true,
                error: null,
            },
        },
    };
};

const requestTourRoomDataSuccess = (
    state: TourState,
    action: RequestTourRoomDataSuccessAction
): TourState => {
    return {
        ...state,
        roomData: {
            ...state.roomData,
            [`${action.departureId}-${action.currencyCode}`]: {
                options: action.data,
                loading: false,
                error: null,
            },
        },
    };
};

const requestTourRoomDataFailure = (
    state: TourState,
    action: RequestTourRoomDataFailureAction
): TourState => {
    return {
        ...state,
        roomData: {
            ...state.roomData,
            [`${action.departureId}-${action.currencyCode}`]: {
                options: null,
                loading: false,
                error: action.error,
            },
        },
    };
};

const tour = (state: TourState = initialState, action: Action): TourState => {
    const handlers: {
        [key: string]: (s: TourState, action?: Action) => TourState;
    } = {
        [TYPES.REQUEST_TOUR]: requestTour,
        [TYPES.REQUEST_TOUR_SUCCESS]: requestTourSuccess,
        [TYPES.REQUEST_TOUR_FAILURE]: requestTourFailure,
        [TYPES.TOUR_REQUEST_TOUR_ROOM_DATA]: requestTourRoomData,
        [TYPES.TOUR_REQUEST_TOUR_ROOM_DATA_SUCCESS]: requestTourRoomDataSuccess,
        [TYPES.TOUR_REQUEST_TOUR_ROOM_DATA_FAILURE]: requestTourRoomDataFailure,
    };

    const handler: (site: TourState, action?: Action) => TourState =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default tour;
