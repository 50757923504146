import {
    GetBookingsResponse,
    PassengerDetails,
    UserDetails,
    AccountHoldSpacesResponse,
    AgentDetails,
    AccountFavouritesResponse,
    WhoIsGoingRequest,
    GetBookingResponse,
} from '@/endurance/accounts';
import { tapApiAuthKey, tapApiBaseUrl } from '../utils/config';
import { ApiResponse, BasicApiResponse } from '@/endurance';

export function getBookings(accessToken: string): Promise<GetBookingsResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/bookings`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getBooking(
    accessToken: string,
    bookingReference: string
): Promise<GetBookingResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/bookings/${bookingReference}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getUserDetails(
    accessToken: string
): Promise<BasicApiResponse<UserDetails>> {
    return fetch(`${tapApiBaseUrl}/accounts/details`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getPassengerDetails(
    accessToken: string
): Promise<BasicApiResponse<PassengerDetails>> {
    return fetch(`${tapApiBaseUrl}/accounts/passengerDetails`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getHoldspaces(
    accessToken: string
): Promise<AccountHoldSpacesResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/holdspaces`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getAgentDetails(
    accessToken: string
): Promise<BasicApiResponse<AgentDetails>> {
    return fetch(`${tapApiBaseUrl}/accounts/agentDetails`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getDocument(
    accessToken: string,
    documentName: string,
    bookingReference: string
): Promise<BasicApiResponse<File>> {
    return fetch(
        `${tapApiBaseUrl}/accounts/documents/${bookingReference}?documentName=${documentName}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-AuthKey': tapApiAuthKey,
                'X-AccessToken': accessToken,
                'Content-Disposition': documentName,
            },
            cache: 'no-cache',
        }
    ).then(response => response.json());
}

export function getFavourites(
    accessToken: string
): Promise<AccountFavouritesResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/favourites`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getWhoIsGoings(
    accessToken: string
): Promise<BasicApiResponse<WhoIsGoingRequest[]>> {
    return fetch(`${tapApiBaseUrl}/accounts/whoIsGoings`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function addFavourites(
    accessToken: string,
    tourIds: string[]
): Promise<ApiResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/favourites?tourIds=${tourIds}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function unFavourite(
    accessToken: string,
    tourId: string
): Promise<ApiResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/favourites?tourId=${tourId}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function requestCancellation(
    accessToken: string,
    bookingReference: string
): Promise<ApiResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/requestcancellation`, {
        body: JSON.stringify({
            bookingReference,
        }),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function requestBookingAmendment(
    accessToken: string,
    bookingReference: string,
    amendmentRequest: string
): Promise<ApiResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/requestbookingamendment`, {
        body: JSON.stringify({
            amendmentRequest,
            bookingReference,
        }),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function requestVisas(
    accessToken: string,
    bookingReference: string
): Promise<ApiResponse> {
    return fetch(`${tapApiBaseUrl}/accounts/requestVisas`, {
        body: JSON.stringify({
            bookingReference,
        }),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

const accountApi = {
    getBooking,
    getBookings,
    getHoldspaces,
    getUserDetails,
    getPassengerDetails,
    getAgentDetails,
    getDocument,
    getFavourites,
    getWhoIsGoings,
    addFavourites,
    unFavourite,
    requestBookingAmendment,
    requestCancellation,
    requestVisas,
};

export default accountApi;
