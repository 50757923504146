import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './RedWireButton.module.scss';

const RedWireButton = ({ className, ...props }) => {
    return (
        <Button
            className={classNames(styles.redWireButton, className)}
            {...props}
        />
    );
};

RedWireButton.propTypes = {
    ...Button.propTypes,
};

export default RedWireButton;
