import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as AbtaLogoColoured } from '../../../../assets/images/abta.svg';
import { ReactComponent as AbtaLogoPlain } from '../../../../assets/images/abta-logo-with-tag-black.svg';

const logo = {
    coloured: AbtaLogoColoured,
    plain: AbtaLogoPlain,
};

const AbtaBadgeWithLabel = ({ theme = 'plain', classNames, ...props }) => {
    const T = logo[theme];

    return (
        <Link to="/abta-member" {...props}>
            <T alt="ABTA member No. P8142" />
        </Link>
    );
};

AbtaBadgeWithLabel.propTypes = {
    theme: PropTypes.oneOf(['coloured', 'plain']),
};

export default AbtaBadgeWithLabel;
