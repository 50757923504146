import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Button.module.scss';

const Button = ({
    as: T = 'button',
    children,
    className,
    disabled = false,
    ...rest
}) => {
    return (
        <T
            className={classNames(styles.button, className)}
            disabled={disabled}
            {...rest}
        >
            {children}
        </T>
    );
};

Button.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

export default Button;
