export const compareVersions = (versionA, versionB) => {
    let result = 0;
    if (versionA !== versionB) {
        const a = versionA.split('.').map(s => parseInt(s));
        const b = versionB.split('.').map(s => parseInt(s));

        if (
            a[0] < b[0] ||
            (a[0] === b[0] && a[1] < b[1]) ||
            (a[0] === b[0] && a[1] === b[1] && a[2] < b[2])
        ) {
            result = -1;
        } else {
            result = 1;
        }
    }

    return result;
};

const versionService = { compareVersions };

export default versionService;
