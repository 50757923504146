import { useEffect } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { requestAirportData } from '../redux/actions';

export function useFlights() {
    const dispatch = useAppDispatch();
    const status = useAppSelector(state => state.flights.status);
    const airports = useAppSelector(state => state.flights.airports);
    const airportGroups = useAppSelector(state => state.flights.airportGroups);

    useEffect(() => {
        if (status === 'initial') {
            dispatch(requestAirportData());
        }
    }, [dispatch, status]);

    return {
        airports,
        airportGroups,
        isLoading: status === 'loading' || status === 'initial',
    };
}
