import { buildConfirmPriceQuery } from '../services/accommodationPricingService';
import { tapApiBaseUrl, tapApiAuthKey } from '../utils/config';

const accommodationsUrl = `${tapApiBaseUrl}/accommodations`;
const confirmPriceUrl = `${accommodationsUrl}/pricing/confirmPrice`;

export function getAccommodation(countryIsoCode2, accommodationSlug) {
    return fetch(
        `${accommodationsUrl}/${countryIsoCode2.toLowerCase()}/${encodeURI(
            accommodationSlug.toLowerCase()
        )}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-AuthKey': tapApiAuthKey,
            },
        }
    ).then(response =>
        response.json().then(result => ({
            success: result.success,
            error: result.errors,
            accommodation: result.result,
        }))
    );
}

export function confirmPrice(params) {
    const query = buildConfirmPriceQuery(params);

    return fetch(`${confirmPriceUrl}?${query}`, {
        headers: {
            'X-AuthKey': tapApiAuthKey,
        },
    }).then(response => response.json());
}

const accommodationApi = {
    getAccommodation,
    confirmPrice,
};

export default accommodationApi;
