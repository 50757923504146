import { call, put, select } from 'redux-saga/effects';
import searchApi from '../../apis/accommodationSearchApi';
import { logError, logErrorWithInfo } from '../../utils/sentry';
import {
    searchAccommodationSuccess,
    searchAccommodationFailure,
    incrementAccommodationPageSuccess,
    incrementAccommodationPageFailure,
} from '../actions/accommodationSearch.actions';
import {
    selectPage,
    selectPageSize,
    selectRequest,
    selectSessionId,
} from '../reducers/accommodationSearch.reducer';
import { getQueryFromRequest } from '../../services/accommodationSearchService';

export const getState = state => state.accommodationSearch;
export const getSelectedCurrency = state => {
    return state.site.selectedCurrency ? state.site.selectedCurrency : 'GBP';
};

export function populateAccommodationSearchParamsSaga(action) {
    if (action.updateQueryString) {
        updateSearchQueryString(action.params);
    }
}

export function* performAccommodationSearchSaga() {
    try {
        const response = yield search();

        if (response.success) {
            yield put(searchAccommodationSuccess(response));
        } else {
            yield put(searchAccommodationFailure(response));
            logErrorWithInfo(
                Error('Failed to search accommodations'),
                response
            );
        }
    } catch (e) {
        logError(e);
        yield put(searchAccommodationFailure({ errors: ['Unknown Error'] }));
    }
}

function* search(sessionId) {
    const searchState = yield select(getState);
    const selectedCurrency = yield select(getSelectedCurrency);

    return yield call(searchApi.searchAccommodation, {
        ...searchState.searchParams,
        currencyCode: selectedCurrency,
        page: searchState.page,
        pageSize: searchState.pageSize,
        sortType: searchState.sortType,
        sessionId,
    });
}

export function* incrementAccommodationSearchPageSaga() {
    const request = yield select(selectRequest);
    const sessionId = yield select(selectSessionId);
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);

    const apiSearchParams = {
        ...request,
        sessionId,
        page,
        pageSize,
    };

    try {
        const result = yield call(
            searchApi.searchAccommodation,
            apiSearchParams
        );
        if (result.success) {
            yield put(incrementAccommodationPageSuccess(result));
        } else {
            yield put(incrementAccommodationPageFailure(result));
            logErrorWithInfo(Error('Error retrieving search results'), result);
        }
    } catch (e) {
        logError(e);
        yield put(searchAccommodationFailure({ errors: ['Unknown Error'] }));
    }
}

export function* filterAccommodationSearchSaga(action) {
    const sessionId = yield select(selectSessionId);
    const response = yield search(sessionId);
    if (response.success) {
        yield put(searchAccommodationSuccess(response));
    } else {
        yield put(searchAccommodationFailure(response));
        logErrorWithInfo(Error('Failed to search accommodations'), response);
    }
}

export function* sortAccommodationSearchSaga() {
    const sessionId = yield select(selectSessionId);
    const response = yield search(sessionId);
    if (response.success) {
        yield put(searchAccommodationSuccess(response));
    } else {
        yield put(searchAccommodationFailure(response));
        logErrorWithInfo(Error('Failed to search accommodations'), response);
    }
}

export function* resetAccommodationFiltersSaga() {
    const sessionId = yield select(selectSessionId);
    const response = yield search(sessionId);
    if (response.success) {
        yield put(searchAccommodationSuccess(response));
    } else {
        yield put(searchAccommodationFailure(response));
        logErrorWithInfo(Error('Failed to search accommodations'), response);
    }
}

function updateSearchQueryString(searchParams) {
    window.history.pushState(
        null,
        '',
        `search?${getQueryFromRequest(searchParams)}`
    );
}
