import {
    TourSearchAggregations,
    TourSearchParams,
    SortType,
} from '@/core/tourSearch';
import { SearchedTour } from '@/endurance/tourSearch';

import {
    useState,
    useContext,
    createContext,
    ReactNode,
    useEffect,
    useCallback,
} from 'react';
import { getSearchParamsFromQuery } from '../services/tourSearchService';
import { useLocation } from 'react-router-dom';
import {
    incrementPage,
    performSearch,
    populateSearchParams,
    resetSearch,
    resetFilters,
    filterTourSearch,
} from '../redux/actions';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { useSession } from './SessionContext';

export interface TourSearchResultsContextValue {
    hasInitialSearchComplete: boolean;
    isSearching: boolean;
    isPaging: boolean;
    isFilteringOrSorting: boolean;
    searchParams: TourSearchParams;
    results: SearchedTour[];
    totalResults: number;
    filterCount: number;
    sortType: SortType;
    aggregations: TourSearchAggregations | null;
    originalAggregations: TourSearchAggregations | null;
    onLoadMoreResults: (pageNumber: number) => void;
    onFilterResults: (newSearchParams: TourSearchParams) => void;
    onResetFilters: () => void;
    populateSearchParams: (newSearchParams: TourSearchParams) => void;
}

export interface TourSearchResultsContextProps {
    children: ReactNode;
}

const TourSearchResultsContext =
    createContext<TourSearchResultsContextValue | null>(null);

export function useTourSearchResults() {
    const context = useContext(TourSearchResultsContext);
    if (!context) {
        throw new Error('No Tour Search Results Context initialised.');
    }
    return context;
}

export function TourSearchResultsProvider({
    children,
}: TourSearchResultsContextProps) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { sessionId } = useSession();

    const [hasInitialSearchStarted, setHasInitialSearchStarted] =
        useState<boolean>(false);
    const [hasInitialSearchComplete, setHasInitialSearchComplete] =
        useState<boolean>(false);
    const [filterCount, setFilterCount] = useState<number>(0);
    const {
        isSearching,
        results,
        totalResults,
        isPaging,
        isFilteringOrSorting,
        aggregations,
        originalAggregations,
        searchParams,
    } = useAppSelector(state => state.tourSearch);

    const handleSearch = useCallback(
        (searchParams: TourSearchParams) => {
            setHasInitialSearchStarted(true);
            dispatch(resetSearch(sessionId));
            dispatch(populateSearchParams(searchParams));
            dispatch(performSearch(sessionId));
        },
        [dispatch, sessionId]
    );

    // On main search criteria change treat as new search page landing
    useEffect(() => {
        const currentParams = getSearchParamsFromQuery(location.search);

        const hasSearchChanged =
            searchParams != null &&
            (currentParams.text !== searchParams.text ||
                currentParams.isAnywhere !== searchParams.isAnywhere ||
                currentParams.yearMonth !== searchParams.yearMonth ||
                currentParams.startDate !== searchParams.startDate ||
                currentParams.startDateFlexibility !==
                    searchParams.startDateFlexibility ||
                (currentParams.pageNumber != null
                    ? currentParams.pageNumber
                    : 1) <
                    (searchParams.pageNumber !== null
                        ? searchParams?.pageNumber
                        : 1));

        if (hasSearchChanged && hasInitialSearchComplete) {
            setHasInitialSearchStarted(false);
            setHasInitialSearchComplete(false);
        }
    }, [location.search, searchParams, hasInitialSearchComplete]);

    // Trigger search when search not started
    useEffect(() => {
        const params = getSearchParamsFromQuery(location.search);

        if (!hasInitialSearchStarted && !isSearching) {
            handleSearch(params);
        }
    }, [handleSearch, location.search, isSearching, hasInitialSearchStarted]);

    // Finish Search
    useEffect(() => {
        if (
            hasInitialSearchStarted &&
            !hasInitialSearchComplete &&
            !isSearching
        ) {
            setHasInitialSearchComplete(true);
        }
    }, [hasInitialSearchStarted, hasInitialSearchComplete, isSearching]);

    useEffect(() => {
        if (searchParams != null) {
            setFilterCount(
                searchParams.attributes.length +
                    searchParams.countries.length +
                    searchParams.regions.length +
                    searchParams.physicalRatings.length +
                    (searchParams.maxAge !== null ||
                    searchParams.minAge !== null ||
                    searchParams.familyOnly
                        ? 1
                        : 0) +
                    (searchParams.minPrice ? 1 : 0) +
                    (searchParams.maxPrice ? 1 : 0) +
                    (searchParams.minDuration ? 1 : 0) +
                    (searchParams.maxDuration ? 1 : 0)
            );
        }
    }, [searchParams]);

    return (
        <TourSearchResultsContext.Provider
            value={{
                hasInitialSearchComplete,
                isSearching: isSearching,
                isPaging,
                isFilteringOrSorting,
                searchParams:
                    searchParams ?? getSearchParamsFromQuery(location.search),
                results: results,
                totalResults,
                sortType: searchParams.sortType ?? 'Recommended',
                filterCount,
                aggregations,
                originalAggregations,
                onLoadMoreResults: handleLoadMore,
                onResetFilters: handleResetFilters,
                onFilterResults: handleFilterResults,
                populateSearchParams: handlePopulateSearchParams,
            }}
        >
            {children}
        </TourSearchResultsContext.Provider>
    );

    function handleLoadMore(pageNumber: number) {
        dispatch(incrementPage(sessionId, pageNumber));
    }

    function handleResetFilters() {
        dispatch(resetFilters(sessionId));
    }

    function handleFilterResults(newParams: TourSearchParams) {
        dispatch(filterTourSearch(newParams, sessionId));
    }

    function handlePopulateSearchParams(newParams: TourSearchParams) {
        dispatch(populateSearchParams(newParams));
    }
}
