import * as Cookies from 'js-cookie';

const experimentCookieName = 'experimentUUID';

export function setupExperimentUUID(uuid: string) {
    if (!getExperimentUUID()) {
        Cookies.set(experimentCookieName, uuid, { expires: 365, secure: true });
    }
}

export function getExperimentUUID(): string | undefined {
    return Cookies.get(experimentCookieName);
}

const experimentTrackingService = {
    setupExperimentUUID,
    getExperimentUUID,
};

export default experimentTrackingService;
