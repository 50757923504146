import ConversionCategory from '../enums/ConversionCategory';
import { logErrorWithInfo } from '../../sentry';
import ConversionType from '../enums/ConversionType';
import IAnalyticsProvider from '../interfaces/IAnalyticsProvider';
import mixpanel from 'mixpanel-browser';
import { mixpanelToken } from '../../../utils/config';
import {
    Page,
    Area,
    InteractionItem,
    Action,
} from '../enums/EventInteractionEnums';
import EventData from '../dataModels/EventData';
import { getTrackingCodes } from '../../tracking/trackingService';

export default class mixpanelAnalytics implements IAnalyticsProvider {
    sendProviderAdsEvent(
        _category: ConversionCategory,
        _action: string,
        _label: string | null,
        _value: number | null
    ): void {
        //Don't send old event types to mixpanel
    }

    sendEventV2(
        page: Page,
        area: Area,
        interactionItem: InteractionItem | string,
        action: Action,
        data: EventData | null
    ): void {
        try {
            this.initializeMixpanel();
            mixpanel.track(`${page}_${area}_${interactionItem}_${action}`, {
                ...data,
            });
        } catch (error) {
            logErrorWithInfo('Failed to log event with mixpanel', {
                error,
                page,
                area,
                interactionItem,
                action,
                ...data,
            });
        }
    }

    sendProviderPageView(
        _label: string,
        pageName?: string,
        data?: EventData | null
    ): void {
        try {
            this.initializeMixpanel();
            mixpanel.track(`${pageName}_View`, {
                ...data,
            });
        } catch (error) {
            logErrorWithInfo('Failed to log page view with mixpanel', {
                error,
                pageName,
                ...data,
            });
        }
    }

    sendProviderAdsConversion(
        type: ConversionType,
        id: string,
        value: number | null,
        currency: string | null
    ): void {
        var trackingCode = getTrackingCodes()[0];

        try {
            this.initializeMixpanel();
            mixpanel.track(type, {
                id: id,
                value: value,
                currency: currency,
                adTrackingSource: trackingCode?.provider ?? undefined,
                adTrackingId: trackingCode?.code ?? undefined,
            });
        } catch (error) {
            logErrorWithInfo('Failed to log page view with mixpanel', {
                error,
                type,
            });
        }
    }

    loginUser(email: string): void {
        try {
            this.initializeMixpanel();
            mixpanel.identify(email);
            mixpanel.people.set({
                $email: email,
            });
            mixpanel.people.set_once({
                $first_login: new Date().toISOString(),
            });
            mixpanel.people.increment({
                'Login Count': 1,
            });
        } catch (error) {
            logErrorWithInfo('Failed to identify user with mixpanel', {
                error,
                email,
            });
        }
    }

    logoutUser(): void {
        try {
            mixpanel.reset();
        } catch (error) {
            logErrorWithInfo(
                'Failed to log logout with mixpanel, likely mixpanel was not initialised',
                {
                    error,
                }
            );
        }
    }

    initializeMixpanel() {
        mixpanel.init(mixpanelToken, {
            persistence: 'localStorage',
        });
    }
}
