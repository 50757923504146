import encodeUrl from 'encodeurl';
import queryString from 'qs';

const withSearchQuery = (url, searchParams) => {
    const searchQueryString = searchParams
        ? queryString.stringify(searchParams, {
              addQueryPrefix: true,
              arrayFormat: 'comma',
          })
        : '';

    return url + searchQueryString;
};

const getTourUrl = (tourName, tourId, queryParams = {}) => {
    const sanitizedTourName = sanitizeText(tourName);
    const queryParamString = Object.entries(queryParams)
        .filter(([key, value]) => value !== '' && value !== undefined)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

    return encodeUrl(
        `/tour/${sanitizedTourName}/${tourId}${
            queryParamString ? '?' + queryParamString : ''
        }`
    );
};
const getAccommodationUrl = (countryIsoCode2, slug, params) => {
    return withSearchQuery(
        encodeUrl(`/accommodation/${countryIsoCode2}/${slug}`.toLowerCase()),
        params
    );
};
const getPackagesUrl = (countryIsoCode2, slug, params) => {
    return withSearchQuery(
        encodeUrl(`/packages/${countryIsoCode2}/${slug}`.toLowerCase()),
        params
    );
};

const sanitizeText = text => {
    // Replaces whitespace with a single space character
    let result = text.replace(/\s+/g, ' ');

    // Remove single quotation mark and slash
    result = result.replace("'", '').replace('/', '-');

    // Remove diacritics
    if (result.normalize) {
        result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    // Replace invalid characters with spaces
    result = result.replace(/[^A-Za-z0-9.]+/g, ' ').trim(' ');

    // Remove prepositions
    let words = result.toLowerCase().split(' ');
    let prepositions = [
        'a',
        'an',
        'and',
        'any',
        'at',
        'from',
        'into',
        'of',
        'on',
        'or',
        'some',
        'the',
        'to',
        'by',
        'it',
        'if',
        'is',
        'in',
        '&',
        'with',
    ];
    result = words.filter(word => {
        return prepositions.indexOf(word) < 0;
    });

    // Rebuild string with dashes
    result = result.join('-');

    // Condense any instances of two or more dashes with a single dash
    return result.replace(/-{2,}/, '-').trim('-');
};

const getCheckoutFlightSelectUrl = (
    tourId,
    tourDepartureId,
    searchParams = {}
) => {
    const { adults, departureAirportCode, departureAirportGroupCode } =
        searchParams;

    return withSearchQuery(
        `/checkout/flights/${encodeUrl(tourId)}/${tourDepartureId}`,
        {
            ...(adults && { adults: adults }),
            ...(departureAirportCode && { departureAirportCode }),
            ...(departureAirportGroupCode && { departureAirportGroupCode }),
        }
    );
};

const getCheckoutPackagesSelectUrl = (
    adults,
    departureAirportCode,
    departureAirportGroupCode,
    arrivalDate,
    returnDate,
    currencyCode,
    departureId,
    tourId,
    departureRoomId,
    flightToken,
    flightSupplierId,
    isCachedFlight
) => {
    return withSearchQuery(`/tour/packages`, {
        ...(adults && { adults: adults }),
        ...(departureAirportCode && { departureAirportCode }),
        ...(departureAirportGroupCode && { departureAirportGroupCode }),
        ...(arrivalDate && { arrivalDate }),
        ...(returnDate && { returnDate }),
        ...(currencyCode && { currencyCode }),
        ...(departureId && { departureId }),
        ...(tourId && { tourId }),
        ...(departureRoomId && { departureRoomId }),
        ...(flightToken && { flightToken }),
        ...(flightSupplierId && { flightSupplierId }),
        ...(isCachedFlight && { isCachedFlight }),
    });
};

const getCheckoutCustomerDetailsUrl = (tourId, tourDepartureId) =>
    `/checkout/traveller-details/${encodeUrl(tourId)}/${tourDepartureId}`;

const getCheckoutPaymentUrl = (tourId, tourDepartureId) =>
    `/checkout/payment/${encodeUrl(tourId)}/${tourDepartureId}`;

const getCheckoutBookingConfirmationUrl = (tourId, tourDepartureId) =>
    `/checkout/confirmation/${encodeUrl(tourId)}/${tourDepartureId}`;

const getCheckoutAccomOnlyCustomerDetailsUrl = () =>
    '/checkout/accom-only/traveller-details';
const getCheckoutAccomOnlyPaymentUrl = () => '/checkout/accom-only/payment';
const getCheckoutAccomOnlyConfirmationUrl = () =>
    '/checkout/accom-only/confirmation';

const getHoldSpaceUrl = (tourId, tourDepartureId) =>
    `/holdspace/${encodeUrl(tourId)}/${tourDepartureId}`;

const getHoldSpaceConfirmationUrl = (
    tourId,
    tourDepartureId,
    searchParams = null
) =>
    withSearchQuery(
        `/holdspace/confirmation/${encodeUrl(tourId)}/${tourDepartureId}`,
        searchParams
    );

export const getCustomerPredepartureUrl = predepartureCode =>
    `/myaccount/pre-departure?predepartureCode=${encodeUrl(predepartureCode)}`;

const urlService = {
    getTourUrl,
    getCheckoutFlightSelectUrl,
    getCheckoutPackagesSelectUrl,
    getCheckoutCustomerDetailsUrl,
    getCheckoutPaymentUrl,
    getCheckoutBookingConfirmationUrl,
    getHoldSpaceUrl,
    getHoldSpaceConfirmationUrl,
    getAccommodationUrl,
    getCheckoutAccomOnlyCustomerDetailsUrl,
    getCheckoutAccomOnlyPaymentUrl,
    getCheckoutAccomOnlyConfirmationUrl,
    getPackagesUrl,
    getCustomerPredepartureUrl,
};

export default urlService;
