import { FlightsState } from '@/redux/FlightsState';
import * as TYPES from '../actions/types';
import {
    RequestAirportDataFailureAction,
    RequestAirportDataSuccessAction,
} from '@/redux/actions/flight';
import { Action } from '@/redux/actions/Action';

export const initialState: FlightsState = {
    airports: [],
    airportGroups: [],
    status: 'initial',
    errors: [],
};

const requestAirports = (state: FlightsState): FlightsState => {
    return {
        ...state,
        status: 'loading',
        errors: initialState.errors,
    };
};

const requestAirportsSuccess = (
    state: FlightsState,
    action: RequestAirportDataSuccessAction
): FlightsState => {
    if (state.status !== 'loading') {
        return state;
    }

    return {
        ...state,
        airports: action.airports.map(airport => ({
            ...airport,
            type: 'Airport',
        })),
        airportGroups: action.airportGroups.map(airportGroup => ({
            ...airportGroup,
            type: 'AirportGroup',
        })),
        status: 'succeeded',
    };
};

const requestAirportsFailure = (
    state: FlightsState,
    action: RequestAirportDataFailureAction
): FlightsState => {
    if (state.status !== 'loading') {
        return state;
    }

    return { ...state, status: 'failed', errors: action.errors };
};

const results = (state: FlightsState = initialState, action: Action) => {
    const handlers: any = {
        [TYPES.FLIGHTS_AIRPORT_DATA_REQUEST]: requestAirports,
        [TYPES.FLIGHTS_AIRPORT_DATA_SUCCESS]: requestAirportsSuccess,
        [TYPES.FLIGHTS_AIRPORT_DATA_FAILURE]: requestAirportsFailure,
    };

    const handler: (site: FlightsState, action?: Action) => FlightsState =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default results;
