import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';
import Icon from '../../Icon/Icon';
import styles from './IconButton.module.scss';
import { availableIcons } from '../../Icon/iconHelper';

const IconButton = ({
    children,
    icon,
    iconColor = '406365',
    iconHeight = 20,
    iconWidth = 20,
    className,
    ...props
}) => {
    return (
        <Button className={classNames(styles.iconButton, className)} {...props}>
            {children}{' '}
            <Icon
                icon={icon}
                color={iconColor}
                height={iconHeight}
                width={iconWidth}
            />
        </Button>
    );
};

IconButton.propTypes = {
    icon: PropTypes.oneOf(availableIcons),
    iconColor: PropTypes.string,
    iconHeight: PropTypes.number,
    iconWidth: PropTypes.number,
    ...Button.propTypes,
};

export default IconButton;
