import React from 'react';

const TapEmailAddress = props => {
    return (
        <a href="mailto:hello@theadventurepeople.com" {...props}>
            hello@theadventurepeople.com
        </a>
    );
};

export default TapEmailAddress;
