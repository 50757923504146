import * as classNames from 'classnames';
import feefoLogoDark from './../../../../assets/images/feefoReviewDark.svg';
import feefoLogoLight from './../../../../assets/images/feefoReview.svg';
import styles from './FeefoBadge.module.scss';
import { Page } from '../../../../utils/analytics/enums/Page';
import { Area } from '../../../../utils/analytics/enums/Area';
import { InteractionItem } from '../../../../utils/analytics/enums/InteractionItem';
import { Action } from '../../../../utils/analytics/enums/Action';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';

export interface FeefoBadgeProps {
    theme: keyof typeof logo;
    showBlurb?: boolean;
    className?: string;
    page: Page;
}

const logo = {
    dark: feefoLogoDark,
    light: feefoLogoLight,
};

const FeefoBadge = ({
    theme = 'dark',
    showBlurb = true,
    className,
    page,
    ...props
}: FeefoBadgeProps) => {
    var onClickHandler = () => {
        sendEventV2(
            page,
            Area.feefoButton,
            InteractionItem.feefoButton,
            Action.click,
            null
        );
    };

    return (
        <a
            className={classNames(
                styles.feefo,
                { [styles.light]: theme === 'light' },
                className
            )}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.feefo.com/en-US/reviews/the-adventure-people?displayFeedbackType=SERVICE&timeFrame=ALL"
            onClick={onClickHandler}
            {...props}
        >
            <img alt="Feefo Rating 4.9/5" src={logo[theme]} />
            {showBlurb && (
                <p>
                    The Adventure People has been rated excellent by its
                    customers
                </p>
            )}
        </a>
    );
};

export default FeefoBadge;
