import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './TransparentButton.module.scss';

const TransparentButton = ({ className, ...props }) => {
    return (
        <Button
            className={classNames(styles.transparentButton, className)}
            {...props}
        />
    );
};

TransparentButton.propTypes = {
    ...Button.propTypes,
};

export default TransparentButton;
