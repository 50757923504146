import { useContext, useState, createContext, ReactNode } from 'react';
import sessionService from '../services/sessionService';

export interface SessionContextValue {
    sessionId: string;
}

export interface SessionProviderProps {
    children: ReactNode;
}

const SessionContext = createContext<SessionContextValue | null>(null);

export function useSession() {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('No Session Context initialised.');
    }
    return context;
}

export function SessionProvider({ children }: SessionProviderProps) {
    const [sessionId] = useState(sessionService.startSession());

    return (
        <SessionContext.Provider
            value={{
                sessionId: sessionId,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
}

export default SessionContext;
