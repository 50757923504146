import { useEffect } from 'react';

export function useBodyClass(className: string, isActive: boolean) {
    useEffect(() => {
        const body = document.querySelector('body');
        if (body && isActive && !body.classList.contains(className)) {
            body.classList.add(className);
        } else if (body && !isActive && body.classList.contains(className)) {
            body.classList.remove(className);
        }

        return () => {
            const body = document.querySelector('body');
            if (body && body.classList.contains(className)) {
                body.classList.remove(className);
            }
        };
    }, [isActive, className]);
}
