import * as TYPES from '../actions/types';
import { Action } from '@/redux/actions/Action';
import { TourPackagesState } from '@/redux/TourPackagesState';
import {
    GetPackageFailureAction,
    GetPackageSuccessAction,
    SearchTourDeparturesFailureAction,
    SearchTourDeparturesSuccessAction,
} from '@/redux/actions/tourPackages';

const initialState: TourPackagesState = {
    loading: null,
    departures: [],
    package: null,
    error: null,
    flightInclusive: false,
    searchParams: {},
};

const searchTourDeparturesRequest = (
    state: TourPackagesState
): TourPackagesState => {
    return {
        ...state,
        loading: true,
    };
};

const searchTourDeparturesSuccess = (
    state: TourPackagesState,
    action: SearchTourDeparturesSuccessAction
): TourPackagesState => {
    const { departures } = action;
    return {
        ...state,
        loading: false,
        departures: departures,
    };
};

const searchTourDeparturesFailure = (
    state: TourPackagesState,
    action: SearchTourDeparturesFailureAction
): TourPackagesState => {
    const { error } = action;
    return {
        ...state,
        loading: false,
        error: { statusCode: error },
    };
};
const getPackageRequest = (state: TourPackagesState): TourPackagesState => {
    return {
        ...state,
        loading: true,
    };
};

const getPackageSuccess = (
    state = initialState,
    action: GetPackageSuccessAction
): TourPackagesState => {
    if (!(action && action.data)) {
        return {
            ...state,
            loading: false,
        };
    }

    const prices = Array.isArray(action.data.prices) ? action.data.prices : [];

    return {
        ...state,
        loading: false,
        package: {
            ...state.package,
            tour: { ...action.data.tour },
            flight: { ...action.data.flight } || null,
            prices: [...prices],
        },
    };
};

const getPackageFailure = (
    state: TourPackagesState,
    action: GetPackageFailureAction
): TourPackagesState => {
    const { error } = action;
    return {
        ...state,
        loading: false,
        error: { statusCode: error },
    };
};

const toggleTourFlightInclusiveSwitch = (
    state: TourPackagesState
): TourPackagesState => {
    const updatedFlightInclusive = !state.flightInclusive;
    return {
        ...state,
        flightInclusive: updatedFlightInclusive,
    };
};

const tourPackages = (
    state: TourPackagesState = initialState,
    action: Action
): TourPackagesState => {
    const handlers: {
        [key: string]: (
            s: TourPackagesState,
            action?: Action
        ) => TourPackagesState;
    } = {
        [TYPES.TOGGLE_TOUR_FLIGHT_INCLUSIVE_SWITCH]:
            toggleTourFlightInclusiveSwitch,
        [TYPES.TOUR_DEPARTURES_SEARCH_REQUEST]: searchTourDeparturesRequest,
        [TYPES.TOUR_DEPARTURES_SEARCH_SUCCESS]: searchTourDeparturesSuccess,
        [TYPES.TOUR_DEPARTURES_SEARCH_FAILURE]: searchTourDeparturesFailure,
        [TYPES.TOUR_PACKAGES_GET_REQUEST]: getPackageRequest,
        [TYPES.TOUR_PACKAGES_GET_SUCCESS]: getPackageSuccess,
        [TYPES.TOUR_PACKAGES_GET_FAILURE]: getPackageFailure,
    };

    const handler: (
        site: TourPackagesState,
        action?: Action
    ) => TourPackagesState = handlers[action.type];

    return handler ? handler(state, action) : state;
};
export default tourPackages;
