import { BasicApiResponse } from '@/endurance';
import { TourRoom } from '@/endurance/checkout';
import { Tour } from '@/endurance/tours';
import { RequestTourAction } from '@/redux/actions/tour';
import { RequestTourRoomDataAction } from '@/redux/actions/tour/RequestTourRoomDataAction';
import { call, put } from 'redux-saga/effects';
import { getTour, getTourRoomData } from '../../apis/tourApi.v2';
import { logError, logErrorWithInfo } from '../../utils/sentry';
import {
    requestTourSuccess,
    requestTourFailure,
    requestTourRoomDataSuccess,
    requestTourRoomDataFailure,
} from '../actions/tour.actions';

export function* requestTour(action: RequestTourAction) {
    try {
        const result: {
            success: boolean;
            errors: string[];
            tour: Tour | null;
        } = yield call(
            getTour,
            action.tourId,
            action.currencyCode,
            action.departureId,
            action.departureMonth
        );

        if (result.success && !!result.tour) {
            yield put(requestTourSuccess(result.tour));
        } else {
            yield put(requestTourFailure(action.tourId, result.errors));
            logErrorWithInfo(Error('Failed to fetch tour'), {
                tourId: action.tourId,
                result: result,
            });
        }
    } catch (e) {
        logError(e);
        yield put(requestTourFailure(action.tourId, [`${e}`]));
    }
}

export function* requestTourRoomDataSaga(action: RequestTourRoomDataAction) {
    const { departureId, currencyCode } = action;
    try {
        const roomDataResponse: BasicApiResponse<TourRoom[]> = yield call(
            getTourRoomData,
            departureId,
            currencyCode
        );

        if (roomDataResponse.success && roomDataResponse.result != null) {
            yield put(
                requestTourRoomDataSuccess(
                    departureId,
                    currencyCode,
                    roomDataResponse.result
                )
            );
        } else {
            yield put(
                requestTourRoomDataFailure(
                    departureId,
                    currencyCode,
                    roomDataResponse.statusCode.toString(),
                    roomDataResponse?.errors?.join(', ') ?? 'Unexpected error'
                )
            );
        }
    } catch (e) {
        yield put(
            requestTourRoomDataFailure(
                departureId,
                currencyCode,
                e,
                'Unexpected error'
            )
        );
    }
}
