import { call, put } from 'redux-saga/effects';
import { getAccommodation, confirmPrice } from '../../apis/accommodationApi';
import { logErrorWithInfo } from '../../utils/sentry';
import {
    requestAccommodationSuccess,
    requestAccommodationFailure,
    confirmPriceSuccess,
    confirmPriceFailure,
} from '../actions/accommodation.actions';

export function* requestAccommodation({ countryIsoCode2, slug }) {
    try {
        const result = yield call(getAccommodation, countryIsoCode2, slug);
        if (result.success) {
            yield put(requestAccommodationSuccess(result.accommodation));
        } else {
            yield put(
                requestAccommodationFailure(countryIsoCode2, slug, result.error)
            );
            logErrorWithInfo(Error('Failed to fetch accommodation'), {
                countryIsoCode2,
                slug,
                result: result,
            });
        }
    } catch (e) {
        logErrorWithInfo(e, { countryIsoCode2, slug });
        yield put(requestAccommodationFailure(countryIsoCode2, slug, [`${e}`]));
    }
}

export function* confirmAccommodationPrice({ request }) {
    try {
        const result = yield call(confirmPrice, request);
        if (result.success) {
            yield put(confirmPriceSuccess(result));
        } else {
            yield put(confirmPriceFailure());
            logErrorWithInfo(Error('Failed to confirm accommodation price'), {
                request,
                result,
            });
        }
    } catch (e) {
        logErrorWithInfo(e, request);
        yield put(confirmPriceFailure());
    }
}
