import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import mySaga from '../sagas';
import { loadState, saveState } from '../persistence/sessionStore';

const persistedState = loadState();

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const setupStore = (preloadedState: any) => {
    if (
        (preloadedState?.flights?.airports !== undefined &&
            preloadedState?.flights?.airports.constructor !== Array) ||
        (preloadedState?.flights?.airportGroups !== undefined &&
            preloadedState?.flights?.airportGroups.constructor !== Array)
    ) {
        delete preloadedState.flights;
    }

    const store = configureStore({
        reducer: rootReducer,
        preloadedState: preloadedState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(middleware),
    });

    sagaMiddleware.run(mySaga);

    store.subscribe(() => {
        saveState({
            checkout: store.getState().checkout,
            flights: store.getState().flights,
            accommodationRooms: store.getState().accommodationRooms,
        });
    });

    return store;
};

export const store = setupStore(persistedState);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
