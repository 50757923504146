import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckboxField.module.scss';
import classNames from 'classnames';

const CheckboxField = ({
    isChecked,
    isDisabled = false,
    isCount = null,
    label,
    id,
    name,
    value,
    onChange,
    size = 'normal',
    count = 0,
    className,
}) => {
    return (
        <div
            className={classNames(
                styles.wrapper,
                {
                    [styles.disabled]: isDisabled,
                    [styles.small]: size === 'small',
                },
                className
            )}
        >
            <input
                type="checkbox"
                id={id}
                name={name}
                value={value}
                checked={isChecked}
                onChange={onChange}
                disabled={isDisabled}
            />
            <label className={styles.checkbox} htmlFor={id} />
            <label htmlFor={id} className={styles.label}>
                {label}
                {isCount && <span>{count}</span>}
            </label>
        </div>
    );
};

CheckboxField.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default CheckboxField;
