import {
    LOOKUPS_ACTIVITIES_REQUEST,
    LOOKUPS_ACTIVITIES_SUCCESS,
    LOOKUPS_ACTIVITIES_FAILURE,
} from './types';

export const requestActivities = () => ({
    type: LOOKUPS_ACTIVITIES_REQUEST,
});

export const requestActivitiesSuccess = activities => ({
    type: LOOKUPS_ACTIVITIES_SUCCESS,
    activities,
});

export const requestActivitiesFailure = () => {
    return {
        type: LOOKUPS_ACTIVITIES_FAILURE,
    };
};
