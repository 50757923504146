import { TourRoom } from '@/endurance/checkout';
import { Tour } from '@/endurance/tours';
import {
    RequestTourAction,
    RequestTourSuccessAction,
    RequestTourFailureAction,
    RequestTourRoomDataAction,
    RequestTourRoomDataSuccessAction,
    RequestTourRoomDataFailureAction,
} from '@/redux/actions/tour';
import {
    REQUEST_TOUR,
    REQUEST_TOUR_SUCCESS,
    REQUEST_TOUR_FAILURE,
    TOUR_REQUEST_TOUR_ROOM_DATA,
    TOUR_REQUEST_TOUR_ROOM_DATA_SUCCESS,
    TOUR_REQUEST_TOUR_ROOM_DATA_FAILURE,
} from './types';

export const requestTour = (
    tourId: string,
    currencyCode: string,
    departureId?: number,
    departureMonth?: string | null
): RequestTourAction => ({
    type: REQUEST_TOUR,
    tourId: tourId,
    currencyCode: currencyCode,
    departureId: departureId,
    departureMonth: departureMonth,
});

export const requestTourSuccess = (tour: Tour): RequestTourSuccessAction => ({
    type: REQUEST_TOUR_SUCCESS,
    tour,
});

export const requestTourFailure = (
    tourId: string,
    errors: string[]
): RequestTourFailureAction => {
    return {
        type: REQUEST_TOUR_FAILURE,
        tourId,
        errors,
    };
};

export const requestTourRoomData = (
    departureId: number,
    currencyCode: string
): RequestTourRoomDataAction => ({
    type: TOUR_REQUEST_TOUR_ROOM_DATA,
    departureId,
    currencyCode,
});

export const requestTourRoomDataSuccess = (
    departureId: number,
    currencyCode: string,
    data: TourRoom[]
): RequestTourRoomDataSuccessAction => {
    return {
        type: TOUR_REQUEST_TOUR_ROOM_DATA_SUCCESS,
        departureId,
        currencyCode,
        data,
    };
};

export const requestTourRoomDataFailure = (
    departureId: number,
    currencyCode: string,
    error: string,
    message: string
): RequestTourRoomDataFailureAction => {
    return {
        type: TOUR_REQUEST_TOUR_ROOM_DATA_FAILURE,
        departureId,
        currencyCode,
        error,
        message,
    };
};
