import * as Cookies from 'js-cookie';
import { logErrorWithCustomMessage } from '../sentry';
import { TrackingCode } from '@/endurance/common/TrackingCode';

interface Provider {
    name: 'Google' | 'Microsoft' | 'Meta';
    clickIdParamName: string;
    cookieName: string;
    saveCookie: boolean;
}

const providers: Provider[] = [
    {
        name: 'Google',
        clickIdParamName: 'gclid',
        cookieName: 'TAP-GCLID',
        saveCookie: true,
    },
    {
        name: 'Microsoft',
        clickIdParamName: 'msclkid',
        cookieName: 'TAP-MSCLKID',
        saveCookie: true,
    },
    {
        name: 'Meta',
        clickIdParamName: 'fbclid',
        cookieName: '_fbc',
        saveCookie: false,
    },
];

export const setupTrackingCodes = () =>
    providers.forEach(provider => {
        if (provider.saveCookie) {
            setupTrackingCode(provider.clickIdParamName, provider.cookieName);
        }
    });

export const getTrackingCodes = (): TrackingCode[] =>
    providers
        .map(provider => getTrackingCode(provider.name, provider.cookieName))
        .filter(x => !!x.code);

const setupTrackingCode = (clickIdParamName: string, cookieName: string) => {
    try {
        const clickId = getClickIdFromQuery(clickIdParamName);

        if (clickId) {
            Cookies.set(cookieName, clickId, { expires: 90, secure: true });

            console.log(`${clickIdParamName} saved`, clickId); // eslint-disable-line no-console
        }
    } catch (error) {
        logErrorWithCustomMessage(error, 'Unable to save tracking cookie');
    }
};

const getTrackingCode = (
    provider: 'Google' | 'Microsoft' | 'Meta',
    cookieName: string
): TrackingCode => {
    let clickId = null;

    try {
        clickId = Cookies.get(cookieName) || null;
    } catch (error) {
        logErrorWithCustomMessage(error, 'Unable to get tracking cookie');
    }

    return { provider: provider, code: clickId };
};

const getClickIdFromQuery = (paramName: string) => {
    if (URL) {
        const param = new URL(window.location.href).searchParams.get(paramName);
        return param ? decodeURIComponent(param) : null;
    } else {
        var match = RegExp(`[?&]${paramName}=([^&]*)`).exec(
            window.location.search
        );
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }
};

const tracking = {
    setupTrackingCodes,
    getTrackingCodes,
};

export default tracking;
