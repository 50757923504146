import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import styles from './SecondaryLoadingButton.module.scss';

const SecondaryLoadingButton = ({ className, ...props }) => {
    return (
        <SecondaryButton
            className={classNames(styles.secondaryLoadingButton, className)}
            onClick={e => {
                e.preventDefault();
            }}
            {...props}
        >
            <div className={classNames(styles.loader)} />
        </SecondaryButton>
    );
};

SecondaryLoadingButton.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default SecondaryLoadingButton;
