import { logErrorWithInfo } from '../../sentry';
import ConversionType from '../enums/ConversionType';
import IAnalyticsProvider from '../interfaces/IAnalyticsProvider';

export default class metaAnalytics implements IAnalyticsProvider {
    public sendProviderAdsEvent() {
        //Not implemented: Google is used to view event data
    }

    public sendEventV2(): void {
        //Not implemented: Google/Mixpanel is used to view event data
    }

    public sendProviderPageView() {
        // Not implemented - Page view tracking is doing for Meta in index.html
    }

    public sendProviderAdsConversion(
        type: ConversionType,
        id: string,
        value: number | null = null,
        currency: string | null = null
    ) {
        try {
            window.fbq = window.fbq || [];

            window.fbq('trackCustom', 'Conversion', {
                action: type,
                id: id,
                currency: currency,
                value: value,
            });
        } catch (error) {
            logErrorWithInfo('Failed to send Meta Ads conversion.', {
                error,
                type,
                value,
                currency,
            });
        }
    }
}
