import { AirportsResult } from '@/apis/flightApi';

import { call, put } from 'redux-saga/effects';
import * as flightApi from '../../apis/flightApi';
import { logErrorWithInfo } from '../../utils/sentry';
import {
    requestAirportDataSuccess,
    requestAirportDataFailure,
} from '../actions/flights.actions';

export function* loadAirportData() {
    try {
        const result: AirportsResult = yield call(flightApi.getAirports);
        if (result.success) {
            yield put(
                requestAirportDataSuccess(result.airports, result.airportGroups)
            );
        } else {
            yield put(requestAirportDataFailure(result.errors));
            logErrorWithInfo(Error('Failed to fetch airports'), result);
        }
    } catch (e) {
        logErrorWithInfo(e);
        yield put(
            requestAirportDataFailure([`Unexpected Error - ${e.message}`])
        );
    }
}
