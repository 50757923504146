import Moment from 'moment';
import queryString from 'qs';
import searchHelper from '../helpers/searchHelper';
import isEqual from 'lodash.isequal';

export const queryDateFormat = 'YYYYMMDD';
export const apiDateFormat = 'YYYY-MM-DD';

export const getSearchParamsFromQuery = queryStringParams => {
    const parsedParams = queryString.parse(queryStringParams, {
        ignoreQueryPrefix: true,
    });

    const params = {
        searchText: parsedParams.searchText ? parsedParams.searchText : null,
        checkInDate: parsedParams.checkInDate
            ? parseQueryDate(parsedParams.checkInDate)
            : null,
        accommodationId: parsedParams.accommodationId
            ? parseInt(parsedParams.accommodationId)
            : null,
        checkOutDate: parsedParams.checkOutDate
            ? parseQueryDate(parsedParams.checkOutDate)
            : null,
        adultCount: parsedParams.adultCount
            ? parseInt(parsedParams.adultCount)
            : null,
        childCount: parsedParams.childCount
            ? parseInt(parsedParams.childCount)
            : null,
        childAges: parsedParams.childAges
            ? parsedParams.childAges.split(',').map(a => parseInt(a))
            : [],
        starRatings: parsedParams.starRatings
            ? parsedParams.starRatings.split(',').map(a => parseInt(a))
            : [],
        boardBases: parsedParams.boardBases
            ? parsedParams.boardBases.split(',')
            : [],
        accommodationTypes: parsedParams.accommodationTypes
            ? parsedParams.accommodationTypes.split(',')
            : [],
        minimumPricePerNight: parsedParams.minimumPricePerNight,
        maximumPricePerNight: parsedParams.maximumPricePerNight,
        source: parsedParams.source ? parsedParams.source : null,
    };
    return params;
};

export const getApiQueryFromSearchParams = searchParams => {
    const params = {
        ...searchHelper.removeUnusedEntries(searchParams),
        childAges:
            searchParams.childAges != null && searchParams.childAges.length > 0
                ? searchParams.childAges.join(',')
                : null,
        starRatings:
            searchParams.starRatings != null &&
            searchParams.starRatings.length > 0
                ? searchParams.starRatings.join(',')
                : null,
        boardBases:
            searchParams.boardBases != null &&
            searchParams.boardBases.length > 0
                ? searchParams.boardBases.join(',')
                : null,
        accommodationTypes:
            searchParams.accommodationTypes != null &&
            searchParams.accommodationTypes.length > 0
                ? searchParams.accommodationTypes.join(',')
                : null,
    };

    return queryString.stringify(params, {
        encode: false,
        arrayFormat: 'comma',
    });
};

export const getQueryFromRequest = searchRequest => {
    const params = {
        searchText: searchRequest.searchText,
        adultCount: searchRequest.adultCount,
        childCount: searchRequest.childCount,
        checkInDate: searchRequest.checkInDate
            ? parseResponseDate(searchRequest.checkInDate)
            : null,
        checkOutDate: searchRequest.checkOutDate
            ? parseResponseDate(searchRequest.checkOutDate)
            : null,
        childAges: searchRequest.childAges
            ? searchRequest.childAges.join(',')
            : null,
        starRatings: searchRequest.starRatings
            ? searchRequest.starRatings.join(',')
            : null,
        boardBases: searchRequest.boardBases
            ? searchRequest.boardBases.join(',')
            : null,
        accommodationTypes: searchRequest.accommodationTypes
            ? searchRequest.accommodationTypes.join(',')
            : null,
        minimumPricePerNight: searchRequest.minimumPricePerNight,
        maximumPricePerNight: searchRequest.maximumPricePerNight,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, {
        encode: false,
        arrayFormat: 'comma',
    });
};

export const getAccommodationParamsFromRequest = requestParams =>
    searchHelper.removeUnusedEntries({
        checkInDate: parseResponseDate(requestParams.checkInDate),
        checkOutDate: parseResponseDate(requestParams.checkOutDate),
        adultCount: requestParams.adultCount,
        childCount: requestParams.childCount,
        childAges: requestParams.childAges
            ? requestParams.childAges.join(',')
            : null,
        accommodationId: requestParams.accommodationId,
    });

export const paramsMatchRequest = (params, request, selectedCurrencyCode) => {
    const newRequest = {
        ...searchHelper.removeUnusedEntries(getSearchParamsFromQuery(params)),
        currencyCode: selectedCurrencyCode,
    };

    const oldRequest = searchHelper.removeUnusedEntries({ ...request });
    delete oldRequest.sessionId;

    const isMatch = isEqual(newRequest, oldRequest);

    return isMatch;
};

export const parseQueryDate = date =>
    Moment(date, queryDateFormat, true).format(apiDateFormat);

export const parseResponseDate = date =>
    Moment(date, apiDateFormat).format(queryDateFormat);
