import moment from 'moment';

const keyDateFormat = 'YYYYMMDD';

export const accommodationKey = (countryIsoCode2, slug) =>
    `${countryIsoCode2}|${slug}`.toLowerCase();

export const roomsKey = (
    countryIsoCode2,
    slug,
    currencyCode,
    checkInDate,
    checkOutDate,
    adultCount,
    childAges
) => {
    const parentKey = accommodationKey(countryIsoCode2, slug);
    const checkIn = moment(checkInDate).format(keyDateFormat);
    const checkOut = moment(checkOutDate).format(keyDateFormat);
    const childAgesKey = childAges ? childAges : '';

    return `${parentKey}|${currencyCode}|${checkIn}|${checkOut}|${adultCount}|${childAgesKey}`;
};
