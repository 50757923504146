import { AttributeLookup } from '@/endurance/lookups/AttributeLookup';
import { RegionLookup } from '@/endurance/lookups/RegionLookup';
import { get, post } from './enduranceApi';
import { ApiResponse } from '@/apis';

export function getAttributes(): Promise<ApiResponse<AttributeLookup[]>> {
    return get<AttributeLookup[]>(`/lookups/attributes`).then(
        ({ result, isSuccess, errors }) => {
            const response: ApiResponse<AttributeLookup[]> = {
                isSuccess,
                errors,
                result: null,
            };

            if (result) {
                try {
                    const lookups: AttributeLookup[] = result.map(r => ({
                        group: r.group,
                        name: r.name,
                    }));

                    response.result = lookups;
                } catch (error) {
                    response.isSuccess = false;
                    response.errors = ['Unexpected error mapping lookups.'];
                }
            }

            return response;
        }
    );
}

export function getRegions(
    countries: string[]
): Promise<ApiResponse<RegionLookup[]>> {
    return post<string[], RegionLookup[]>(`/lookups/regions`, countries).then(
        ({ result, isSuccess, errors }) => {
            const response: ApiResponse<RegionLookup[]> = {
                isSuccess,
                errors,
                result: null,
            };

            if (result) {
                try {
                    const regionLookups: RegionLookup[] = result.map(r => ({
                        region: r.region,
                        isoCode: r.isoCode,
                        countries: r.countries.map(c => ({
                            country: c.country,
                            isoCode: c.isoCode,
                        })),
                    }));
                    response.result = regionLookups;
                } catch (error) {
                    response.isSuccess = false;
                    response.errors = ['Unexpected error mapping lookups.'];
                }
            }
            return response;
        }
    );
}

const lookupsApi = {
    getAttributes,
    getRegions,
};

export default lookupsApi;
