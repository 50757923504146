import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './BrandButton.module.scss';

const BrandButton = ({ className, ...props }) => {
    return (
        <Button
            className={classNames(styles.brandButton, className)}
            {...props}
        />
    );
};

BrandButton.propTypes = {
    ...Button.propTypes,
};

export default BrandButton;
