import { FlightSearchResponse } from '@/endurance/flightSearch';
import { TourFlightSearchParams } from '@/core/tourFlightSearch';

import { getApiQueryFromSearchParams } from '../services/tourFlightSearchService';
import { getResponse } from './enduranceApi';

const searchUrl = '/flights/search';

export function searchFlights(params: TourFlightSearchParams) {
    const query = getApiQueryFromSearchParams(params);

    return getResponse<FlightSearchResponse>(`${searchUrl}?${query}`).then(
        (result: FlightSearchResponse) => ({
            sessionId: result.sessionId,
            success: result.success,
            errors: result.errors,
            flights: result.flights,
            totalResults: result.totalResults,
            totalFilteredResults: result.totalFilteredResults,
            aggregations: result.aggregations,
            request: result.request,
        })
    );
}

const flightSearchApi = {
    searchFlights,
};

export default flightSearchApi;
