import Helmet from 'react-helmet';
import { TravelAgency, WithContext } from 'schema-dts';
import metaLogo from '../../../assets/images/meta/the-adventure-people-logo.png';
import metaImageThailand1x1 from '../../../assets/images/meta/1x1/the-adventure-people-thailand.jpg';
import metaImageThailand4x3 from '../../../assets/images/meta/4x3/the-adventure-people-thailand.jpg';
import metaImageThailand16x9 from '../../../assets/images/meta/16x9/the-adventure-people-thailand.jpg';
import metaImageSahara1x1 from '../../../assets/images/meta/1x1/the-adventure-people-sahara.jpg';
import metaImageSahara4x3 from '../../../assets/images/meta/4x3/the-adventure-people-sahara.jpg';
import metaImageSahara16x9 from '../../../assets/images/meta/16x9/the-adventure-people-sahara.jpg';
import metaImageKayaking1x1 from '../../../assets/images/meta/1x1/the-adventure-people-kayaking.jpg';
import metaImageKayaking4x3 from '../../../assets/images/meta/4x3/the-adventure-people-kayaking.jpg';
import metaImageKayaking16x9 from '../../../assets/images/meta/16x9/the-adventure-people-kayaking.jpg';
import metaImageAlaska1x1 from '../../../assets/images/meta/1x1/the-adventure-people-alaska.jpg';
import metaImageAlaska4x3 from '../../../assets/images/meta/4x3/the-adventure-people-alaska.jpg';
import metaImageAlaska16x9 from '../../../assets/images/meta/16x9/the-adventure-people-alaska.jpg';

const BusinessStructuredData = () => {
    const baseUrl = window.location.origin;

    const travelAgency: WithContext<TravelAgency> = {
        '@context': 'https://schema.org',
        '@type': 'TravelAgency',
        url: baseUrl,
        name: 'The Adventure People',
        description:
            'The Adventure People offer small group guided adventure holidays, including activities, solo traveller, families packages and more, find, compare and book your adventure with us!',
        logo: `${baseUrl}${metaLogo}`,
        image: [
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageThailand1x1}`,
                width: '1280px',
                height: '720px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageThailand4x3}`,
                width: '800px',
                height: '600px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageThailand16x9}`,
                width: '400px',
                height: '400px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageSahara1x1}`,
                width: '1280px',
                height: '720px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageSahara4x3}`,
                width: '800px',
                height: '600px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageSahara16x9}`,
                width: '400px',
                height: '400px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageKayaking1x1}`,
                width: '1280px',
                height: '720px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageKayaking4x3}`,
                width: '800px',
                height: '600px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageKayaking16x9}`,
                width: '400px',
                height: '400px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageAlaska1x1}`,
                width: '1280px',
                height: '720px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageAlaska4x3}`,
                width: '800px',
                height: '600px',
            },
            {
                '@type': 'ImageObject',
                url: `${baseUrl}${metaImageAlaska16x9}`,
                width: '400px',
                height: '400px',
            },
        ],
        priceRange: '$$',
        telephone: '+44-20-8004-8886',
        address: {
            '@type': 'PostalAddress',
            streetAddress: 'Studio 1.01 Piano House, 9 Brighton Terrace',
            addressLocality: 'Brixton, London',
            postalCode: 'SW9 8DJ',
            addressCountry: 'GB',
        },
        contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer service',
            telephone: '+44-020-8004-8886',
            email: 'hello@theadventurepeople.com',
        },
        sameAs: [
            'https://www.facebook.com/TheAdventurePeoples/',
            'https://www.instagram.com/the_adventure_people/',
            'https://www.youtube.com/c/TheAdventurePeople',
            'https://twitter.com/adventure_peeps',
            'https://www.linkedin.com/company/the-adventure-people/',
        ],
        memberOf: [
            {
                '@type': 'ProgramMembership',
                hostingOrganization: 'UK Civil Aviation Authority',
                membershipNumber: '11740',
                programName: 'ATOL',
            },
        ],
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(travelAgency)}
            </script>
        </Helmet>
    );
};

export default BusinessStructuredData;
