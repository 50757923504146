import { call, put } from 'redux-saga/effects';
import searchApi from '../../apis/accommodationSearchApi';
import { logErrorWithInfo } from '../../utils/sentry';
import {
    requestAccommodationRoomsSuccess,
    requestAccommodationRoomsFailure,
} from '../actions/accommodationRooms.actions';

export function* requestAccommodationRooms(roomsRequest) {
    try {
        const result = yield call(
            searchApi.searchAccommodationRooms,
            roomsRequest
        );
        if (result.success) {
            yield put(requestAccommodationRoomsSuccess(roomsRequest, result));
        } else {
            yield put(requestAccommodationRoomsFailure(result));
            logErrorWithInfo(Error('Failed to fetch accommodation rooms'), {
                pricesRequest: roomsRequest,
                result,
            });
        }
    } catch (e) {
        logErrorWithInfo(e, { roomsRequest });
        yield put(requestAccommodationRoomsFailure(roomsRequest, [`${e}`]));
    }
}
