import { useContext, useState, createContext, ReactNode } from 'react';
import experimentTrackingService from '../services/experimentTrackingService';
import { v4 as uuidv4 } from 'uuid';
import { isPreRender, isBot } from '../utils/config';

export interface ExperimentTrackingContextValue {
    experimentTrackingUUID: string;
}

export interface ExperimentTrackingProviderProps {
    children: ReactNode;
}

const ExperimentTrackingContext =
    createContext<ExperimentTrackingContextValue | null>(null);

export function useExperimentTracking() {
    const context = useContext(ExperimentTrackingContext);
    if (!context) {
        throw new Error('No Experiment Tracking Context initialised.');
    }
    return context;
}

export function ExperimentTrackingProvider({
    children,
}: ExperimentTrackingProviderProps) {
    let experimentUUID = experimentTrackingService.getExperimentUUID();

    if (!experimentUUID) {
        if (isPreRender() || isBot()) {
            experimentUUID = 'IsBot';
        } else {
            experimentUUID = uuidv4();
        }

        experimentTrackingService.setupExperimentUUID(experimentUUID);
    }

    const [experimentTrackingUUID] = useState(experimentUUID);

    return (
        <ExperimentTrackingContext.Provider
            value={{
                experimentTrackingUUID: experimentTrackingUUID,
            }}
        >
            {children}
        </ExperimentTrackingContext.Provider>
    );
}
