import { GetParams, SearchParams } from '@/apis/tourPackagesApi';
import {
    TourPackageDeparture,
    TourPackageFlight,
    TourPackagePrice,
    TourPackageTour,
} from '@/endurance/tourPackages';
import * as TYPES from './types';
import {
    GetPackageAction,
    GetPackageFailureAction,
    GetPackageSuccessAction,
} from '@/redux/actions/tourPackages';

export const toggleTourFlightInclusiveSwitch = () => {
    return {
        type: TYPES.TOGGLE_TOUR_FLIGHT_INCLUSIVE_SWITCH,
    };
};

export const searchTourDeparturesRequest = (data: SearchParams) => {
    return {
        type: TYPES.TOUR_DEPARTURES_SEARCH_REQUEST,
        data,
    };
};

export const searchTourDeparturesSuccess = (
    departures: TourPackageDeparture[]
) => {
    return {
        type: TYPES.TOUR_DEPARTURES_SEARCH_SUCCESS,
        departures,
    };
};

export const searchTourDeparturesFailure = (error: string) => {
    return {
        type: TYPES.TOUR_DEPARTURES_SEARCH_FAILURE,
        error,
    };
};

export const getPackageRequest = (
    params: GetParams,
    currencyCode: string
): GetPackageAction => ({
    type: TYPES.TOUR_PACKAGES_GET_REQUEST,
    params,
    currencyCode,
});

export const getPackageSuccess = (
    tour: TourPackageTour,
    flight: TourPackageFlight,
    prices: TourPackagePrice[]
): GetPackageSuccessAction => ({
    type: TYPES.TOUR_PACKAGES_GET_SUCCESS,
    data: {
        tour: tour,
        flight: flight,
        prices: prices,
    },
});

export const getPackageFailure = (error: string): GetPackageFailureAction => ({
    type: TYPES.TOUR_PACKAGES_GET_FAILURE,
    error,
});
