import { tapApiBaseUrl, tapApiAuthKey } from '../utils/config';

const agentUrl = `${tapApiBaseUrl}/agents`;
const tradeAgentSignUpFormUrl = `${tapApiBaseUrl}/agents/tradeagentsignup`;

export async function fetchAgents() {
    const response = await fetch(agentUrl, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
    });

    return await response.json();
}

export function sendTradeAgentSignUpForm(
    firstName,
    lastName,
    email,
    phoneCode,
    phoneNumber,
    travelAgency,
    branchName,
    abtaNumber
) {
    return fetch(tradeAgentSignUpFormUrl, {
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone: `${phoneCode}-${phoneNumber}`,
            travelAgency: travelAgency,
            branchName: branchName !== '' ? branchName : null,
            abtaNumber: abtaNumber !== '' ? abtaNumber : null,
        }),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

const agentsApi = {
    fetchAgents,
    sendTradeAgentSignUpForm,
};

export default agentsApi;
