import { TourDeparture } from '@/core';
import { TourDepartureState } from '@/redux/TourDepartureState';
import { TourState } from '@/redux/TourState';
import { differenceInDays } from 'date-fns';

export const selectTourDepartures = (departureState: TourDepartureState) => {
    return departureState?.departures || undefined;
};

export const selectTourDeparture = (
    departureState: TourDepartureState,
    departureId: number
) => {
    const departures = departureState?.departures;
    const departureResult =
        departures && departures.find(d => d.departureId === departureId);

    if (departureResult) {
        return departureResult;
    } else {
        return null;
    }
};

export const selectIsRequestingTourDepartures = (
    departureState: TourDepartureState
) => {
    return departureState.loading !== undefined ? departureState.loading : true;
};

export const selectIsLoadingTourDepartures = (
    departureState: TourDepartureState
): boolean | undefined => {
    return departureState.loading;
};

export const selectIsTourDeparturesNotFound = (
    departureState: TourDepartureState
) => {
    const errors = departureState.errors;

    return !!errors && !!errors.find(e => e.toLowerCase() === 'notfound');
};

export const selectTourDeparturesErrors = (
    departureState: TourDepartureState
) => {
    return departureState.errors ?? [];
};

// Departure Selectors
export const selectDeparturePrice = (departure: TourDeparture) =>
    departure.sellingPrice;

export const selectDepartureOriginalPrice = (departure: TourDeparture) =>
    departure.originalPrice;

export const selectDepartureAdultPrice = (departure: TourDeparture) =>
    departure.sellingPrice;

export const selectDepartureDiscount = (departure: TourDeparture) =>
    departure.discount;

export const selectDepartureDeposit = (departure: TourDeparture) =>
    departure.deposit;

export const selectIsFullBalanceDue = (departure: TourDeparture) => {
    return differenceInDays(new Date(), departure.balanceDueDate) >= 0;
};

export const selectTourRoomData = (
    tourState: TourState,
    departureId: number,
    currencyCode: string
) => tourState.roomData[`${departureId}-${currencyCode}`];

// Combined Selectors

export const selectTourProduct = (
    departureState: TourDepartureState,
    departureId: number,
    quantity: number
) => {
    const departure = selectTourDeparture(departureState, departureId);
    if (departure === null) {
        return null;
    }

    const adultPrices = departure.sellingPrice;
    if (!adultPrices) {
        return null;
    }
    const originalPrice = departure.originalPrice;
    const deposit = departure.deposit;
    const discount = departure.discount;
    const hasTourDiscount = discount && discount.value > 0;
    const totalDeposit = deposit && deposit.value * quantity;

    return {
        totalDeposit,
        isFullBalanceDue: selectIsFullBalanceDue(departure),
        balanceDueDate: departure.balanceDueDate,
        lineItems: [
            {
                id: 'Tour',
                type: 'Product',
                label: hasTourDiscount ? 'Original tour price' : 'Tour price',
                price:
                    hasTourDiscount && originalPrice
                        ? originalPrice.value
                        : adultPrices.value,
                quantity: quantity,
            },
            ...(hasTourDiscount
                ? [
                      {
                          id: 'TourDiscount',
                          type: 'Product',
                          label: 'Tour discount',
                          price: -discount.value,
                          quantity: quantity,
                      },
                  ]
                : []),
        ],
    };
};

export const selectTourDepartureProduct = (
    tourState: TourState,
    departureState: TourDepartureState,
    departureId: number,
    selectedRooms: { [key: number]: number },
    currencyCode: string
) => {
    const departure = selectTourDeparture(departureState, departureId);
    if (departure === null) {
        return { lineItems: [] };
    }

    const roomData = selectTourRoomData(tourState, departureId, currencyCode);
    const deposit = departure.deposit;

    let totalQuantity = 0;
    const lineItems: {
        id: 'Tour Room';
        type: 'Product';
        label: 'Tour price';
        price: number;
        quantity: number;
    }[] = [];

    if (roomData && roomData.options) {
        Object.keys(selectedRooms).forEach(key => {
            const departureRoomId = parseInt(key);
            const room = roomData.options?.find(
                r => r.departureRoomId === departureRoomId
            );
            const quantity = selectedRooms[departureRoomId];

            totalQuantity += quantity;
            if (room) {
                lineItems.push({
                    id: 'Tour Room',
                    type: 'Product',
                    label: 'Tour price',
                    price: room.price,
                    quantity: quantity,
                });
            }
        });
    }

    return {
        totalDeposit: deposit && deposit.value * totalQuantity,
        isFullBalanceDue: selectIsFullBalanceDue(departure),
        balanceDueDate: departure.balanceDueDate,
        lineItems: lineItems,
    };
};
