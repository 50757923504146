import { DiallingCodeOption } from '@/core';

const diallingCodes: {
    options: DiallingCodeOption[];
}[] = [
    {
        options: [
            {
                country: 'United Kingdom',
                value: '+44',
                countryCode: 'GBR',
                label: '+44 - United Kingdom',
            },
            {
                country: 'United States',
                value: '+1',
                countryCode: 'USA',
                label: '+1 - United States',
            },
            {
                country: 'Australia',
                value: '+61',
                countryCode: 'AUS',
                label: '+61 - Australia',
            },
            {
                country: 'Canada',
                value: '+1',
                countryCode: 'CAN',
                label: '+1 - Canada',
            },
            {
                country: 'Ireland',
                value: '+353',
                countryCode: 'IRL',
                label: '+353 - Ireland',
            },
            {
                country: '--------------',
                value: '',
                isDisabled: true,
                countryCode: null,
                label: '---------------',
            },
        ],
    },
    {
        options: [
            {
                country: 'Abkhazia',
                value: '+7',
                countryCode: 'GE',
                label: '+7 - Abkhazia',
            },
            {
                country: 'Afghanistan',
                value: '+93',
                countryCode: 'AFG',
                label: '+93 - Afghanistan',
            },
            {
                country: 'Åland Islands',
                value: '+358',
                countryCode: 'ALA',
                label: '+358 - Åland Islands',
            },
            {
                country: 'Albania',
                value: '+355',
                countryCode: 'ALB',
                label: '+355 - Albania',
            },
            {
                country: 'Algeria',
                value: '+213',
                countryCode: 'DZA',
                label: '+213 - Algeria',
            },
            {
                country: 'American Samoa',
                value: '+1',
                countryCode: 'ASM',
                label: '+1 - American Samoa',
            },
            {
                country: 'Andorra',
                value: '+376',
                countryCode: 'AND',
                label: '+376 - Andorra',
            },
            {
                country: 'Angola',
                value: '+244',
                countryCode: 'ANG',
                label: '+244 - Angola',
            },
            {
                country: 'Anguilla',
                value: '+1',
                countryCode: 'AIA',
                label: '+1 - Anguilla',
            },
            {
                country: 'Antigua and Barbuda',
                value: '+1',
                countryCode: 'ATG',
                label: '+1 - Antigua and Barbuda',
            },
            {
                country: 'Argentina',
                value: '+54',
                countryCode: 'ARG',
                label: '+54 - Argentina',
            },
            {
                country: 'Armenia',
                value: '+374',
                countryCode: 'ARM',
                label: '+374 - Armenia',
            },
            {
                country: 'Aruba',
                value: '+297',
                countryCode: 'ABW ',
                label: '+297 - Aruba',
            },
            {
                country: 'Austria',
                value: '+43',
                countryCode: 'AUT',
                label: '+43 - Austria',
            },
            {
                country: 'Azerbaijan',
                value: '+994',
                countryCode: 'AZE',
                label: '+994 - Azerbaijan',
            },
            {
                country: 'Bahamas',
                value: '+1',
                countryCode: 'BHS',
                label: '+1 - Bahamas',
            },
            {
                country: 'Bahrain',
                value: '+973',
                countryCode: 'BHR',
                label: '+973 - Bahrain',
            },
            {
                country: 'Bangladesh',
                value: '+880',
                countryCode: 'BGD',
                label: '+880 - Bangladesh',
            },
            {
                country: 'Barbados',
                value: '+1',
                countryCode: 'BRB',
                label: '+1 - Barbados',
            },
            {
                country: 'Belarus',
                value: '+375',
                countryCode: 'BLR',
                label: '+375 - Belarus',
            },
            {
                country: 'Belgium',
                value: '+32',
                countryCode: 'BEL',
                label: '+32 - Belgium',
            },
            {
                country: 'Belize',
                value: '+501',
                countryCode: 'BLZ',
                label: '+501 - Belize',
            },
            {
                country: 'Benin',
                value: '+229',
                countryCode: 'BEN',
                label: '+229 - Benin',
            },
            {
                country: 'Bermuda',
                value: '+1',
                countryCode: 'BMU',
                label: '+1 - Bermuda',
            },
            {
                country: 'Bhutan',
                value: '+975',
                countryCode: 'BTN',
                label: '+975 - Bhutan',
            },
            {
                country: 'Bolivia',
                value: '+591',
                countryCode: 'BOL',
                label: '+591 - Bolivia',
            },
            {
                country: 'Bonaire',
                value: '+599',
                countryCode: 'BES',
                label: '+599 - Bonaire',
            },
            {
                country: 'Bosnia and Herzegovina',
                value: '+387',
                countryCode: 'BIH',
                label: '+387 - Bosnia and Herzegovina',
            },
            {
                country: 'Botswana',
                value: '+267',
                countryCode: 'BWA',
                label: '+267 - Botswana',
            },
            {
                country: 'Brazil',
                value: '+55',
                countryCode: 'BRA',
                label: '+55 - Brazil',
            },
            {
                country: 'British Indian Ocean Territory',
                value: '+246',
                countryCode: 'IOT',
                label: '+246 - British Indian Ocean Territory',
            },
            {
                country: 'British Virgin Islands',
                value: '+1',
                countryCode: 'VGB',
                label: '+1 - British Virgin Islands',
            },
            {
                country: 'Brunei Darussalam',
                value: '+673',
                countryCode: 'BRN',
                label: '+673 - Brunei Darussalam',
            },
            {
                country: 'Bulgaria',
                value: '+359',
                countryCode: 'BGR',
                label: '+359 - Bulgaria',
            },
            {
                country: 'Burkina Faso',
                value: '+226',
                countryCode: 'BFA',
                label: '+226 - Burkina Faso',
            },
            {
                country: 'Burundi',
                value: '+257',
                countryCode: 'BDI',
                label: '+257 - Burundi',
            },
            {
                country: 'Cambodia',
                value: '+855',
                countryCode: 'KHM',
                label: '+855 - Cambodia',
            },
            {
                country: 'Cameroon',
                value: '+237',
                countryCode: 'CMR',
                label: '+237 - Cameroon',
            },
            {
                country: 'Cape Verde',
                value: '+238',
                countryCode: 'CPV',
                label: '+238 - Cape Verde',
            },
            {
                country: 'Caribbean Netherlands',
                value: '+599',
                countryCode: 'BES',
                label: '+599 - Caribbean Netherlands',
            },
            {
                country: 'Cayman Islands',
                value: '+1',
                countryCode: 'CYM',
                label: '+1 - Cayman Islands',
            },
            {
                country: 'Central African Republic',
                value: '+236',
                countryCode: 'CAF',
                label: '+236 - Central African Republic',
            },
            {
                country: 'Chad',
                value: '+235',
                countryCode: 'TCD',
                label: '+235 - Chad',
            },
            {
                country: 'Chile',
                value: '+56',
                countryCode: 'CHL',
                label: '+56 - Chile',
            },
            {
                country: 'China',
                value: '+86',
                countryCode: 'CHN',
                label: '+86 - China',
            },
            {
                country: 'Christmas Island',
                value: '+61',
                countryCode: 'CXR',
                label: '+61 - Christmas Island',
            },
            {
                country: 'Cocos (Keeling) Islands',
                value: '+61',
                countryCode: 'CCK',
                label: '+61 - Cocos (Keeling) Islands',
            },
            {
                country: 'Colombia',
                value: '+57',
                countryCode: 'COL',
                label: '+57 - Colombia',
            },
            {
                country: 'Comoros',
                value: '+269',
                countryCode: 'COM',
                label: '+269 - Comoros',
            },
            {
                country: 'Congo',
                value: '+242',
                countryCode: 'COG',
                label: '+242 - Congo',
            },
            {
                country: 'Congo, the Democratic Republic of the (Zaire)',
                value: '+243',
                countryCode: 'COD',
                label: '+243 - Congo, the Democratic Republic of the (Zaire)',
            },
            {
                country: 'Cook Islands',
                value: '+682',
                countryCode: 'COK',
                label: '+682 - Cook Islands',
            },
            {
                country: 'Costa Rica',
                value: '+506',
                countryCode: 'CRI',
                label: '+506 - Costa Rica',
            },
            {
                country: 'Croatia',
                value: '+385',
                countryCode: 'HRV',
                label: '+385 - Croatia',
            },
            {
                country: 'Cuba',
                value: '+53',
                countryCode: 'CUB',
                label: '+53 - Cuba',
            },
            {
                country: 'Curaçao',
                value: '+599',
                countryCode: 'CUW',
                label: '+599 - Curaçao',
            },
            {
                country: 'Cyprus',
                value: '+357',
                countryCode: 'CYP',
                label: '+357 - Cyprus',
            },
            {
                country: 'Czech Republic',
                value: '+420',
                countryCode: 'CZE',
                label: '+420 - Czech Republic',
            },
            {
                country: 'Denmark',
                value: '+45',
                countryCode: 'DNK',
                label: '+45 - Denmark',
            },
            {
                country: 'Djibouti',
                value: '+253',
                countryCode: 'DJI',
                label: '+253 - Djibouti',
            },
            {
                country: 'Dominica',
                value: '+1',
                countryCode: 'DMA',
                label: '+1 - Dominica',
            },
            {
                country: 'Dominican Republic',
                value: '+1',
                countryCode: 'DOM',
                label: '+1 - Dominican Republic',
            },
            {
                country: 'Ecuador',
                value: '+593',
                countryCode: 'ECU',
                label: '+593 - Ecuador',
            },
            {
                country: 'Egypt',
                value: '+20',
                countryCode: 'EGY',
                label: '+20 - Egypt',
            },
            {
                country: 'El Salvador',
                value: '+503',
                countryCode: 'SLV',
                label: '+503 - El Salvador',
            },
            {
                country: 'Equatorial Guinea',
                value: '+240',
                countryCode: 'GNQ',
                label: '+240 - Equatorial Guinea',
            },
            {
                country: 'Eritrea',
                value: '+291',
                countryCode: 'ERI',
                label: '+291 - Eritrea',
            },
            {
                country: 'Estonia',
                value: '+372',
                countryCode: 'EST',
                label: '+372 - Estonia',
            },
            {
                country: 'Ethiopia',
                value: '+251',
                countryCode: 'ETH',
                label: '+251 - Ethiopia',
            },
            {
                country: 'Falkland Islands',
                value: '+500',
                countryCode: 'FLK',
                label: '+500 - Falkland Islands',
            },
            {
                country: 'Faroe Islands',
                value: '+298',
                countryCode: 'FRO',
                label: '+298 - Faroe Islands',
            },
            {
                country: 'Fiji',
                value: '+679',
                countryCode: 'FJI',
                label: '+679 - Fiji',
            },
            {
                country: 'Finland',
                value: '+358',
                countryCode: 'FIN',
                label: '+358 - Finland',
            },
            {
                country: 'France',
                value: '+33',
                countryCode: 'FRA',
                label: '+33 - France',
            },
            {
                country: 'French Guiana',
                value: '+594',
                countryCode: 'GUF',
                label: '+594 - French Guiana',
            },
            {
                country: 'French Polynesia',
                value: '+689',
                countryCode: 'PYF',
                label: '+689 - French Polynesia',
            },
            {
                country: 'Gabon',
                value: '+241',
                countryCode: 'GAB',
                label: '+241 - Gabon',
            },
            {
                country: 'Gambia',
                value: '+220',
                countryCode: 'GMB',
                label: '+220 - Gambia',
            },
            {
                country: 'Georgia',
                value: '+995',
                countryCode: 'GEO',
                label: '+995 - Georgia',
            },
            {
                country: 'Germany',
                value: '+49',
                countryCode: 'DEU',
                label: '+49 - Germany',
            },
            {
                country: 'Ghana',
                value: '+233',
                countryCode: 'GHA',
                label: '+233 - Ghana',
            },
            {
                country: 'Gibraltar',
                value: '+350',
                countryCode: 'GIB',
                label: '+350 - Gibraltar',
            },
            {
                country: 'Greece',
                value: '+30',
                countryCode: 'GRC',
                label: '+30 - Greece',
            },
            {
                country: 'Greenland',
                value: '+299',
                countryCode: 'GRL',
                label: '+299 - Greenland',
            },
            {
                country: 'Grenada',
                value: '+1',
                countryCode: 'GRD',
                label: '+1 - Grenada',
            },
            {
                country: 'Guadeloupe',
                value: '+590',
                countryCode: 'GLD',
                label: '+590 - Guadeloupe',
            },
            {
                country: 'Guam',
                value: '+1',
                countryCode: 'GUM',
                label: '+1 - Guam',
            },
            {
                country: 'Guatemala',
                value: '+502',
                countryCode: 'GTM',
                label: '+502 - Guatemala',
            },
            {
                country: 'Guernsey',
                value: '+44',
                countryCode: 'GGY',
                label: '+44 - Guernsey',
            },
            {
                country: 'Guinea',
                value: '+224',
                countryCode: 'GIN',
                label: '+224 - Guinea',
            },
            {
                country: 'Guinea-Bissau',
                value: '+245',
                countryCode: 'GNB',
                label: '+245 - Guinea-Bissau',
            },
            {
                country: 'Guyana',
                value: '+592',
                countryCode: 'GUY',
                label: '+592 - Guyana',
            },
            {
                country: 'Haiti',
                value: '+509',
                countryCode: 'HTI',
                label: '+509 - Haiti',
            },
            {
                country: 'Honduras',
                value: '+504',
                countryCode: 'HND',
                label: '+504 - Honduras',
            },
            {
                country: 'Hong Kong',
                value: '+852',
                countryCode: 'HKG',
                label: '+852 - Hong Kong',
            },
            {
                country: 'Hungary',
                value: '+36',
                countryCode: 'HUN',
                label: '+36 - Hungary',
            },
            {
                country: 'Iceland',
                value: '+354',
                countryCode: 'ISL',
                label: '+354 - Iceland',
            },
            {
                country: 'India',
                value: '+91',
                countryCode: 'IND',
                label: '+91 - India',
            },
            {
                country: 'Indonesia',
                value: '+62',
                countryCode: 'IDN',
                label: '+62 - Indonesia',
            },
            {
                country: 'Iran',
                value: '+98',
                countryCode: 'IRN',
                label: '+98 - Iran',
            },
            {
                country: 'Iraq',
                value: '+964',
                countryCode: 'IRQ',
                label: '+964 - Iraq',
            },
            {
                country: 'Isle of Man',
                value: '+44',
                countryCode: 'IMN',
                label: '+44 - Isle of Man',
            },
            {
                country: 'Israel',
                value: '+972',
                countryCode: 'ISR',
                label: '+972 - Israel',
            },
            {
                country: 'Italy',
                value: '+39',
                countryCode: 'ITA',
                label: '+39 - Italy',
            },
            {
                country: 'Jamaica',
                value: '+1',
                countryCode: 'JAM',
                label: '+1 - Jamaica',
            },
            {
                country: 'Japan',
                value: '+81',
                countryCode: 'JPN',
                label: '+81 - Japan',
            },
            {
                country: 'Jersey',
                value: '+44',
                countryCode: 'JEY',
                label: '+44 - Jersey',
            },
            {
                country: 'Jordan',
                value: '+962',
                countryCode: 'JOR',
                label: '+962 - Jordan',
            },
            {
                country: 'Kazakhstan',
                value: '+7',
                countryCode: 'KAZ',
                label: '+7 - Kazakhstan',
            },
            {
                country: 'Kenya',
                value: '+254',
                countryCode: 'KEN',
                label: '+254 - Kenya',
            },
            {
                country: 'Kiribati',
                value: '+686',
                countryCode: 'KIR',
                label: '+686 - Kiribati',
            },
            {
                country: 'Korea, North',
                value: '+850',
                countryCode: 'PRK',
                label: '+850 - Korea, North',
            },
            {
                country: 'Korea, South',
                value: '+82',
                countryCode: 'KOR',
                label: '+82 - Korea, South',
            },
            {
                country: 'Kuwait',
                value: '+965',
                countryCode: 'KWT',
                label: '+965 - Kuwait',
            },
            {
                country: 'Kyrgyzstan',
                value: '+996',
                countryCode: 'KGZ',
                label: '+996 - Kyrgyzstan',
            },
            {
                country: 'Laos',
                value: '+856',
                countryCode: 'LAO',
                label: '+856 - Laos',
            },
            {
                country: 'Latvia',
                value: '+371',
                countryCode: 'LVA',
                label: '+371 - Latvia',
            },
            {
                country: 'Lebanon',
                value: '+961',
                countryCode: 'LBN',
                label: '+961 - Lebanon',
            },
            {
                country: 'Lesotho',
                value: '+266',
                countryCode: 'LSN',
                label: '+266 - Lesotho',
            },
            {
                country: 'Liberia',
                value: '+231',
                countryCode: 'LBR',
                label: '+231 - Liberia',
            },
            {
                country: 'Libya',
                value: '+218',
                countryCode: 'LBY',
                label: '+218 - Libya',
            },
            {
                country: 'Liechtenstein',
                value: '+423',
                countryCode: 'LIE',
                label: '+423 - Liechtenstein',
            },
            {
                country: 'Lithuania',
                value: '+370',
                countryCode: 'LTU',
                label: '+370 - Lithuania',
            },
            {
                country: 'Luxembourg',
                value: '+352',
                countryCode: 'LUX',
                label: '+352 - Luxembourg',
            },
            {
                country: 'Macau',
                value: '+853',
                countryCode: 'MAC',
                label: '+853 - Macau',
            },
            {
                country: 'Macedonia',
                value: '+389',
                countryCode: 'MKD',
                label: '+389 - Macedonia',
            },
            {
                country: 'Madagascar',
                value: '+261',
                countryCode: 'MDG',
                label: '+261 - Madagascar',
            },
            {
                country: 'Malawi',
                value: '+265',
                countryCode: 'MWI',
                label: '+265 - Malawi',
            },
            {
                country: 'Malaysia',
                value: '+60',
                countryCode: 'MYS',
                label: '+60 - Malaysia',
            },
            {
                country: 'Maldives',
                value: '+960',
                countryCode: 'MDV',
                label: '+960 - Maldives',
            },
            {
                country: 'Mali',
                value: '+223',
                countryCode: 'MLI',
                label: '+223 - Mali',
            },
            {
                country: 'Malta',
                value: '+356',
                countryCode: 'MLT',
                label: '+356 - Malta',
            },
            {
                country: 'Marshall Islands',
                value: '+692',
                countryCode: 'MHL',
                label: '+692 - Marshall Islands',
            },
            {
                country: 'Martinique',
                value: '+596',
                countryCode: 'MTQ',
                label: '+596 - Martinique',
            },
            {
                country: 'Mauritania',
                value: '+222',
                countryCode: 'MRT',
                label: '+222 - Mauritania',
            },
            {
                country: 'Mauritius',
                value: '+230',
                countryCode: 'MUS',
                label: '+230 - Mauritius',
            },
            {
                country: 'Mayotte',
                value: '+262',
                countryCode: 'MYT',
                label: '+262 - Mayotte',
            },
            {
                country: 'Mexico',
                value: '+52',
                countryCode: 'MEX',
                label: '+52 - Mexico',
            },
            {
                country: 'Micronesia, Federated States of',
                value: '+691',
                countryCode: 'FSM',
                label: '+691 - Micronesia, Federated States of',
            },
            {
                country: 'Moldova',
                value: '+373',
                countryCode: 'MDA',
                label: '+373 - Moldova',
            },
            {
                country: 'Monaco',
                value: '+377',
                countryCode: 'MCO',
                label: '+377 - Monaco',
            },
            {
                country: 'Mongolia',
                value: '+976',
                countryCode: 'MNG',
                label: '+976 - Mongolia',
            },
            {
                country: 'Montenegro',
                value: '+382',
                countryCode: 'MNE',
                label: '+382 - Montenegro',
            },
            {
                country: 'Montserrat',
                value: '+1',
                countryCode: 'MSR',
                label: '+1 - Montserrat',
            },
            {
                country: 'Morocco',
                value: '+212',
                countryCode: 'MAR',
                label: '+212 - Morocco',
            },
            {
                country: 'Mozambique',
                value: '+258',
                countryCode: 'MOZ',
                label: '+258 - Mozambique',
            },
            {
                country: 'Myanmar',
                value: '+95',
                countryCode: 'MMR',
                label: '+95 - Myanmar',
            },
            {
                country: 'Namibia',
                value: '+264',
                countryCode: 'NAM',
                label: '+264 - Namibia',
            },
            {
                country: 'Nauru',
                value: '+674',
                countryCode: 'NRU',
                label: '+674 - Nauru',
            },
            {
                country: 'Nepal',
                value: '+977',
                countryCode: 'NPL',
                label: '+977 - Nepal',
            },
            {
                country: 'Netherlands',
                value: '+31',
                countryCode: 'NLD',
                label: '+31 - Netherlands',
            },
            {
                country: 'New Caledonia',
                value: '+687',
                countryCode: 'NCL',
                label: '+687 - New Caledonia',
            },
            {
                country: 'New Zealand',
                value: '+64',
                countryCode: 'NZD',
                label: '+64 - New Zealand',
            },
            {
                country: 'Nicaragua',
                value: '+505',
                countryCode: 'NIC',
                label: '+505 - Nicaragua',
            },
            {
                country: 'Niger',
                value: '+227',
                countryCode: 'NER',
                label: '+227 - Niger',
            },
            {
                country: 'Nigeria',
                value: '+234',
                countryCode: 'NGA',
                label: '+234 - Nigeria',
            },
            {
                country: 'Niue',
                value: '+683',
                countryCode: 'NIU',
                label: '+683 - Niue',
            },
            {
                country: 'Norfolk Island',
                value: '+672',
                countryCode: 'NFK',
                label: '+672 - Norfolk Island',
            },
            {
                country: 'Northern Mariana Islands',
                value: '+1',
                countryCode: 'MNP',
                label: '+1 - Northern Mariana Islands',
            },
            {
                country: 'Norway',
                value: '+47',
                countryCode: 'NOR',
                label: '+47 - Norway',
            },
            {
                country: 'Oman',
                value: '+968',
                countryCode: 'OMN',
                label: '+968 - Oman',
            },
            {
                country: 'Pakistan',
                value: '+92',
                countryCode: 'PAK',
                label: '+92 - Pakistan',
            },
            {
                country: 'Palau',
                value: '+680',
                countryCode: 'PLW',
                label: '+680 - Palau',
            },
            {
                country: 'Palestine, State of',
                value: '+970',
                countryCode: 'PSE',
                label: '+970 - Palestine, State of',
            },
            {
                country: 'Panama',
                value: '+507',
                countryCode: 'PAN',
                label: '+507 - Panama',
            },
            {
                country: 'Papua New Guinea',
                value: '+675',
                countryCode: 'PNG',
                label: '+675 - Papua New Guinea',
            },
            {
                country: 'Paraguay',
                value: '+595',
                countryCode: 'PRY',
                label: '+595 - Paraguay',
            },
            {
                country: 'Peru',
                value: '+51',
                countryCode: 'PER',
                label: '+51 - Peru',
            },
            {
                country: 'Philippines',
                value: '+63',
                countryCode: 'PHL',
                label: '+63 - Philippines',
            },
            {
                country: 'Pitcairn Islands',
                value: '+64',
                countryCode: 'PCN',
                label: '+64 - Pitcairn Islands',
            },
            {
                country: 'Poland',
                value: '+48',
                countryCode: 'POL',
                label: '+48 - Poland',
            },
            {
                country: 'Portugal',
                value: '+351',
                countryCode: 'PRT',
                label: '+351 - Portugal',
            },
            {
                country: 'Puerto Rico',
                value: '+1',
                countryCode: 'PRI',
                label: '+1 - Puerto Rico',
            },
            {
                country: 'Qatar',
                value: '+974',
                countryCode: 'QAT',
                label: '+974 - Qatar',
            },
            {
                country: 'Romania',
                value: '+40',
                countryCode: 'ROU',
                label: '+40 - Romania',
            },
            {
                country: 'Russia',
                value: '+7',
                countryCode: 'RUS',
                label: '+7 - Russia',
            },
            {
                country: 'Rwanda',
                value: '+250',
                countryCode: 'RWA',
                label: '+250 - Rwanda',
            },
            {
                country: 'Saint Barthélemy',
                value: '+590',
                countryCode: 'BLM',
                label: '+590 - Saint Barthélemy',
            },
            {
                country: 'Saint Helena',
                value: '+290',
                countryCode: 'SHN',
                label: '+290 - Saint Helena',
            },
            {
                country: 'Saint Kitts and Nevis',
                value: '+1',
                countryCode: 'KNA',
                label: '+1 - Saint Kitts and Nevis',
            },
            {
                country: 'Saint Lucia',
                value: '+1',
                countryCode: 'LCA',
                label: '+1 - Saint Lucia',
            },
            {
                country: 'Saint Martin (France)',
                value: '+590',
                countryCode: 'MAF',
                label: '+590 - Saint Martin (France)',
            },
            {
                country: 'Saint Pierre and Miquelon',
                value: '+508',
                countryCode: 'SPM',
                label: '+508 - Saint Pierre and Miquelon',
            },
            {
                country: 'Saint Vincent and the Grenadines',
                value: '+1',
                countryCode: 'VCT',
                label: '+1 - Saint Vincent and the Grenadines',
            },
            {
                country: 'Samoa',
                value: '+685',
                countryCode: 'WSM',
                label: '+685 - Samoa',
            },
            {
                country: 'San Marino',
                value: '+378',
                countryCode: 'SMR',
                label: '+378 - San Marino',
            },
            {
                country: 'São Tomé and Príncipe',
                value: '+239',
                countryCode: 'STP',
                label: '+239 - São Tomé and Príncipe',
            },
            {
                country: 'Saudi Arabia',
                value: '+966',
                countryCode: 'SAU',
                label: '+966 - Saudi Arabia',
            },
            {
                country: 'Senegal',
                value: '+221',
                countryCode: 'SEN',
                label: '+221 - Senegal',
            },
            {
                country: 'Serbia',
                value: '+381',
                countryCode: 'SRB',
                label: '+381 - Serbia',
            },
            {
                country: 'Seychelles',
                value: '+248',
                countryCode: 'SYC',
                label: '+248 - Seychelles',
            },
            {
                country: 'Sierra Leone',
                value: '+232',
                countryCode: 'SLE',
                label: '+232 - Sierra Leone',
            },
            {
                country: 'Singapore',
                value: '+65',
                countryCode: 'SGP',
                label: '+65 - Singapore',
            },
            {
                country: 'Sint Maarten (Netherlands)',
                value: '+1',
                countryCode: 'SXN',
                label: '+1 - Sint Maarten (Netherlands)',
            },
            {
                country: 'Slovakia',
                value: '+421',
                countryCode: 'SVK',
                label: '+421 - Slovakia',
            },
            {
                country: 'Slovenia',
                value: '+386',
                countryCode: 'SVN',
                label: '+386 - Slovenia',
            },
            {
                country: 'Solomon Islands',
                value: '+677',
                countryCode: 'SLB',
                label: '+677 - Solomon Islands',
            },
            {
                country: 'Somalia',
                value: '+252',
                countryCode: 'SOM',
                label: '+252 - Somalia',
            },
            {
                country: 'South Africa',
                value: '+27',
                countryCode: 'ZAF',
                label: '+27 - South Africa',
            },
            {
                country: 'South Georgia and the South Sandwich Islands',
                value: '+500',
                countryCode: 'SGS',
                label: '+500 - South Georgia and the South Sandwich Islands',
            },
            {
                country: 'South Sudan',
                value: '+211',
                countryCode: 'SSD',
                label: '+211 - South Sudan',
            },
            {
                country: 'Spain',
                value: '+34',
                countryCode: 'ESP',
                label: '+34 - Spain',
            },
            {
                country: 'Sri Lanka',
                value: '+94',
                countryCode: 'LKA',
                label: '+94 - Sri Lanka',
            },
            {
                country: 'Sudan',
                value: '+249',
                countryCode: 'SDN',
                label: '+249 - Sudan',
            },
            {
                country: 'Suriname',
                value: '+597',
                countryCode: 'SUR',
                label: '+597 - Suriname',
            },
            {
                country: 'Svalbard',
                value: '+47',
                countryCode: 'SJM',
                label: '+47 - Svalbard',
            },
            {
                country: 'Swaziland',
                value: '+268',
                countryCode: 'SWZ',
                label: '+268 - Swaziland',
            },
            {
                country: 'Sweden',
                value: '+46',
                countryCode: 'SWE',
                label: '+46 - Sweden',
            },
            {
                country: 'Switzerland',
                value: '+41',
                countryCode: 'CHE',
                label: '+41 - Switzerland',
            },
            {
                country: 'Syria',
                value: '+963',
                countryCode: 'SYR',
                label: '+963 - Syria',
            },
            {
                country: 'Taiwan',
                value: '+886',
                countryCode: 'TWN',
                label: '+886 - Taiwan',
            },
            {
                country: 'Tajikistan',
                value: '+992',
                countryCode: 'TJK',
                label: '+992 - Tajikistan',
            },
            {
                country: 'Tanzania',
                value: '+255',
                countryCode: 'TZA',
                label: '+255 - Tanzania',
            },
            {
                country: 'Thailand',
                value: '+66',
                countryCode: 'THA',
                label: '+66 - Thailand',
            },
            {
                country: 'Togo',
                value: '+228',
                countryCode: 'TGO',
                label: '+228 - Togo',
            },
            {
                country: 'Tokelau',
                value: '+690',
                countryCode: 'TKL',
                label: '+690 - Tokelau',
            },
            {
                country: 'Tonga',
                value: '+676',
                countryCode: 'TON',
                label: '+676 - Tonga',
            },
            {
                country: 'Trinidad and Tobago',
                value: '+1',
                countryCode: 'TTO',
                label: '+1 - Trinidad and Tobago',
            },
            {
                country: 'Tunisia',
                value: '+216',
                countryCode: 'TUN',
                label: '+216 - Tunisia',
            },
            {
                country: 'Turkey',
                value: '+90',
                countryCode: 'TUR',
                label: '+90 - Turkey',
            },
            {
                country: 'Turkmenistan',
                value: '+993',
                countryCode: 'TKM',
                label: '+993 - Turkmenistan',
            },
            {
                country: 'Turks and Caicos Islands',
                value: '+1',
                countryCode: 'TCA',
                label: '+1 - Turks and Caicos Islands',
            },
            {
                country: 'Tuvalu',
                value: '+688',
                countryCode: 'TUV',
                label: '+688 - Tuvalu',
            },
            {
                country: 'Uganda',
                value: '+256',
                countryCode: 'UGA',
                label: '+256 - Uganda',
            },
            {
                country: 'Ukraine',
                value: '+380',
                countryCode: 'UKR',
                label: '+380 - Ukraine',
            },
            {
                country: 'United Arab Emirates',
                value: '+971',
                countryCode: 'ARE',
                label: '+971 - United Arab Emirates',
            },
            {
                country: 'Uruguay',
                value: '+598',
                countryCode: 'URY',
                label: '+598 - Uruguay',
            },
            {
                country: 'US Virgin Islands',
                value: '+1',
                countryCode: 'VIR',
                label: '+1 - US Virgin Islands',
            },
            {
                country: 'Uzbekistan',
                value: '+998',
                countryCode: 'UZB',
                label: '+998 - Uzbekistan',
            },
            {
                country: 'Vanuatu',
                value: '+678',
                countryCode: 'VUT',
                label: '+678 - Vanuatu',
            },
            {
                country: 'Venezuela',
                value: '+58',
                countryCode: 'VEN',
                label: '+58 - Venezuela',
            },
            {
                country: 'Vatican City State (Holy See)',
                value: '+379',
                countryCode: 'VAT',
                label: '+379 - Vatican City State (Holy See)',
            },
            {
                country: 'Vietnam',
                value: '+84',
                countryCode: 'VMN',
                label: '+84 - Vietnam',
            },
            {
                country: 'Wallis and Futuna',
                value: '+681',
                countryCode: 'WLF',
                label: '+681 - Wallis and Futuna',
            },
            {
                country: 'Yemen',
                value: '+967',
                countryCode: 'YEM',
                label: '+967 - Yemen',
            },
            {
                country: 'Zambia',
                value: '+260',
                countryCode: 'ZMB',
                label: '+260 - Zambia',
            },
            {
                country: 'Zanzibar',
                value: '+255',
                countryCode: 'CPV',
                label: '+255 - Zanzibar',
            },
            {
                country: 'Zimbabwe',
                value: '+263',
                countryCode: 'ZWE',
                label: '+263 - Zimbabwe',
            },
        ],
    },
];

export default diallingCodes;
