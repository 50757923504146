import {
    GetPackageAction,
    SearchTourDeparturesAction,
} from '@/redux/actions/tourPackages';

import { call, put } from 'redux-saga/effects';
import {
    searchTourDepartures,
    getTourPackage,
} from '../../apis/tourPackagesApi';
import {
    searchTourDeparturesSuccess,
    searchTourDeparturesFailure,
    getPackageSuccess,
    getPackageFailure,
} from '../actions/tourPackages.actions';
import {
    GetTourPackageResponse,
    SearchTourDepartureResponse,
} from '@/apis/tourPackagesApi';
import { getApiParamsFromGetParams } from '../../services/tourPackageService';

export function* searchTourDeparturesSaga(action: SearchTourDeparturesAction) {
    try {
        const response: SearchTourDepartureResponse = yield call(
            searchTourDepartures,
            action.data
        );
        if (response.success) {
            yield put(searchTourDeparturesSuccess(response.departures));
        } else {
            yield put(searchTourDeparturesFailure(response.errors.join(', ')));
        }
    } catch (error) {
        yield put(searchTourDeparturesFailure(error));
    }
}

export function* getTourPackageSaga(action: GetPackageAction) {
    try {
        const apiParams = getApiParamsFromGetParams(
            action.params,
            action.currencyCode
        );
        const response: GetTourPackageResponse = yield call(
            getTourPackage,
            apiParams
        );

        if (response.success && response.flight) {
            yield put(
                getPackageSuccess(
                    response.tour,
                    response.flight,
                    response.prices
                )
            );
        } else {
            yield put(getPackageFailure(response.errors.join(', ')));
        }
    } catch (error) {
        yield put(
            getPackageFailure(error.message || ['Unknown error occurred'])
        );
    }
}
