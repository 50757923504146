import React from 'react';
import classNames from 'classnames';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import LoadingLogo from '../common/LoadingLogo/LoadingLogo';
import LoadingText from '../common/LoadingText/LoadingText';
import styles from './PageLoading.module.scss';

const PageLoading = ({ className = null, ...props }) => (
    <div className={classNames(styles.pageLoading, className)} {...props}>
        <ScrollToTopOnMount />
        <LoadingLogo className={styles.spinner} />
        <LoadingText className={styles.loadingText} />
    </div>
);

export default PageLoading;
