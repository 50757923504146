import cookies from 'js-cookie';

const sessionCookieName = 'TAP-SessionId';

export function startSession() {
    let sessionId = getSession();

    if (sessionId === null) {
        sessionId = generateId(10);
        cookies.set(sessionCookieName, sessionId, { secure: true });
    }

    return sessionId;
}

export function getSession() {
    return cookies.get(sessionCookieName) ?? null;
}

function generateId(length) {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

const sessionService = {
    startSession,
    getSession,
};

export default sessionService;
