import {
    FETCH_AGENTS_REQUEST,
    FETCH_AGENTS_SUCCESS,
    FETCH_AGENTS_FAILURE,
} from '../actions/types';

export const fetchAgentsRequest = () => {
    return {
        type: FETCH_AGENTS_REQUEST,
    };
};

export const fetchAgentsSuccess = agents => ({
    type: FETCH_AGENTS_SUCCESS,
    agents: agents,
});

export const fetchAgentsFailure = (error = null) => ({
    type: FETCH_AGENTS_FAILURE,
    error: error,
});
