import React from 'react';
import classNames from 'classnames';
import IconButton from '../IconButton/IconButton';
import styles from './DisabledIconButton.module.scss';

const DisabledIconButton = ({ className, ...props }) => {
    return (
        <IconButton
            className={classNames(styles.disabledIconButton, className)}
            iconColor="808080"
            {...props}
        />
    );
};

DisabledIconButton.propTypes = {
    ...IconButton.propTypes,
};

export default DisabledIconButton;
