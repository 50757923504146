import { Feature } from '@/endurance/featureFlags';

import {
    useState,
    useContext,
    createContext,
    useEffect,
    ReactNode,
} from 'react';
import { getSpecificFeatures } from '../apis/featureFlagApi';
import { isBot, isPreRender } from '../utils/config';
import { logErrorWithCustomMessage } from '../utils/sentry';
import { useLogin } from './LoginContext';
import { useExperimentTracking } from './ExperimentTrackingContext';
import { sendEventV2 } from '../utils/analytics/analyticsService';
import { Page, Area, InteractionItem, Action } from '../utils/analytics/enums';

export enum FeatureFlag {
    SiteWideHoldspaceRebrand = 'sitewide-holdspacerebrand',
    TourPageNavigation = 'tourpage-navigation',
    TourPageHighlightsRename = 'tourpage-highlightsrename',
    SiteWideTailorMadeRename = 'sitewide-tailormaderename',
    DealPageVersion = 'dealpage-version',
    TourPageFlightSwitch = 'tourpage-flightswitch',
    SearchFormMobileAutocomplete = 'searchform-mobileautocomplete',
    TourPagePAndADisableFlights = 'tourpage-pandadisableflights',
    SearchResultsFilterOrdering = 'searchresults-orderfilters',
    SiteWideRemoveLinksBookingPaths = 'sitewide-removelinksbookingpaths',
    HomePageRemoveFlightSearchTab = 'homepage-removeflightstabsearch',
    HideWhoisGoingOnHighAvailability = 'tourpage-hideWhoIsGoingOnHighAvailability',
    TourPackagePageVersion = 'tourpackagepage-version',
    TourPagePandAAtTopOfPage = 'tourpage-pandatopofpage',
    DestinationPageSmallHero = 'destinationpage-smallhero',
    SearchResultsHideCancellationAndRefunds = 'searchresultspage-hidecancellationsandrefundsonresultcard',
    HomePagePartnersDisplay = 'homepage-partnersdisplay',
    TourEnquiryContactUsPopout = 'tourpage-enquirycontactuspopout',
    PaymentPageIncrementalPricing = 'paymentpage-incrementalpricing',
    SiteWidePartnersDisplay = 'sitewide-partnersdisplay',
    ContactUsRemoveHeader = 'contactus-removeheader',
    SearchResultButtonProminence = 'searchresult-button-prominence',
    TourPagePAndAHoldForFree = 'tourpage-panda-holdforfree',
    PaymentPagePaymentPlanUiUpdate = 'paymentpage-paymentplan-uiupdate',
    TourSearchDatesOnResults = 'toursearch-datesonresults',
    SiteWideSearchToolDefaultSpecificDate = 'sitewide-searchtool-default-specificdate',
    LandingPagesLargerButtonToSRP = 'landingpages-largerbuttontosrp',
    LandingPagesShowLoadMoreButton = 'landingpages-showloadmorebutton',
    HomePageShowPromotedTours = 'homepage-showpromotedtours',
    SearchResultsResultImageMultipleImages = 'searchresults-resultimage-multipleimages',
    SearchResultsSmallDesktopDisplay = 'searchresults-small-desktop-display',
    SearchResultsSmallMobileDisplay = 'searchresults-small-mobile-display',
}

export interface FeatureFlagContextValue {
    isLoading: boolean;
    features: Features | null;
}

export interface Features {
    tourPageNavigation: boolean;
    tourPageHighlightsRename: boolean;
    siteWideTailorMadeRename: boolean;
    dealPageVersion: 1 | 2;
    tourPageFlightSwitch: FlightSwitchType;
    searchFormMobileAutocomplete: boolean;
    tourPagePAndADisableFlights: boolean;
    searchResultsOrderFilters: OrderingFeature;
    siteWideRemoveLinksBookingPaths: boolean;
    homePageRemoveFlightSearchTab: boolean;
    hideWhoisGoingOnHighAvailability: boolean;
    tourPackagePageVersion: 1 | 2;
    tourPagePandAAtTopOfPage: boolean;
    destinationPageSmallHero: boolean;
    searchResultsHideCancellationAndRefunds: boolean;
    siteWidePartnersDisplay: boolean;
    tourEnquiryContactUsPopout: boolean;
    paymentPageIncrementalPricing: boolean;
    contactUsRemoveHeader: boolean;
    searchResultButtonProminence: boolean;
    tourPagePAndAHoldForFree: boolean;
    paymentPagePaymentPlanUiUpdate: boolean;
    tourSearchDatesOnResults: boolean;
    siteWideSearchToolDefaultSpecificDate: boolean;
    landingPagesLargerButtonToSRP: boolean;
    landingPagesShowLoadMoreButton: boolean;
    homePageShowPromotedTours: boolean;
    searchResultsResultImageMultipleImages: boolean;
    searchResultsSmallDesktopDisplay: boolean;
    searchResultsSmallMobileDisplay: boolean;
}

export enum FlightSwitchType {
    Control = 0,
    UpdatedTabDesign = 1,
    DepartureFlightLink = 2,
}

export interface OrderingFeature {
    order: string[];
}

export interface FeatureFlagProviderProps {
    children: ReactNode;
}

const FeatureFlagContext = createContext<FeatureFlagContextValue | null>(null);

export function useFeatureFlag() {
    const context = useContext(FeatureFlagContext);
    if (!context) {
        throw new Error('No Feature Flag Context initialised.');
    }
    return context;
}

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
    const { accessToken } = useLogin();
    const [isLoading, setIsLoading] = useState(true);
    const [isRequesting, setIsRequesting] = useState(false);
    const [features, setFeatures] = useState<Features | null>(null);

    const experimentTrackingUUID =
        useExperimentTracking().experimentTrackingUUID;

    const bot = isPreRender() || isBot();

    useEffect(() => {
        if (!isRequesting && features === null && experimentTrackingUUID) {
            setIsRequesting(true);
            getSpecificFeatures(
                [
                    FeatureFlag.SiteWideHoldspaceRebrand,
                    FeatureFlag.TourPageNavigation,
                    FeatureFlag.TourPageHighlightsRename,
                    FeatureFlag.SiteWideTailorMadeRename,
                    // TODO: Add these in as the pages are completed so we don't slow down the request
                    // FeatureFlag.DealPageVersion,
                    FeatureFlag.TourPageFlightSwitch,
                    FeatureFlag.SearchFormMobileAutocomplete,
                    FeatureFlag.TourPagePAndADisableFlights,
                    FeatureFlag.SearchResultsFilterOrdering,
                    FeatureFlag.SiteWideRemoveLinksBookingPaths,
                    FeatureFlag.HomePageRemoveFlightSearchTab,
                    FeatureFlag.HideWhoisGoingOnHighAvailability,
                    FeatureFlag.TourPackagePageVersion,
                    FeatureFlag.TourPagePandAAtTopOfPage,
                    FeatureFlag.DestinationPageSmallHero,
                    FeatureFlag.SearchResultsHideCancellationAndRefunds,
                    FeatureFlag.TourEnquiryContactUsPopout,
                    FeatureFlag.PaymentPageIncrementalPricing,
                    FeatureFlag.SiteWidePartnersDisplay,
                    FeatureFlag.ContactUsRemoveHeader,
                    FeatureFlag.SearchResultButtonProminence,
                    FeatureFlag.TourPagePAndAHoldForFree,
                    FeatureFlag.PaymentPagePaymentPlanUiUpdate,
                    FeatureFlag.TourSearchDatesOnResults,
                    FeatureFlag.SiteWideSearchToolDefaultSpecificDate,
                    FeatureFlag.LandingPagesLargerButtonToSRP,
                    FeatureFlag.LandingPagesShowLoadMoreButton,
                    FeatureFlag.HomePageShowPromotedTours,
                    FeatureFlag.SearchResultsResultImageMultipleImages,
                    FeatureFlag.SearchResultsSmallDesktopDisplay,
                    FeatureFlag.SearchResultsSmallMobileDisplay,
                ],
                experimentTrackingUUID,
                accessToken,
                bot
            )
                .then(response => {
                    if (response.isSuccess) {
                        const features = {
                            tourPageNavigation: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPageNavigation,
                                false
                            ),
                            tourPageHighlightsRename: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPageHighlightsRename,
                                false
                            ),
                            siteWideTailorMadeRename: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.SiteWideTailorMadeRename,
                                false
                            ),
                            dealPageVersion: getFeatureValue<1 | 2>(
                                response.result,
                                FeatureFlag.DealPageVersion,
                                1
                            ),
                            tourPageFlightSwitch: getFeatureValue<0 | 1 | 2>(
                                response.result,
                                FeatureFlag.TourPageFlightSwitch,
                                FlightSwitchType.Control
                            ),
                            searchFormMobileAutocomplete:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchFormMobileAutocomplete,
                                    false
                                ),
                            tourPagePAndADisableFlights:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.TourPagePAndADisableFlights,
                                    false
                                ),
                            searchResultsOrderFilters:
                                getFeatureValue<OrderingFeature>(
                                    response.result,
                                    FeatureFlag.SearchResultsFilterOrdering,
                                    {
                                        order: [
                                            'Age',
                                            'Budget',
                                            'Duration',
                                            'PhysicalRating',
                                            'Destinations',
                                            'PrivateRoom',
                                            'ActivityGroup',
                                        ],
                                    }
                                ),
                            siteWideRemoveLinksBookingPaths:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SiteWideRemoveLinksBookingPaths,
                                    false
                                ),
                            homePageRemoveFlightSearchTab:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.HomePageRemoveFlightSearchTab,
                                    false
                                ),
                            hideWhoisGoingOnHighAvailability:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.HideWhoisGoingOnHighAvailability,
                                    true
                                ),
                            tourPackagePageVersion: getFeatureValue<1 | 2>(
                                response.result,
                                FeatureFlag.TourPackagePageVersion,
                                1
                            ),
                            tourPagePandAAtTopOfPage: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPagePandAAtTopOfPage,
                                false
                            ),
                            destinationPageSmallHero: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.DestinationPageSmallHero,
                                false
                            ),
                            searchResultsHideCancellationAndRefunds:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsHideCancellationAndRefunds,
                                    false
                                ),
                            tourEnquiryContactUsPopout:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.TourEnquiryContactUsPopout,
                                    false
                                ),
                            paymentPageIncrementalPricing:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.PaymentPageIncrementalPricing,
                                    false
                                ),
                            siteWidePartnersDisplay: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.SiteWidePartnersDisplay,
                                false
                            ),
                            contactUsRemoveHeader: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.ContactUsRemoveHeader,
                                false
                            ),
                            searchResultButtonProminence:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultButtonProminence,
                                    false
                                ),
                            tourPagePAndAHoldForFree: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPagePAndAHoldForFree,
                                false
                            ),
                            paymentPagePaymentPlanUiUpdate:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.PaymentPagePaymentPlanUiUpdate,
                                    false
                                ),
                            siteWideSearchToolDefaultSpecificDate:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SiteWideSearchToolDefaultSpecificDate,
                                    false
                                ),
                            landingPagesLargerButtonToSRP:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.LandingPagesLargerButtonToSRP,
                                    false
                                ),
                            landingPagesShowLoadMoreButton:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.LandingPagesShowLoadMoreButton,
                                    false
                                ),
                            homePageShowPromotedTours: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.HomePageShowPromotedTours,

                                false
                            ),
                            tourSearchDatesOnResults: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourSearchDatesOnResults,
                                false
                            ),
                            searchResultsResultImageMultipleImages:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsResultImageMultipleImages,
                                    false
                                ),
                            searchResultsSmallDesktopDisplay:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsSmallDesktopDisplay,
                                    false
                                ),
                            searchResultsSmallMobileDisplay:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsSmallMobileDisplay,
                                    false
                                ),
                        };

                        setFeatures(features);

                        sendEventV2(
                            Page.siteWide,
                            Area.siteWide,
                            InteractionItem.featureFlag,
                            Action.featureExposure,
                            {
                                experimentTrackingUUID: experimentTrackingUUID,
                                ...features,
                            }
                        );
                    } else {
                        throw new Error(
                            `Feature Flag request failed - ${response.errors}`
                        );
                    }
                })
                .catch(e => {
                    logErrorWithCustomMessage(e, 'Failed to get feature flags');
                    setFeatures({
                        tourPageNavigation: false,
                        tourPageHighlightsRename: false,
                        siteWideTailorMadeRename: false,
                        dealPageVersion: 1,
                        tourPageFlightSwitch: FlightSwitchType.Control,
                        searchFormMobileAutocomplete: false,
                        tourPagePAndADisableFlights: false,
                        searchResultsOrderFilters: {
                            order: [
                                'Age',
                                'Budget',
                                'Duration',
                                'PhysicalRating',
                                'Destinations',
                                'PrivateRoom',
                                'ActivityGroup',
                            ],
                        },
                        siteWideRemoveLinksBookingPaths: false,
                        homePageRemoveFlightSearchTab: false,
                        hideWhoisGoingOnHighAvailability: false,
                        tourPackagePageVersion: 1,
                        tourPagePandAAtTopOfPage: false,
                        destinationPageSmallHero: false,
                        searchResultsHideCancellationAndRefunds: false,
                        tourEnquiryContactUsPopout: false,
                        paymentPageIncrementalPricing: false,
                        siteWidePartnersDisplay: false,
                        contactUsRemoveHeader: false,
                        searchResultButtonProminence: false,
                        tourPagePAndAHoldForFree: false,
                        paymentPagePaymentPlanUiUpdate: false,
                        tourSearchDatesOnResults: false,
                        siteWideSearchToolDefaultSpecificDate: false,
                        landingPagesLargerButtonToSRP: false,
                        landingPagesShowLoadMoreButton: false,
                        homePageShowPromotedTours: false,
                        searchResultsResultImageMultipleImages: false,
                        searchResultsSmallDesktopDisplay: false,
                        searchResultsSmallMobileDisplay: false,
                    });
                })
                .finally(() => {
                    setIsRequesting(false);
                    setIsLoading(false);
                });
        }
    }, [isRequesting, features, experimentTrackingUUID, accessToken, bot]);

    return (
        <FeatureFlagContext.Provider
            value={{
                isLoading: isLoading,
                features: features,
            }}
        >
            {children}
        </FeatureFlagContext.Provider>
    );
}

function getFeatureValue<T extends string | boolean | number | OrderingFeature>(
    features: Feature[] | null,
    feature: FeatureFlag,
    defaultValue: T
): T {
    var result = features?.find(f => f.name === feature)?.value ?? null;
    if (result !== null) {
        try {
            const type = typeof defaultValue;
            switch (type) {
                case 'string':
                    return result as T;
                case 'number':
                    return Number(result) as T;
                case 'boolean': {
                    return result as T;
                }
                case 'object':
                    return result as T;
                default:
                    throw new Error(
                        `Unable to use feature flag ${feature} of type ${type}.`
                    );
            }
        } catch (error) {
            logErrorWithCustomMessage(error, 'Failed to parse feature flag');
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

export default FeatureFlagContext;
