import React, { PureComponent } from 'react';
import responseIq from '../../utils/responseIq';

class ResponseIq extends PureComponent {
    render() {
        return <></>;
    }

    componentDidMount() {
        responseIq.init();
    }
}

export default ResponseIq;
