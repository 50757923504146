import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import classNames from 'classnames';
import agentApi from '../../../apis/agentApi';
import TextInputField from '../../common/TextInputField/TextInputField';
import PhoneNumberField from '../../common/PhoneNumberField/PhoneNumberField';
import { isValidEmail, isValidPhoneNumber } from '../../../utils/validation';
import styles from './TradeAgentSignUpForm.module.scss';
import {
    logErrorWithCustomMessage,
    logErrorWithInfo,
} from '../../../utils/sentry';
import Button from '../../common/Button';
import { TapPhoneNumber } from '../../common/TapContactInfo';
import { useState } from 'react';
import { getDiallingCodeByCurrency } from '../../common/Select/DiallingCodeSelect/diallingCodeHelpers';
import { selectSelectedCurrencyCode } from '../../../redux/selectors/site.selectors';

const TradeAgentSignUpForm = ({ selectedCurrency, className }) => {
    const [responseSuccess, setResponseStatus] = useState(null);

    switch (responseSuccess) {
        case true:
            return (
                <p>
                    Thank you for submitting your details. We'll be in touch
                    very soon.
                </p>
            );
        case false:
            return (
                <p className={styles.error}>
                    Oops! Something went wrong while submitting your details.
                    Please try again or contact us on <TapPhoneNumber />.
                </p>
            );
        default:
            return (
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: {
                            code:
                                getDiallingCodeByCurrency(selectedCurrency) ??
                                null,
                            number: '',
                        },
                        travelAgency: '',
                        branchName: '',
                        abtaNumber: '',
                    }}
                    enableReinitialize={true}
                    validate={validate}
                    onSubmit={(values, { setSubmitting }) => {
                        agentApi
                            .sendTradeAgentSignUpForm(
                                values.firstName,
                                values.lastName,
                                values.email,
                                values.phone.code.value,
                                values.phone.number,
                                values.travelAgency,
                                values.branchName,
                                values.abtaNumber
                            )
                            .then(response => {
                                if (!response.success) {
                                    logErrorWithInfo(
                                        Error(
                                            'Failed to submit trade agent sign up'
                                        ),
                                        response
                                    );
                                }
                                setResponseStatus(response.success);
                                setSubmitting(false);
                            })
                            .catch(error => {
                                logErrorWithCustomMessage(
                                    error,
                                    'Failed to submit trade agent sign up',
                                    {
                                        ...values,
                                    }
                                );
                                setResponseStatus(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form className={classNames(styles.form, className)}>
                            <div>
                                <TextInputField
                                    isRequired={true}
                                    label="First Name"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    error={errors.firstName}
                                    isTouched={touched.firstName === true}
                                />
                                <TextInputField
                                    isRequired={true}
                                    label="Last Name"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    error={errors.lastName}
                                    isTouched={touched.lastName === true}
                                />
                            </div>
                            <div>
                                <TextInputField
                                    isRequired={true}
                                    label="Your Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={errors.email}
                                    type="email"
                                    isTouched={touched.email === true}
                                    className={styles.email}
                                />
                            </div>
                            <div>
                                <PhoneNumberField
                                    isRequired={true}
                                    value={values.phone}
                                    error={errors.phone}
                                    name={`phone`}
                                    isTouched={touched.phone}
                                    handleChange={handleChange}
                                    label="Your Phone Number"
                                />
                                <TextInputField
                                    label="ABTA Number"
                                    name="abtaNumber"
                                    value={values.abtaNumber}
                                    onChange={handleChange}
                                    error={errors.abtaNumber}
                                    isTouched={touched.abtaNumber === true}
                                />
                            </div>
                            <div>
                                <TextInputField
                                    isRequired={true}
                                    label="Travel Agency"
                                    name="travelAgency"
                                    value={values.travelAgency}
                                    onChange={handleChange}
                                    error={errors.travelAgency}
                                    isTouched={touched.travelAgency === true}
                                />
                                <TextInputField
                                    label="Branch Name"
                                    name="branchName"
                                    value={values.branchName}
                                    onChange={handleChange}
                                    error={errors.branchName}
                                    isTouched={touched.branchName === true}
                                />
                            </div>
                            <Button
                                onClick={e => {
                                    e.preventDefault();
                                    if (!isSubmitting) {
                                        handleSubmit(e);
                                    }
                                }}
                                className={styles.button}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {!isSubmitting ? (
                                    'Submit Details'
                                ) : (
                                    <div
                                        className={classNames(
                                            styles.loader,
                                            'gif-loader'
                                        )}
                                    />
                                )}
                            </Button>
                        </form>
                    )}
                </Formik>
            );
    }
};

const validate = values => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = '*Please enter First Name';
    }
    if (!values.lastName) {
        errors.lastName = '*Please enter Last Name';
    }

    if (!values.email) {
        errors.email = '*Please enter your Email';
    } else if (!isValidEmail(values.email)) {
        errors.email = '*Please enter a valid Email';
    }

    if (!values.phone.number || !values.phone.code) {
        errors.phone = '*Please enter your Phone Number';
    } else if (!isValidPhoneNumber(values.phone.number)) {
        errors.phone = '*Please enter a valid Phone Number';
    }

    if (!values.travelAgency) {
        errors.travelAgency = '*Please enter Travel Agency';
    }

    return errors;
};

const mapStateToProps = state => {
    return {
        selectedCurrency: selectSelectedCurrencyCode(state),
    };
};

export default connect(mapStateToProps)(TradeAgentSignUpForm);
