const removeUnusedEntries = obj => {
    return Object.keys(obj)
        .filter(
            e =>
                obj[e] !== null &&
                obj[e] !== '' &&
                !(Array.isArray(obj[e]) && obj[e].length === 0) &&
                obj[e] !== {} &&
                typeof obj[e] !== 'undefined'
        )
        .reduce((acc, value) => {
            acc[value] = obj[value];
            return acc;
        }, {});
};

const searchHelper = {
    removeUnusedEntries,
};

export default searchHelper;
