export default function debounce(callback, delay) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            callback.apply(this, arguments);
        }, delay);
    };
}
