import Header from './Header';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext';
import { useLocation } from 'react-router-dom';
import { isCheckoutPage } from '../../helpers/globalHelpers';

export default function HeaderHoc() {
    const { features } = useFeatureFlag();
    const location = useLocation();

    const shouldHideHeader =
        (features?.siteWideRemoveLinksBookingPaths ?? false) &&
        isCheckoutPage(location?.pathname);

    return !shouldHideHeader && <Header />;
}
