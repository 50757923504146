import React from 'react';
import { SecondaryButton } from '../../Button';

const TapEmailAddress = ({ emailSubject = '', ...props }) => {
    return (
        <SecondaryButton
            as="a"
            href={`mailto:hello@theadventurepeople.com${
                emailSubject && '?subject=' + emailSubject
            }`}
            icon="mail"
            color="ffffff"
            width={20}
            height={20}
            {...props}
        >
            Email Us
        </SecondaryButton>
    );
};

export default TapEmailAddress;
