import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SecondaryButton } from '../../Button';
import { selectTapTelephoneNumber } from '../../../../redux/selectors/site.selectors';

const TapPhoneNumber = ({
    displayTelephoneNumber,
    telephoneNumber,
    dispatch,
    ...props
}) => {
    return (
        <SecondaryButton
            as="a"
            href={`tel:${telephoneNumber}`}
            icon="phone"
            color="ffffff"
            width={20}
            height={20}
            {...props}
        >
            {displayTelephoneNumber}
        </SecondaryButton>
    );
};

TapPhoneNumber.propTypes = {
    displayTelephoneNumber: PropTypes.string,
    telephoneNumber: PropTypes.string,
};

const mapStateToProps = ({ site }) => {
    const tapTelephoneNumber = selectTapTelephoneNumber(site);

    return {
        displayTelephoneNumber:
            site?.selectedCurrencyData?.displayTelephoneNumber ||
            tapTelephoneNumber.displayTelephoneNumber,
        telephoneNumber:
            site?.selectedCurrencyData?.telephoneNumber ||
            tapTelephoneNumber.telephoneNumber,
    };
};

export default connect(mapStateToProps)(TapPhoneNumber);
