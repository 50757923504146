import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { PrimaryButton } from '../common/Button';
import { getQueryFromSearchParams } from '../../services/tourSearchService';
import styles from './SimpleSearchTool.module.scss';
import { sendEventV2 } from '../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../utils/analytics/enums/EventInteractionEnums';

function SimpleSearchTool({
    children = 'Search',
    source,
    postSearch,
    history,
    className,
    staticContext,
    location,
    match,
    ...props
}) {
    const keywordsInputRef = useRef(null);
    const [keywords, setKeywords] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const handleSearch = event => {
        event.preventDefault();
        setHasSubmitted(true);

        sendEventV2(
            Page.header,
            Area.searchBox,
            InteractionItem.searchBox,
            Action.submit,
            {
                searchText: keywords,
                attributeData: null,
                dateTimeData: null,
            }
        );

        const searchParamsString = getQueryFromSearchParams({
            text: keywords,
            source: source,
        });

        if (keywords.length > 0) {
            history.push({
                pathname: '/search',
                search: `${searchParamsString}`,
            });
            setKeywords('');
            setHasSubmitted(false);
            setIsValid(true);
            keywordsInputRef.current.blur();

            if (postSearch) {
                postSearch();
            }
        } else {
            setIsValid(false);
        }
    };

    return (
        <form
            className={classNames({
                [styles.simpleSearchTool]: true,
                [styles.isInvalid]: hasSubmitted && !isValid,
                [className]: !!className,
            })}
            {...props}
        >
            <input
                ref={keywordsInputRef}
                className={styles.textbox}
                type="search"
                placeholder="Search for..."
                value={keywords}
                onChange={e => setKeywords(e.target.value)}
            />
            <PrimaryButton
                className={styles.searchButton}
                onSubmit={handleSearch}
                onClick={handleSearch}
                type="submit"
                aria-label="search"
                icon="search"
                color="ffffff"
                width={15}
                height={15}
            ></PrimaryButton>
        </form>
    );
}

SimpleSearchTool.propTypes = {
    children: PropTypes.node,
    source: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    postSearch: PropTypes.func,
    className: PropTypes.string,
};

export default withRouter(SimpleSearchTool);
