import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './MenuItem.module.scss';
import classNames from 'classnames';

export function MenuItem({ children, className, ...props }) {
    return (
        <li className={classNames(styles.menuItem, className)} {...props}>
            {children}
        </li>
    );
}

MenuItem.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(MenuItem);
