import { GetParams } from '@/apis/tourPackagesApi';
import { TourFlightSearchParams } from '@/core/tourFlightSearch';
import { TourPackageGetRequest } from '@/endurance/tourPackages';

import * as queryString from 'qs';
import { getQueryFromSearchParams } from './tourFlightSearchService';
import searchHelper from '../helpers/searchHelper';

export const getPackageParamsFromQuery = (
    queryStringParams: string
): GetParams => {
    const parsedParams = queryString.parse(queryStringParams, {
        ignoreQueryPrefix: true,
    });

    if (parsedParams.adults === undefined) {
        throw new Error('adults is required');
    } else if (parsedParams.tourId === undefined) {
        throw new Error('tourId is required');
    } else if (parsedParams.departureId === undefined) {
        throw new Error('departureId is required');
    } else if (parsedParams.departureRoomId === undefined) {
        throw new Error('departureRoomId is required');
    } else if (parsedParams.flightToken === undefined) {
        throw new Error('flightToken is required');
    } else if (parsedParams.flightSupplierId === undefined) {
        throw new Error('flightSupplierId is required');
    } else if (parsedParams.isCachedFlight === undefined) {
        throw new Error('isCachedFlight is required');
    }

    const params: GetParams = {
        adults: parseInt(parsedParams.adults as string),
        tourId: parsedParams.tourId as string,
        departureId: parseInt(parsedParams.departureId as string),
        departureRoomId: parseInt(parsedParams.departureRoomId as string),
        flightToken: parsedParams.flightToken as string,
        flightSupplierId: parseInt(parsedParams.flightSupplierId as string),
        isCachedFlight:
            (parsedParams.isCachedFlight as string).toLowerCase() === 'true',
    };

    return params;
};

export function getQueryFromParams(
    packageParams: GetParams,
    flightSearchParams: TourFlightSearchParams
): string {
    const params = {
        adults: null, // Set from flightSearchParams
        departureId: packageParams.departureId || null,
        departureRoomId: packageParams.departureRoomId || null,
        flightToken: packageParams.flightToken || null,
        flightSupplierId: packageParams.flightSupplierId || null,
        isCachedFlight: packageParams.isCachedFlight || null,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);
    const packageQuery = queryString.stringify(filteredParams, {
        encode: true,
    });

    const flightSearchQuery = getQueryFromSearchParams(flightSearchParams);

    return `${packageQuery}&${flightSearchQuery}`;
}

export const getApiParamsFromGetParams = (
    params: GetParams,
    currencyCode: string
): TourPackageGetRequest => {
    return {
        currencyCode: currencyCode,
        adults: params.adults,
        departureId: params.departureId,
        departureRoomId: params.departureRoomId,
        flightToken: params.flightToken,
        flightSupplierId: params.flightSupplierId,
        isCachedFlight: params.isCachedFlight,
    };
};
