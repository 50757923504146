import { DiallingCodeOption } from '@/core';

import diallingCodes from './diallingCodeOptions';

const currencyCountryMappings: any = {
    GBP: 'GBR',
    USD: 'USA',
    CAD: 'CAN',
    AUD: 'AUS',
    DKK: 'DNK',
    SEK: 'SWE',
    NOK: 'NOR',
    SGD: 'SGP',
    ZAR: 'ZAF',
};

export function getDiallingCodeByCurrency(
    currencyCode: string
): DiallingCodeOption | null {
    if (Object.keys(currencyCountryMappings).includes(currencyCode)) {
        let code: DiallingCodeOption | null = null;

        diallingCodes.forEach(arr => {
            if (code === null || !code) {
                code =
                    arr.options.find(
                        o =>
                            o.countryCode ===
                            currencyCountryMappings[currencyCode]
                    ) ?? null;
            }
        });
        return code ?? null;
    }
    return null;
}

export function getDiallingCodeOption(
    value: string
): DiallingCodeOption | null {
    let code: DiallingCodeOption | null = null;
    diallingCodes.forEach(arr => {
        if (code === null || !code) {
            code = arr.options.find(o => o.value === value) ?? null;
        }
    });
    return code ?? null;
}

const diallingCodeHelpers = {
    getDiallingCodeByCurrency,
    getDiallingCodeOption,
};

export default diallingCodeHelpers;
