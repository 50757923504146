import { DiallingCodeOption } from '@/core';

import Select from 'react-select';
import { StylesConfig } from 'react-select/src/styles';
import * as classNames from 'classnames';
import diallingCodes from './diallingCodeOptions';
import styles from './DiallingCodeSelect.module.scss';
import { Option } from 'react-select/src/filters';

const customStyles: StylesConfig = {
    control: styles => ({
        ...styles,
        height: '40px',
    }),
};

export interface DiallingCodeSelectProps {
    value: DiallingCodeOption | null;
    name: string;
    onChange: (event: {
        target: { name: string; value: DiallingCodeOption | null };
    }) => void;
    placeholder?: string;

    className?: string;
}

const DiallingCodeSelect = ({
    value,
    name,
    onChange,
    placeholder = '',
    className,
}: DiallingCodeSelectProps) => {
    return (
        <Select<DiallingCodeOption>
            className={classNames(styles.select, className)}
            styles={customStyles}
            options={diallingCodes}
            name={name}
            onChange={handleChange}
            classNamePrefix="diallingCode-select"
            placeholder={placeholder}
            value={value}
            filterOption={filter}
        />
    );

    function handleChange(value: DiallingCodeOption | null) {
        onChange({ target: { name, value } });
    }
};

function filter({ label }: Option, inputValue: string) {
    return (
        label
            .split(' ')
            .filter(word =>
                word.toLowerCase().includes(inputValue.toLowerCase())
            ).length > 0
    );
}

export default DiallingCodeSelect;
