import { isPreRender } from './config';

export function init() {
    if (!isPreRender()) {
        var script = document.createElement('script');
        script.id = 'responseIq';
        script.src =
            'https://app.responseiq.com/widgetsrc.php?widget=XT5T48JPN8VE1KVI83&widgetrnd=Math.random();';
        script.async = true;
        script.defer = true;

        var root = document.getElementById('root');
        root.parentNode.insertBefore(script, root);
    } else {
        console.log('ResponseIQ disabled'); // eslint-disable-line no-console
    }
}

const responseIq = {
    init,
};

export default responseIq;
