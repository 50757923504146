import * as TYPES from '../actions/types';
import { roomsKey } from '../../services/accommodationService';

const initialState = {
    request: null,
    loading: {},
    rooms: {},
    errors: {},
};

const getRoomsKey = action =>
    roomsKey(
        action.countryIsoCode2,
        action.slug,
        action.currencyCode,
        action.checkInDate,
        action.checkOutDate,
        action.adultCount,
        action.childAges
    );

const requestRooms = (state, action) => {
    const roomsKey = getRoomsKey(action);

    return {
        ...state,
        request: null,
        errors: {},
        loading: { ...state.loading, [roomsKey]: true },
    };
};

const requestRoomsSuccess = (state, action) => {
    const roomsKey = getRoomsKey(action.request);
    const rooms = action.results.map(room => ({
        ...room,
        sessionId: action.sessionId,
    }));

    return {
        ...state,
        request: {
            ...action.request,
            childAges: action.request.childAges ? action.request.childAges : [],
        },
        loading: { ...state.loading, [roomsKey]: false },
        rooms: {
            ...state.rooms,
            [roomsKey]: rooms,
        },
        errors: { ...state.errors, [roomsKey]: undefined },
    };
};

const requestRoomsFailure = (state, action) => {
    const roomsKey = getRoomsKey(action);

    return {
        ...state,
        loading: { ...state.loading, [roomsKey]: false },
        rooms: {
            ...state.rooms,
            [roomsKey]: undefined,
        },
        errors: {
            ...state.errors,
            [roomsKey]: action.errors,
        },
    };
};

const storeSearchRooms = (state, action) => {
    const rooms = action.results
        .map(r => ({
            ...action.request,
            countryIsoCode2: r.location.countryIsoCode2,
            slug: r.slug,
            rooms: r.rooms,
        }))
        .reduce((accumulator, currentValue) => {
            const roomsKey = getRoomsKey(currentValue);
            const rooms = currentValue.rooms.map(room => ({
                ...room,
                sessionId: action.sessionId,
            }));

            accumulator[roomsKey] = rooms;
            return accumulator;
        }, {});

    return {
        ...state,
        request: action.request,
        rooms: {
            ...state.rooms,
            ...rooms,
        },
        loading: {
            ...state.loading,
            ...Object.keys(rooms).reduce((accumulator, key) => {
                accumulator[key] = false;
                return accumulator;
            }, {}),
        },
    };
};

const accommodationRooms = (state = initialState, action) => {
    const handlers = {
        [TYPES.ACCOM_ROOMS_REQUEST]: requestRooms,
        [TYPES.ACCOM_ROOMS_SUCCESS]: requestRoomsSuccess,
        [TYPES.ACCOM_ROOMS_FAILURE]: requestRoomsFailure,
        [TYPES.ACCOM_SEARCH_SUCCESS]: storeSearchRooms,
        [TYPES.ACCOM_SEARCH_INCREMENT_SUCCESS]: storeSearchRooms,
    };

    return handlers[action.type] ? handlers[action.type](state, action) : state;
};

export default accommodationRooms;

// Selectors
const selectState = state => state.accommodationRooms;

export const selectRooms = (state, key) => selectState(state).rooms[key];
export const selectSearchRequest = state => selectState(state).request;
export const selectErrors = (state, key) => selectState(state).errors[key];
export const selectIsLoading = (state, key) => {
    const isLoading = selectState(state).loading[key];
    return isLoading !== undefined ? isLoading : true;
};
