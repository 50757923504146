import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { selectTapTelephoneNumber } from '../../../redux/selectors/site.selectors';
import Icon from '../Icon/Icon';
import styles from './EnquiryNumbers.module.scss';
import { sendEventV2 } from '../../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../../utils/analytics/enums/EventInteractionEnums';
function EnquiryNumbers({
    displayTelephoneNumber,
    telephoneNumber,
    className,
}) {
    return (
        <div className={classNames(styles.enquiries, className)}>
            <div>
                <a
                    className={styles.callUs}
                    href={`tel:${telephoneNumber}`}
                    aria-label={`Call us on ${displayTelephoneNumber}`}
                    onClick={() => {
                        sendEventV2(
                            Page.header,
                            Area.phoneNumber,
                            InteractionItem.phoneLink,
                            Action.click,
                            null
                        );
                    }}
                >
                    <Icon className={styles.icon} icon="phoneSolid" />
                    <span className={styles.displayNumber}>
                        {displayTelephoneNumber}
                    </span>
                </a>
            </div>
        </div>
    );
}

EnquiryNumbers.propTypes = {
    displayTelephoneNumber: PropTypes.string.isRequired,
    telephoneNumber: PropTypes.string.isRequired,
};

function mapStateToProps({ site }) {
    const tapTelephoneNumber = selectTapTelephoneNumber(site);

    return {
        displayTelephoneNumber:
            site?.selectedCurrencyData?.displayTelephoneNumber ||
            tapTelephoneNumber.displayTelephoneNumber,
        telephoneNumber:
            site?.selectedCurrencyData?.telephoneNumber ||
            tapTelephoneNumber.telephoneNumber,
    };
}

export default connect(mapStateToProps)(EnquiryNumbers);
