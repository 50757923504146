import { call, put, select } from 'redux-saga/effects';
import searchApi from '../../apis/packagesApi';
import { logError, logErrorWithInfo } from '../../utils/sentry';
import {
    searchPackagesSuccess,
    searchPackagesFailure,
    incrementPageSuccess,
    incrementPageFailure,
} from '../actions';
import {
    selectPage,
    selectRequest,
    selectSessionId,
} from '../reducers/packagesSearch.reducer';

export const getState = state => state.packagesSearch;
export const getSelectedCurrency = state => {
    return state.site.selectedCurrency ? state.site.selectedCurrency : 'GBP';
};

export function* performPackagesSearchSaga() {
    try {
        const response = yield search();
        if (response.success) {
            yield put(searchPackagesSuccess(response));
        } else {
            yield put(searchPackagesFailure(response));
            logErrorWithInfo(
                Error('Failed to search accommodations'),
                response
            );
        }
    } catch (e) {
        logError(e);
        yield put(searchPackagesFailure({ errors: ['Unknown Error'] }));
    }
}

function* search(sessionId) {
    const searchState = yield select(getState);
    const selectedCurrency = yield select(getSelectedCurrency);
    return yield call(searchApi.searchPackages, {
        ...searchState.searchParams,
        currencyCode: selectedCurrency,
        page: searchState.page,
        pageSize: searchState.pageSize,
        sortType: searchState.sortType,
        sessionId,
    });
}
