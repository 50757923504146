import {
    FAVOURITES_FAVOURITE_TOUR,
    FAVOURITES_UNFAVOURITE_TOUR,
} from './types';

export const favouriteTour = tourId => {
    return {
        type: FAVOURITES_FAVOURITE_TOUR,
        tourId,
    };
};

export const unfavouriteTour = tourId => {
    return {
        type: FAVOURITES_UNFAVOURITE_TOUR,
        tourId,
    };
};
