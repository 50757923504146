import { SiteState } from '@/redux/SiteState';
import { RootState } from '../store';
import { areFlightsEnabled, areFlightsHotelsEnabled } from '../../utils/config';

const defaultCurrencyCode = 'GBP';

const selectState = (state: RootState) => state.site;

export const selectShouldShowFlights = (site: SiteState | undefined) => {
    return (
        areFlightsEnabled &&
        (site
            ? site.selectedCurrency
                ? site.selectedCurrency === defaultCurrencyCode
                : true
            : true)
    );
};
export const selectShouldShowHotelFlights = (site: SiteState) => {
    return (
        areFlightsHotelsEnabled &&
        (site.selectedCurrency
            ? site.selectedCurrency === defaultCurrencyCode
            : true)
    );
};

export const selectCurrency = (site: SiteState) => {
    return site.currencies.find(
        currency =>
            currency.code === site.selectedCurrency || defaultCurrencyCode
    );
};

export const selectSelectedCurrencyCode = (state: RootState) =>
    selectState(state)?.selectedCurrency || defaultCurrencyCode;

export const selectTapTelephoneNumber = (site: SiteState) => {
    const defaultTelephoneNumber = '+44-208-004-8886';
    const defaultDisplayTelephoneNumber = '+44 (0)208 004 8886';
    const currency = selectCurrency(site);
    return {
        telephoneNumber: currency
            ? currency.telephoneNumber
            : defaultTelephoneNumber,
        displayTelephoneNumber: currency
            ? currency.displayTelephoneNumber
            : defaultDisplayTelephoneNumber,
    };
};

const defaultTelephoneNumberTailormade = '+44-208-004-9861';
const defaultDisplayTelephoneNumberTailormade = '0208 004 9861';
export const selectTapTelephoneNumberTailormade = (site: SiteState) => {
    const currency = selectCurrency(site);
    return {
        telephoneNumberTailormade: currency
            ? currency.telephoneNumberTailormade
            : defaultTelephoneNumberTailormade,
        displayTelephoneNumberTailormade: currency
            ? currency.displayTailorMadeTelephoneNumber
            : defaultDisplayTelephoneNumberTailormade,
    };
};
