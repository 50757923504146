import {
    REQUEST_TOUR_DEPARTURES,
    REQUEST_TOUR_DEPARTURES_SUCCESS,
    REQUEST_TOUR_DEPARTURES_FAILURE,
} from './types';

import { TourDeparture } from '@/endurance/tours';
import { RequestTourDeparturesAction } from '@/redux/actions/tour/RequestTourDeparturesAction';
import { RequestTourDeparturesSuccessAction } from '@/redux/actions/tour/RequestTourDeparturesSuccessActions';
import { RequestTourDeparturesFailureAction } from '@/redux/actions/tour/RequestTourDeparturesFailureAction';

export const requestTourDepartures = (
    tourId: string,
    currencyCode: string
): RequestTourDeparturesAction => ({
    type: REQUEST_TOUR_DEPARTURES,
    tourId: tourId,
    currencyCode: currencyCode,
});

export const requestTourDeparturesSuccess = (
    departures: TourDeparture[],
    tourId: string
): RequestTourDeparturesSuccessAction => ({
    type: REQUEST_TOUR_DEPARTURES_SUCCESS,
    departures,
    tourId,
});

export const requestTourDeparturesFailure = (
    tourId: string,
    errors: string[]
): RequestTourDeparturesFailureAction => {
    return {
        type: REQUEST_TOUR_DEPARTURES_FAILURE,
        tourId,
        errors,
    };
};
