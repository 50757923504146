import * as TYPES from './types';

export const searchAccommodation = () => ({
    type: TYPES.ACCOM_SEARCH_REQUEST,
});

export const searchAccommodationSuccess = data => ({
    type: TYPES.ACCOM_SEARCH_SUCCESS,
    ...data,
});

export const searchAccommodationFailure = data => ({
    type: TYPES.ACCOM_SEARCH_FAILURE,
    ...data,
});

export const incrementAccommodationPage = () => ({
    type: TYPES.ACCOM_SEARCH_INCREMENT_REQUEST,
});

export const incrementAccommodationPageSuccess = data => ({
    type: TYPES.ACCOM_SEARCH_INCREMENT_SUCCESS,
    ...data,
});

export const incrementAccommodationPageFailure = data => ({
    type: TYPES.ACCOM_SEARCH_INCREMENT_FAILURE,
    ...data,
});

export const populateAccommodationSearchParams = (
    params,
    updateQueryString = false
) => ({
    type: TYPES.ACCOM_POPULATE_SEARCH_PARAMS,
    updateQueryString,
    params,
});

export const filterAccommodationSearch = params => {
    return {
        type: TYPES.ACCOM_SEARCH_FILTER,
        params,
    };
};

export const sortAccommodationSearch = sortType => ({
    type: TYPES.ACCOM_SEARCH_SORT,
    sortType,
});

export const resetAccommodationSearch = () => ({
    type: TYPES.ACCOM_SEARCH_RESET,
});

export const resetAccommodationFilters = () => ({
    type: TYPES.ACCOM_SEARCH_FILTER_RESET,
});
